import React from 'react'
import styles from '../../styles/styles.module.css';
import Img from '../../assets/api_test.png'
import Img1 from '../../assets/api.png'

const IntroductionApi = () => {
    return (
        <div style={{marginBottom: '0px'}}>
        <div className={styles.cardbod}name="test" style={{marginBottom: '55px'}}>
          <div>
           <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#72b84c'}}>Introduction</h3>
            <hr />
            <p className={styles.p} style={{marginTop: '10px'}}> Welcome to the SG Hawker Centres API documentation page. Our team is here to support you in the development of your applications.</p>
            <p className={styles.p}>With our extensive set of APIs, you can retrieve eMenu and calorie data from merchants and consumers, and do so much more like building your own food ordering apps or website!</p>
            <p className={styles.p}>Watch this space as we continue to improve our API services!</p>
            <div className={styles.div5}></div>
          </div>

          {/* Instruction to get started: */}
          <div>
            <p style={{fontSize: '16px', color: '#72b84c', fontWeight: 'bold'}}> Not sure where to start? Here's our step-by-step guide! </p>

            <div style={{marginTop: '10px'}}>
              <p style={{fontSize: '15px', fontWeight: 'bold'}}> Step 1: </p>
              <p> Register as a developer here: </p>
              <a href="https://developers.openapipass.org/login" style={{color: '#72b84c'}}>https://developers.openapipass.org/login</a>
            </div>

            <div style={{marginTop: '18px'}}>
            <p style={{fontSize: '15px', fontWeight: 'bold'}}> Step 2: </p>
              <p> Congratulations on your successful registration! Next, sign into our developer portal to access our APIs. You will see the option to 'Create Project', click on it to embark on our journey!</p>
            </div>

            <div style={{marginTop: '18px'}}>
            <p style={{fontSize: '15px', fontWeight: 'bold'}}> Step 3: </p>
              <p> Once the project has been created, click on the 'Request' tab to get the API key unique to your project. Here are some options you will see that you can request from: </p>
              
              <div style={{marginTop: '5px', marginLeft: '10px'}}>
                <p style={{fontSize: '15px', fontWeight: 'bold'}}> a. Development key: </p>
                <p> Allows you to access the trial server for test out your solutions. </p>
              </div>

              <div style={{marginTop: '5px', marginLeft: '10px'}}>
                <p style={{fontSize: '15px', fontWeight: 'bold'}}> b. Production key: </p>
                <p> Allows you to access 'live' date for your deployed projects. </p>
              </div>

            </div>
            
            <div style={{marginTop: '18px'}}>
              <p style={{fontSize: '15px', fontWeight: 'bold'}}> Step 4: </p>
              <p> Once you have successfully requested for your API key, you can try out any of our APIs listed in our sidebar! Go crazy and have fun! </p>
            </div>

          </div>
        </div>
        </div>
    );
  };
  
export default IntroductionApi;
