//Firebase.js
// import firebase from 'firebase';
// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
//firebase
var firebaseConfig = {
  apiKey: "AIzaSyCybUJHwRgTg3km_ZMapJVTI0jet98zitU",
  authDomain: "stg-sghawkercentres-firebase.firebaseapp.com",
  projectId: "stg-sghawkercentres-firebase",
  storageBucket: "stg-sghawkercentres-firebase.appspot.com",
  messagingSenderId: "526378468930",
  appId: "1:526378468930:web:d550e088374e1ff12f0fdb",
  measurementId: "G-TX03W5FC0Y"
};
if (process.env.REACT_APP_ENV == "live") {
  firebaseConfig = {
    apiKey: "AIzaSyCybUJHwRgTg3km_ZMapJVTI0jet98zitU",
    authDomain: "stg-sghawkercentres-firebase.firebaseapp.com",
    projectId: "stg-sghawkercentres-firebase",
    storageBucket: "stg-sghawkercentres-firebase.appspot.com",
    messagingSenderId: "526378468930",
    appId: "1:526378468930:web:d550e088374e1ff12f0fdb",
    measurementId: "G-TX03W5FC0Y"
  };
}

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

// // Enable appVerificationDisabledForTesting for testing purposes
// auth.settings.appVerificationDisabledForTesting = true;

export default firebaseConfig;