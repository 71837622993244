import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
    Link,
  } from "react-router-dom";
// import pef from '../../assets/diningmode/pef.png'
import partyset from '../../../assets/diningmode/partyset.png'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function PartySetMode() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  
    if (window.innerWidth < 481) {
        return (
            <div style={{display: 'inline-block', marginTop:0,width:'100%'}}> 
              <div onClick={handleOpen}>
                <img src={partyset} alt="Party Set" style={{width:'80%'}}/>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: "center", borderRadius:'20px',width:'90%',border:0}}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }} >
                   <h3 style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '18px',fontWeight: 'bold',letterSpacing: 0.16,marginTop: '10px'
            }}>Choose type of service</h3>
                  </Typography>
                  <div className="button" style={{display: "block"}}>
                  <Link to={'/'}>
                      <button className="button5" style={{marginBottom: "10px", width: "200px", height: '30px', fontSize:'12px'}}>DOWNLOAD APP</button>
                  </Link>
                    <Link to={'/'}>
                      <button className="button5" style={{width: "200px", height: '30px', fontSize:'12px'}}>VIEW MENU ONLY</button>
                  </Link>
                  </div>
                </Box>
              </Modal>
            </div>
          );
    } else {
        return (
            <div style={{display: 'inline-block', marginTop:0,width:'100%'}}> 
              <div onClick={handleOpen}>
                <img src={partyset} alt="Party Set" style={{width:'35%'}}/>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: "center", borderRadius:'20px',width:'60%',border:0}}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }} >
                   <h3 style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '20px',fontWeight: 'bold',letterSpacing: 0.16,marginTop: '10px'
            }}>Choose type of service</h3>
                  </Typography>
                  <div className="button" style={{display: "inline-flex"}}>
                  <Link to={'/'}>
                      <button className="button5" style={{marginBottom: "20px", width: "200px", height: '50px', fontSize:'16px'}}>DOWNLOAD APP</button>
                  </Link>
                    <Link to={'/'}> 
                      <button className="button5" style={{marginBottom: "20px", width: "200px", height: '50px', marginLeft: "20px", fontSize:'16px'}}>VIEW MENU ONLY</button>
                  </Link>
                  </div>
                </Box>
              </Modal>
            </div>
          );
  }
}