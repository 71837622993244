import React, { Component } from "react";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Badge from '@mui/material/Badge';
import "../../../styles/order_styles.css"
import {
    Link,
  } from "react-router-dom";
import { Typography } from "@mui/material";

class Order extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productid: localStorage.getItem('productid'),
      imgurl: localStorage.getItem('imgurl'),
      productavailqty: parseInt(localStorage.getItem('productavailqty')),
      name: localStorage.getItem('name'),
      price: parseFloat(localStorage.getItem('price')),
      addons: JSON.parse(localStorage.getItem('addons')),
      prefs: JSON.parse(localStorage.getItem('prefs')),
      hpb: JSON.parse(localStorage.getItem('hpb')),
      dinemode: localStorage.getItem('dinemode'),
      peftype: localStorage.getItem('peftype'),
      minaddonqty: localStorage.getItem('minaddonqty'),
      discountdesc: (localStorage.getItem('discountpercent') === "null" || localStorage.getItem('discountpercent') === 0) || (localStorage.getItem('discountvalue') === "null" || localStorage.getItem('discountvalue') === 0) ? "" : localStorage.getItem('discountdesc'),
      discountpercent: localStorage.getItem('discountpercent') === "null" ? 0 : parseInt(localStorage.getItem('discountpercent')),
      discountvalue: localStorage.getItem('discountvalue') === "null" ? 0 : parseFloat(localStorage.getItem('discountvalue')),
      productvouchercode: localStorage.getItem('productvouchercode'),
      selectedAddOn:[],
      selectedPrefs:[],
      qty:0,
      remarks:"",
      display:false,
    };
  }

  addAddon(id) {
    if(parseInt(document.getElementById(id).value) <= this.state.productavailqty){
      document.getElementById(id).value = parseInt(document.getElementById(id).value) + 1;
    }
  }
  removeAddon(id){
    if(parseInt(document.getElementById(id).value) > 0){
      document.getElementById(id).value = parseInt(document.getElementById(id).value) - 1;
    }
  }
  addItem() {
    if (this.state.dinemode === "99" && this.state.peftype === "Claim") {
      
      if(document.getElementById(this.state.productid).value < this.state.productavailqty && document.getElementById(this.state.productid).value < 1){
        document.getElementById(this.state.productid).value = parseInt(document.getElementById(this.state.productid).value) + 1;
      }  
    } else {
      console.log(this.state.productavailqty)
      if(parseInt(document.getElementById(this.state.productid).value) < this.state.productavailqty){
        document.getElementById(this.state.productid).value = parseInt(document.getElementById(this.state.productid).value) + 1;
        
      } else {
        alert("Only " + this.state.productavailqty + " stocks available")
      }
    }
  }
  removeItem(){
    if(document.getElementById(this.state.productid).value > 1){
      document.getElementById(this.state.productid).value = parseInt(document.getElementById(this.state.productid).value) - 1;

    }
  }
  handleRemarks(e){
    this.setState({
      remarks: e.target.value
    })
  }
  addToOrder(e){
    e.preventDefault();
    /////////////////////////// ADD ON //////////////////////////
    var addonArr = [];
    var addonArrDisplay = [];
    if(this.state.dinemode !== "99"){
      for(var i = 0;i<this.state.addons.length;i++){
        var addonObj = {};
        var addonObjDisplay = {};
  
        if (document.querySelector('input[id="' + this.state.addons[i].product_id + '"]').value !== null || document.querySelector('input[id="' + this.state.addons[i].product_id + '"]').value !== "") {
          addonObj.product_id = this.state.addons[i].product_id;
          addonObj.add_on_qty = parseInt(document.querySelector('input[id="' + this.state.addons[i].product_id + '"]').value);          
        }
        if (document.querySelector('input[id="' + this.state.addons[i].product_id + '"]').value !== null  || document.querySelector('input[id="' + this.state.addons[i].product_id + '"]').value !== "") {
          addonObjDisplay.quantity = "0";  
          addonObjDisplay.product_id = this.state.addons[i].product_id;
          addonObjDisplay.quantity = parseInt(document.querySelector('input[id="' + this.state.addons[i].product_id + '"]').value);  
          addonObjDisplay.product_name = this.state.addons[i].product_name;
          addonObjDisplay.product_price = this.state.addons[i].product_price;
        } 
        //add obj to arr
        if(document.querySelector('input[id="' + this.state.addons[i].product_id + '"]').value !== "" && document.querySelector('input[id="' + this.state.addons[i].product_id + '"]').value !== null && document.querySelector('input[id="' + this.state.addons[i].product_id + '"]').value !== "0"){
          addonArr.push(addonObj);
          addonArrDisplay.push(addonObjDisplay);

        }
      }
    }
    var qty = 0;
    var proceedOrder = false;
    if (this.state.addons && this.state.addons.length !== 0) {
      if (addonArr && addonArr.length > 0) {
        for (var x = 0; x < addonArr.length; x++) {
          qty += addonArr[x].add_on_qty
        }
        if (qty < this.state.minaddonqty) {
         alert("Minimum " + this.state.minaddonqty + " Add ons");
        } else {
          proceedOrder = true;
        }
      } else {
        if (this.state.minaddonqty > 0) {
          alert("Minimum " + this.state.minaddonqty + " Add ons");
        } else {
          proceedOrder = true;
        }
      }
    } else {
      proceedOrder = true;
    }
    if (proceedOrder === true) {
      /////////////////////////// PREF //////////////////////////
      var currtotalPref = "";
      var totalPref = "";
      var prefStatus = true;

      if(this.state.dinemode !== "99" && this.state.prefs){
        for (var i = 0; i < this.state.prefs.length; i++){
          if (document.querySelector('input[name="' + this.state.prefs[i].preference_name + '"]:checked') !== null) {
            currtotalPref += document.querySelector('input[name="' + this.state.prefs[i].preference_name + '"]:checked').value + ", ";  
          }else{
            prefStatus = false;
          }          
        }
      }

      if (currtotalPref === "") {
        totalPref = "";
      }else{
        totalPref = currtotalPref.slice(0,-1);
      }
      /////////////////////////// ORDER //////////////////////////

      if (localStorage.getItem("orders") === undefined && localStorage.getItem("ordersdisplay") === undefined || localStorage.getItem("orders") === null && localStorage.getItem("ordersdisplay") === null || localStorage.getItem("orders") === "" && localStorage.getItem("ordersdisplay") === "") {
        var discountedprice = 0;
        
        if (this.state.discountpercent > 0) {
          discountedprice = ((this.state.discountpercent / 100) * this.state.price)*parseInt(document.getElementById(this.state.productid).value)
        } else if (this.state.discountvalue > 0 && (this.state.discountpercent === 0 || this.state.discountpercent === 'null' || this.state.discountpercent === null)) {
          discountedprice = this.state.discountvalue*parseInt(document.getElementById(this.state.productid).value)
        }
        console.log(discountedprice);
        var item = [{
          product_id: this.state.productid,
          order_qty: document.getElementById(this.state.productid).value,
          product_voucher_code: this.state.productvouchercode,
          txn_order_consumer_add_ons: addonArr,
          order_pref: totalPref,
          order_remarks: this.state.remarks,
          discount_amt:  ((this.state.price*parseInt(document.getElementById(this.state.productid).value)) - discountedprice) > 0 ? discountedprice : 0,
          discount_percent: this.state.discountpercent ,
          discount_type: (this.state.discountpercent !== 0 || this.state.discountvalue > 0) && discountedprice > 0 ? 1 : 0,
        }]
        var itemdisplay = [{
          product_id: this.state.productid,
          product_name: this.state.name,
          product_imgurl: this.state.imgurl,
          product_price: this.state.price,
          product_discount_percent: this.state.discountpercent,
          product_discount_value: this.state.discountvalue,
          product_voucher_code: this.state.productvouchercode,
          quantity: document.getElementById(this.state.productid).value,
          add_ons:addonArrDisplay,
          order_pref: totalPref,
          remarks: this.state.remarks
        }]
        if (prefStatus === true) {
            var hpbitems = [{
              product_id: this.state.productid,
              order_amt: this.state.price,
              qty: document.getElementById(this.state.productid).value
            }]
    
          localStorage.setItem("hpbitems", JSON.stringify(hpbitems));  
          localStorage.setItem("orders", JSON.stringify(item));  
          localStorage.setItem("ordersdisplay", JSON.stringify(itemdisplay));  
          document.getElementById('addorder').click();   

        } else {
          alert("Please select preference");
        }
      } else {
        if (this.state.dinemode === "99" && this.state.peftype === "Claim") {
          alert("Not allowed to claim more than 1 item");
        } else {
          if (prefStatus === true) { 
            if(localStorage.getItem("hpbitems") !== null){
              var hpbitems = JSON.parse(localStorage.getItem("hpbitems"));
                var hpbitem = {
                  product_id: this.state.productid,
                  order_amt: this.state.price,
                  qty: document.getElementById(this.state.productid).value
                }
                hpbitems.push(hpbitem);
                localStorage.removeItem("hpbitems");
                localStorage.setItem("hpbitems", JSON.stringify(hpbitems));
            }else{
              var hpbitems = [];
                var hpbitem = {
                  product_id: this.state.productid,
                  order_amt: this.state.price,
                  qty: document.getElementById(this.state.productid).value
                }
                hpbitems.push(hpbitem);
                localStorage.removeItem("hpbitems");
                localStorage.setItem("hpbitems", JSON.stringify(hpbitems));
            }
            
            var items = JSON.parse(localStorage.getItem("orders"));
            var itemsdisplay = JSON.parse(localStorage.getItem("ordersdisplay"));
            var discountedprice = 0;
        
            if (this.state.discountpercent > 0) {
              discountedprice = ((this.state.discountpercent / 100) * this.state.price)*parseInt(document.getElementById(this.state.productid).value)
            } else if (this.state.discountvalue > 0 && (this.state.discountpercent === 0 || this.state.discountpercent === 'null' || this.state.discountpercent === null)) {
              discountedprice = this.state.discountvalue*parseInt(document.getElementById(this.state.productid).value)
            }
            console.log(discountedprice);
            var item = {
              product_id: this.state.productid,
              order_qty: document.getElementById(this.state.productid).value,
              product_voucher_code: this.state.productvouchercode,
              txn_order_consumer_add_ons:addonArr,
              order_pref: totalPref,
              order_remarks: this.state.remarks,
              discount_amt:  ((this.state.price*parseInt(document.getElementById(this.state.productid).value)) - discountedprice) > 0 ? discountedprice : 0,
              discount_percent: this.state.discountpercent ,
              discount_type: (this.state.discountpercent !== 0 || this.state.discountvalue > 0) && discountedprice > 0 ? 1 : 0,
      
            }
            var itemdisplay = {
              product_id: this.state.productid,
              product_name: this.state.name,
              product_imgurl: this.state.imgurl,
              product_price: this.state.price,
              product_discount_percent: this.state.discountpercent,
              product_discount_value: this.state.discountvalue,
              product_voucher_code: this.state.productvouchercode,
              quantity: document.getElementById(this.state.productid).value,
              add_ons:addonArrDisplay,
              order_pref: totalPref,
              remarks: this.state.remarks
            }
          
      
            items.push(item);
            itemsdisplay.push(itemdisplay);
      
            localStorage.removeItem("orders");
            localStorage.removeItem("ordersdisplay");
            localStorage.setItem("orders", JSON.stringify(items));
            localStorage.setItem("ordersdisplay", JSON.stringify(itemsdisplay));
            document.getElementById('addorder').click();   
          } else {
            alert("Please select preference");
          }
        }
      }
    }
  }

  render() {
    if (window.innerWidth < 481) {
      return ( 
        <div>
          {(this.state.addons && this.state.addons.length > 0) || (this.state.prefs && this.state.prefs.length > 0) && this.state.dinemode !== "99" ? 
          <form style={{height:'100%'}}>
                <div className="drawerWeb" style={{ height: 'auto', padding:'10px'}}>
              <Link to={{pathname:`/products`}}>
                <button className="backMobile">Back</button>
              </Link>
            </div>
            <h2 style={{textAlign:'center',marginTop:0}}>Add to Order</h2>
                <div style={{ textAlign:'center'}}>
                  <img src={this.state.imgurl} style={{width: '50%', margin:'auto',zIndex: 1,position: 'relative',boxShadow:'0 0 5px #f6f6f6'}}/>
                </div>
              
                <span className="imageTextWeb" style={{position:'relative', padding:'20px'}}>
                <h3 style={{ margin: 0, fontSize: '16px', width: '100%' }}>{this.state.name}</h3>
                { this.state.discountpercent > 0 && this.state.discountvalue > 0 ? <>{(this.state.price * ((100 - this.state.discountpercent)/100)) > 0 ? <><h3 style={{  fontWeight:'300',margin:0,marginRight:'10px',lineHeight: '30px',fontSize:'16px', textAlign:'right', color:'red'}}>
                        ${(this.state.price * ((100 - this.state.discountpercent)/100)).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',margin:0,lineHeight: '30px',fontSize:'16px',textAlign:'end', textDecoration:'line-through'}}>
                    ${this.state.price.toFixed(2)} </h3> </> : <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                    ${this.state.price.toFixed(2)} </h3></> } </> : <></>}
                
                {this.state.discountpercent > 0 && (this.state.discountvalue === null || this.state.discountvalue === 0) ? <>{(this.state.price * ((100 - this.state.discountpercent)/100)) > 0 ? <><h3 style={{  fontWeight:'300',margin:0,marginRight:'10px',lineHeight: '30px',fontSize:'16px', textAlign:'right', color:'red'}}>
                        ${(this.state.price * ((100 - this.state.discountpercent)/100)).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',margin:0,lineHeight: '30px',fontSize:'16px',textAlign:'end', textDecoration:'line-through'}}>
                      ${this.state.price.toFixed(2)} </h3> </> : <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                    ${this.state.price.toFixed(2)} </h3></> } </> : <></>}
                  
                        {  this.state.discountvalue > 0 && (this.state.discountpercent === null || this.state.discountpercent === 0) ? <>{(this.state.price - this.state.discountvalue) > 0 ? <><h3 style={{ fontWeight:'300',margin:0,marginRight:'10px', lineHeight: '30px',fontSize:'16px', textAlign:'right', color:'red'}}>
                        ${(this.state.price - this.state.discountvalue).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',margin:0,lineHeight: '30px',fontSize:'16px',textAlign:'end', textDecoration:'line-through'}}>
                       ${this.state.price.toFixed(2)} </h3> </> :  <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                    ${this.state.price.toFixed(2)} </h3></> } </> : <></>}
                  
                        { (this.state.discountpercent === null || this.state.discountpercent === 0) && (this.state.discountvalue === null || this.state.discountvalue === 0) && <h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                    ${this.state.price.toFixed(2)} </h3>}
                  <p style={{ position: 'absolute', fontSize: '12px', fontWeight: '600', margin: 0, bottom: 0, right: 0, padding: '0 20px'}}>{this.state.discountdesc !== 'null' && (this.state.discountpercent !== 0 || this.state.discountvalue !== 0) ? this.state.discountdesc : '' } </p>
                </span>
            {this.state.prefs.length > 0 && this.state.dinemode !== "99" ? 
              <div style={{transformStyle: 'preserve-3d', margin:'auto', width:'100%', backgroundColor:'#FFFFF', padding:'2px 0'}}>
              <h3 className="textWeb" style={{marginLeft:'10px', color: '#6D7278',fontSize:'16px'}}><span style={{color: 'red'}}>*</span>Select Preferences <span style={{color: 'lightgray', fontWeight:'500', fontSize:'12px'}}> Pick 1 </span></h3>
                <div style={{ display: "inline-flex", flexWrap: "wrap" }}>
                  <div style={{ display: 'inline-block', width: '100%', height: 'fit-content' }}>
                  {this.state.prefs.map((pref, index) => (
                  <div key={index} style={{ display: 'block', margin: '10px' }}>
                    <h4 className="textWeb" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200' ,fontSize:'14px', textDecoration:'underline'}}>{pref.preference_name}</h4>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name={pref.preference_name}
                        style={{ display: 'inline-block' }}>
                      {pref.preference_options.map((opt, index) => (
                        <Card key={index} sx={{ boxShadow: 3, width: 1/2}} style={{marginRight: '20px' ,width: '150px',height:'100px', borderRadius:'10px',position: 'relative', display:'inline-block'}}>
                            <CardContent>
                            <FormControlLabel className="text" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200' ,fontSize:'12px'}} key={index} value={opt.preference_option_name} control={<Radio />} label={<Typography style={{fontSize:'12px'}}>{opt.preference_option_name}</Typography>} />
                          </CardContent>
                        </Card>
                        ))}
                      </RadioGroup>
                    </div>
                  ))}
                </div>
              </div>
            </div>:<></>}
            {this.state.addons.length > 0 && this.state.dinemode !== "99" ? 
            <div style={{transformStyle: 'preserve-3d', margin:'auto', width:'10  0%', backgroundColor:'#FFFFF', padding:'2px 0 2px 0',marginTop:'10px'}}>
              <h3 className="textWeb" style={{marginLeft:'10px',color: '#6D7278',fontSize:'16px'}}>Select Add Ons <span style={{color: 'lightgray', fontWeight:'500', fontSize:'12px'}}> Optional </span></h3>
              <div> 
                <FormControl style={{display:'inline-block', width:'100%',height:'fit-content'}}>
                {this.state.addons.map((addon, index) => (
                  <div key={index} style={{display:'inline-block', margin:'10px'}}>
                    <Card sx={{ boxShadow: 3, width: 1 / 2 }} style={{ width: '150px', height: '100px', borderRadius: '10px', position: 'relative' }}>
                      <CardContent>
                        <h4 className="textWeb" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200',fontSize:'12px'}}>{addon.product_name}</h4>
                        <div style={{position: 'absolute',bottom: '20px',width: '80%'}}>
                          <h5 className="textWeb" style={{ fontWeight: "bold", marginBottom: '10px', color: '#6D7278', width: '50%', margin: 0, display: 'inline' ,fontSize:'12px'}}>${addon.product_price}</h5>
                          {addon.calorie_count !== -1 ? 
                          <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '35px',bottom: '0px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: addon.calorie_color_sys_code,
                            width:'80px',
                            fontSize:'12px',
                            padding:'10px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={addon.calorie_count + " kcals"} />
                          : <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '35px',bottom: '0px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: 'white',
                            width:'80px',
                            fontSize:'12px',
                            padding:'10px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={""} />
                        }
                          
                        </div>
                  </CardContent>
                </Card>
                <div style={{margin:'5px 2px'}}>
                  <Button onClick={()=>this.removeAddon(addon.product_id)} variant="contained" style={{minWidth:0,width:'20px',height:'30px',borderRadius:'10px', fontSize:'12px', backgroundColor:'#5BBA44', color:'white', marginRight:'10px'}}> - </Button>
                       <input id={addon.product_id} readOnly placeholder="0" value={0} max={addon.add_on_upper_limit}
                  style={{height: "30px", width: "60px", fontSize:'12px',fontWeight:'bold',color:'#1F5626', textAlign:'center', verticalAlign:'bottom',borderStyle:'solid',borderRadius:'10px',borderColor:'#5BBA44',borderWidth:'3px'}}
                  />
                  <Button onClick={()=>this.addAddon(addon.product_id)} variant="contained" style={{minWidth:0,width:'20px',height:'30px',borderRadius:'10px',fontSize:'12px', backgroundColor:'#5BBA44', color:'white', marginLeft:'10px'}}> + </Button>
                </div>
                </div>
              ))}
              </FormControl>
              
              </div>
            </div>:<></>}
    
    
            <div className="checkoutWeb" style={{marginTop: "2%", position:'sticky',zIndex:1}}>  
              <div className="p1" style={{display: "inline-flex",padding:'5px', margin:'auto'}}>
                  <div name="orders" id="orders" className="orders"
                  style={{height: "fit-content", textAlign: "center",borderRadius:'10px',borderColor:'#5BBA44',borderWidth:'3px', margin:'auto',width:'40%'}}> 
                      <input id={this.state.productid} readOnly placeholder="1" value={1} style={{width: "inherit", fontSize:'16px',fontWeight:'bold',color:'#1F5626', textAlign:'center', border:'none', padding:'15px 0'}}/>
                  </div>
                  <div className="button" style={{margin:'auto'}}>
                      <Button onClick={()=>this.addItem()} style={{ height: "25px", width: "40%", fontSize:'18px', borderRadius: '10px',backgroundColor: '#5BBA44', color: 'white', padding: '5px', paddingLeft: '25px', paddingRight: '25px', border:0, margin:'2px 5px'}}>+</Button>
                      <Button onClick={()=>this.removeItem()} style={{ height: "25px", width: "40%", fontSize:'16px', borderRadius: '10px',backgroundColor: '#5BBA44', color: 'white', padding: '5px', paddingLeft: '25px', paddingRight: '25px', border:0, margin:'2px 5px'}}>-</Button>
                  </div>
                  <div style={{textAlign:"center", width:'200px', margin:'auto'}}>
                      <button className="button2Mobile" onClick={(e)=>this.addToOrder(e)} style={{ fontSize:'12px', fontWeight:'bold'}}>ADD TO ORDER</button>
                      <Link to='/products'>
                        <button id="addorder" className="button2Mobile" style={{ fontSize:'12px', fontWeight:'bold',display:'none'}}>ADD TO ORDER</button>
                      </Link>
                  </div>
              </div>
            </div>
            </form> :
            <>
          <form style={{height:'100%'}}>
                <div className="drawerWeb" style={{ height: 'auto', padding:'10px'}}>
              <Link to={{pathname:`/products`}}>
                <button className="backMobile" >Back</button>
              </Link>
            </div>
            <h2 style={{textAlign:'center',marginTop:0}}>Add to Order</h2>
                <div style={{ textAlign:'center'}}>
                  <img src={this.state.imgurl} style={{width: '50%', margin:'auto',zIndex: -1,position: 'relative',boxShadow:'0 0 5px #f6f6f6'}}/>
                </div>
              
                <span className="imageTextWeb" style={{position:'relative', padding:'20px'}}>
                  <h3 style={{ margin: 0, fontSize: '16px', width: '100%' }}>{this.state.name}</h3>
                  { this.state.discountpercent > 0 && this.state.discountvalue > 0 ? <>{(this.state.price * ((100 - this.state.discountpercent)/100)) > 0 ? <><h3 style={{  fontWeight:'300',margin:0,marginRight:'10px',lineHeight: '30px',fontSize:'16px', textAlign:'right', color:'red'}}>
                        ${(this.state.price * ((100 - this.state.discountpercent)/100)).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',margin:0,lineHeight: '30px',fontSize:'16px',textAlign:'end', textDecoration:'line-through'}}>
                    ${this.state.price.toFixed(2)} </h3> </> : <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                    ${this.state.price.toFixed(2)} </h3></> } </> : <></>}
                
                {this.state.discountpercent > 0 && (this.state.discountvalue === null || this.state.discountvalue === 0) ? <>{(this.state.price * ((100 - this.state.discountpercent)/100)) > 0 ? <><h3 style={{  fontWeight:'300',margin:0,marginRight:'10px',lineHeight: '30px',fontSize:'16px', textAlign:'right', color:'red'}}>
                        ${(this.state.price * ((100 - this.state.discountpercent)/100)).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',margin:0,lineHeight: '30px',fontSize:'16px',textAlign:'end', textDecoration:'line-through'}}>
                      ${this.state.price.toFixed(2)} </h3> </> : <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                    ${this.state.price.toFixed(2)} </h3></> } </> : <></>}
                  
                        {  this.state.discountvalue > 0 && (this.state.discountpercent === null || this.state.discountpercent === 0) ? <>{(this.state.price - this.state.discountvalue) > 0 ? <><h3 style={{ fontWeight:'300',margin:0,marginRight:'10px', lineHeight: '30px',fontSize:'16px', textAlign:'right', color:'red'}}>
                        ${(this.state.price - this.state.discountvalue).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',margin:0,lineHeight: '30px',fontSize:'16px',textAlign:'end', textDecoration:'line-through'}}>
                       ${this.state.price.toFixed(2)} </h3> </> :  <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                    ${this.state.price.toFixed(2)} </h3></> } </> : <></>}
                  
                        { (this.state.discountpercent === null || this.state.discountpercent === 0) && (this.state.discountvalue === null || this.state.discountvalue === 0) && <h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                    ${this.state.price.toFixed(2)} </h3>}
                  <p style={{ position: 'absolute', fontSize: '12px', fontWeight: '600', margin: 0, bottom: 0, right: 0, padding: '0 20px'}}>{this.state.discountdesc !== 'null' && (this.state.discountpercent !== 0 || this.state.discountvalue !== 0) ? this.state.discountdesc : ''  } </p>
                </span>
            {this.state.prefs.length > 0 && this.state.dinemode !== "99" ? 
              <div style={{ padding: '20px', maxHeight:'1110px', backgroundColor:'#FFFFF'}}>
              <h3 className="text" style={{color: '#6D7278',fontSize:'32px'}}><span style={{color: 'red'}}>*</span>Select Preferences <span style={{color: 'lightgray', fontWeight:'500', fontSize:'24px'}}> Pick 1 </span></h3>
              <div style={{display: "inline-flex", flexWrap: "wrap"}}>
                      <div style={{display:'inline-block', width:'100%', overflow:'auto'}}>
                        {this.state.prefs.map((pref, index) => (
                        
                        <div key={index} style={{ display: 'inline-block', marginRight: '20px' }}>
                          <h4 className="text" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200' }}>{pref.preference_name}</h4>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name={pref.preference_name}
                              style={{ display: 'inline-block' }}>
                            {pref.preference_options.map((opt, index) => (
                              <Card key={index} sx={{ boxShadow: 3, width: 1/4}} style={{width: '263px',height:'200px', borderRadius:'10px',position: 'relative',display: 'inline-block',marginRight:'10px'}}>
                                  <CardContent>
                                  <FormControlLabel className="text" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200' }} key={index} value={opt.preference_option_name} control={<Radio />} label={<Typography style={{fontSize:'24px'}}>{opt.preference_option_name}</Typography>} />
                                </CardContent>
                              </Card>
                              ))}
                            </RadioGroup>
                    </div>
                  ))}
                  </div>
              </div>
            </div>:<></>}
            {this.state.addons.length > 0 && this.state.dinemode !== "99" ? 
            <div style={{padding:'20px', paddingTop:0,paddingLeft:'80px',transformStyle: 'preserve-3d', margin:'auto', width:'90%'}}>
              <h3 className="textWeb" style={{marginLeft:'10px',color: '#6D7278',fontSize:'18px'}}>Select Add Ons <span style={{color: 'lightgray', fontWeight:'500', fontSize:'16px'}}> Optional </span></h3>
              <div> 
                <FormControl style={{display:'inline-block', width:'100%',height:'fit-content'}}>
                {this.state.addons.map((addon, index) => (
                  <div key={index} style={{display:'inline-block', margin:'10px'}}>
                    <Card sx={{ boxShadow: 3, width: 1 / 4 }} style={{ width: '265px', height: '150px', borderRadius: '10px', position: 'relative' }}>
                      <CardContent>
                        <h4 className="textWeb" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200' }}>{addon.product_name}</h4>
                        <div style={{position: 'absolute',bottom: '20px',width: '80%'}}>
                          <h5 className="textWeb" style={{ fontWeight: "bold", marginBottom: '10px', color: '#6D7278', width: '50%', margin: 0, display: 'inline' }}>${addon.product_price}</h5>
                          {addon.calorie_count !== -1 ? 
                          <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '30px',bottom: '10px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: addon.calorie_color_sys_code,
                            width:'110px',
                            fontSize:'16px',
                            padding:'15px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={addon.calorie_count + " kcals"} />
                          : <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '30px',bottom: '10px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: 'white',
                            width:'110px',
                            fontSize:'16px',
                            padding:'15px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={""} />
                        }
                          
                        </div>
                  </CardContent>
                </Card>
                <div style={{margin:'20px'}}>
                  <Button onClick={()=>this.removeAddon(addon.product_id)} variant="contained" style={{width:'30px',height:'50px',borderRadius:'10px', fontSize:'18px', backgroundColor:'#5BBA44', color:'white', marginRight:'10px'}}> - </Button>
                       <input id={addon.product_id} readOnly placeholder="0" value={0} max={addon.add_on_upper_limit}
                  style={{height: "50px", width: "80px", fontSize:'18px',fontWeight:'bold',color:'#1F5626', textAlign:'center', verticalAlign:'bottom',borderStyle:'solid',borderRadius:'10px',borderColor:'#5BBA44',borderWidth:'3px'}}
                  />
                  <Button onClick={()=>this.addAddon(addon.product_id)} variant="contained" style={{width:'30px',height:'50px',borderRadius:'10px',fontSize:'18px', backgroundColor:'#5BBA44', color:'white', marginLeft:'10px'}}> + </Button>
                </div>
                </div>
              ))}
              </FormControl>
              
              </div>
            </div>:<></>}
                <div className="checkoutWeb" style={{ marginTop: "2%", position:'sticky',zIndex:1}}>  
              <div className="p1" style={{display: "inline-flex",padding:'5px', margin:'auto'}}>
                  <div name="orders" id="orders" className="orders"
                  style={{height: "fit-content", textAlign: "center",borderRadius:'10px',borderColor:'#5BBA44',borderWidth:'3px', margin:'auto',width:'40%'}}> 
                      <input id={this.state.productid} readOnly placeholder="1" value={1} style={{width: "inherit", fontSize:'16px',fontWeight:'bold',color:'#1F5626', textAlign:'center', border:'none', padding:'15px 0'}}/>
                  </div>
                  <div className="button" style={{margin:'auto'}}>
                      <Button onClick={()=>this.addItem()} style={{height: "25px", width: "40%", fontSize:'18px', borderRadius: '10px',backgroundColor: '#5BBA44', color: 'white', padding: '5px', paddingLeft: '25px', paddingRight: '25px', border:0, margin:'2px 5px'}}>+</Button>
                      <Button onClick={()=>this.removeItem()} style={{height: "25px", width: "40%", fontSize:'16px', borderRadius: '10px',backgroundColor: '#5BBA44', color: 'white', padding: '5px', paddingLeft: '25px', paddingRight: '25px', border:0, margin:'2px 5px'}}>-</Button>
                  </div>
                  <div style={{textAlign:"center", width:'200px', margin:'auto'}}>
                      <button className="button2Mobile" onClick={(e)=>this.addToOrder(e)} style={{fontSize:'12px', fontWeight:'bold'}}>ADD TO ORDER</button>
                      <Link to='/products'>
                        <button id="addorder" className="button2Mobile" style={{fontSize:'12px', fontWeight:'bold',display:'none'}}>ADD TO ORDER</button>
                      </Link>
                  </div>
              </div>
            </div>
            </form>
            </>
            
          }
          </div>
          
        );
    } else {
      return ( 
        <div>
          {(this.state.addons && this.state.addons.length > 0) || (this.state.prefs && this.state.prefs.length > 0) && this.state.dinemode !== "99" ? 
          <form style={{height:'100%'}}>
            <div className="drawerWeb" style={{ height: 'auto', padding:'30px'}}>
              <Link to={{pathname:`/products`}}>
                <button className="backWeb" >Back</button>
              </Link>
            </div>
            <h1 style={{textAlign:'center',marginTop:0}}>Add to Order</h1>
    
                <div style={{ textAlign:'center'}}>
                  <img src={this.state.imgurl} style={{width: '25%',height:'300px', margin:'auto',zIndex: -1,position: 'relative',boxShadow:'0 0 5px #f6f6f6'}}/>
                </div>
              
                <span className="imageTextWeb" style={{position:'relative'}}>
                    <h3 style={{margin:0, lineHeight: '50px',fontSize:'20px', width:'100%'}}>{this.state.name}</h3>
                { this.state.discountpercent > 0 && this.state.discountvalue > 0 ?  <>{(this.state.price * ((100 - this.state.discountpercent)/100)) > 0 ? <><h3 style={{  fontWeight:'300',marginTop:'10px',marginRight:'10px',lineHeight: '30px',fontSize:'20px', textAlign:'right', color:'red'}}>
                        ${(this.state.price * ((100 - this.state.discountpercent)/100)).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',marginTop:'10px',lineHeight: '30px',fontSize:'20px',textAlign:'end', textDecoration:'line-through'}}>
                      ${this.state.price.toFixed(2)} </h3> </> :  <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                  ${this.state.price.toFixed(2)} </h3></>} </> : <></>}
                
                { this.state.discountpercent > 0 && (this.state.discountvalue === null || this.state.discountvalue === 0) ? <>{(this.state.price * ((100 - this.state.discountpercent)/100)) > 0 ? <><h3 style={{  fontWeight:'300',marginTop:'10px',marginRight:'10px',lineHeight: '30px',fontSize:'20px', textAlign:'right', color:'red'}}>
                        ${(this.state.price * ((100 - this.state.discountpercent)/100)).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',marginTop:'10px',lineHeight: '30px',fontSize:'20px',textAlign:'end', textDecoration:'line-through'}}>
                      ${this.state.price.toFixed(2)} </h3> </> : <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                  ${this.state.price.toFixed(2)} </h3></>} </> : <></>}
                  
                        {  this.state.discountvalue > 0 && (this.state.discountpercent === null || this.state.discountpercent === 0) ? <>{(this.state.price - this.state.discountvalue) > 0 ? <><h3 style={{ fontWeight:'300',marginTop:'10px',marginRight:'10px', lineHeight: '30px',fontSize:'20px', textAlign:'right', color:'red'}}>
                        ${(this.state.price - this.state.discountvalue).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',marginTop:'10px',lineHeight: '30px',fontSize:'20px',textAlign:'end', textDecoration:'line-through'}}>
                       ${this.state.price.toFixed(2)} </h3> </> : <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                  ${this.state.price.toFixed(2)} </h3></>} </> : <></>}
                  
                        { (this.state.discountpercent === null || this.state.discountpercent === 0) && (this.state.discountvalue === null || this.state.discountvalue === 0) && <h3 style={{ fontWeight:'300',marginTop:'10px', lineHeight: '30px',fontSize:'20px', width:'15%', textAlign:'right'}}>
                    ${this.state.price.toFixed(2)} </h3>}
                  <p style={{ position: 'absolute', fontSize: '16px', fontWeight: '600', margin: 0, bottom: 0, right: 0, padding: '0px 350px 0px 350px'}}>{this.state.discountdesc !== 'null' && (this.state.discountpercent !== 0 || this.state.discountvalue !== 0) ? this.state.discountdesc : '' } </p>

                </span>
            {/* </div> */}
            {this.state.prefs.length > 0 && this.state.dinemode !== "99" ? 
              <div style={{padding:'20px', paddingTop:'5px',paddingLeft:'80px',transformStyle: 'preserve-3d', margin:'auto', width:'90%', backgroundColor:'#FFFFF'}}>
              <h3 className="textWeb" style={{color: '#6D7278',fontSize:'18px'}}><span style={{color: 'red'}}>*</span>Select Preferences <span style={{color: 'lightgray', fontWeight:'500', fontSize:'16px'}}> Pick 1 </span></h3>
                <div style={{ display: "inline-flex", flexWrap: "wrap" }}>
                  <div style={{ display: 'inline-flex', flexWrap:"wrap",width: '100%', height: 'fit-content' }}>
                  {this.state.prefs.map((pref, index) => (
                  <div key={index} style={{ display: 'block', margin: '10px' }}>
                    <h4 className="text" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200', fontSize:'20px', textDecoration:'underline' }}>{pref.preference_name}</h4>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name={pref.preference_name}
                        style={{ display: 'inline-block' }}>
                      {pref.preference_options.map((opt, index) => (
                        <Card key={index} sx={{ boxShadow: 3, width: 1/4}} style={{width: '265px',height:'150px', borderRadius:'10px',position: 'relative', margin:'10px 0'}}>
                            <CardContent>
                            <FormControlLabel className="text" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200' }} key={index} value={opt.preference_option_name} control={<Radio />} label={<Typography style={{fontSize:'16px'}}>{opt.preference_option_name}</Typography>} />
                          </CardContent>
                        </Card>
                        ))}
                      </RadioGroup>
                    </div>
                  ))}
                </div>
              </div>
            </div>:<></>}
            {this.state.addons.length > 0 && this.state.dinemode !== "99" ? 
            <div style={{padding:'20px', paddingTop:0,paddingLeft:'80px',transformStyle: 'preserve-3d', margin:'auto', width:'90%'}}>
              <h3 className="textWeb" style={{marginLeft:'10px',color: '#6D7278',fontSize:'18px'}}>Select Add Ons <span style={{color: 'lightgray', fontWeight:'500', fontSize:'16px'}}> Optional </span></h3>
              <div> 
                <FormControl style={{display:'inline-block', width:'100%',height:'fit-content'}}>
                {this.state.addons.map((addon, index) => (
                  <div key={index} style={{display:'inline-block', margin:'10px'}}>
                    <Card sx={{ boxShadow: 3, width: 1 / 4 }} style={{ width: '265px', height: '150px', borderRadius: '10px', position: 'relative' }}>
                      <CardContent>
                        <h4 className="textWeb" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200' }}>{addon.product_name}</h4>
                        <div style={{position: 'absolute',bottom: '20px',width: '80%'}}>
                          <h5 className="textWeb" style={{ fontWeight: "bold", marginBottom: '10px', color: '#6D7278', width: '50%', margin: 0, display: 'inline' }}>${addon.product_price}</h5>
                          {addon.calorie_count !== -1 ? 
                          <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '30px',bottom: '10px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: addon.calorie_color_sys_code,
                            width:'110px',
                            fontSize:'16px',
                            padding:'15px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={addon.calorie_count + " kcals"} />
                          : <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '30px',bottom: '10px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: 'white',
                            width:'110px',
                            fontSize:'16px',
                            padding:'15px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={""} />
                        }
                          
                        </div>
                  </CardContent>
                </Card>
                <div style={{margin:'20px'}}>
                  <Button onClick={()=>this.removeAddon(addon.product_id)} variant="contained" style={{width:'30px',height:'50px',borderRadius:'10px', fontSize:'18px', backgroundColor:'#5BBA44', color:'white', marginRight:'10px'}}> - </Button>
                       <input id={addon.product_id} readOnly placeholder="0" value={0} max={addon.add_on_upper_limit}
                  style={{height: "50px", width: "80px", fontSize:'18px',fontWeight:'bold',color:'#1F5626', textAlign:'center', verticalAlign:'bottom',borderStyle:'solid',borderRadius:'10px',borderColor:'#5BBA44',borderWidth:'3px'}}
                  />
                  <Button onClick={()=>this.addAddon(addon.product_id)} variant="contained" style={{width:'30px',height:'50px',borderRadius:'10px',fontSize:'18px', backgroundColor:'#5BBA44', color:'white', marginLeft:'10px'}}> + </Button>
                </div>
                </div>
              ))}
              </FormControl>
              
              </div>
            </div>:<></>}
    
    
            <div className="checkoutWeb" style={{marginTop: "2%", position:'sticky'}}>  
              <div className="p1" style={{display: "inline-flex",padding:'20px', margin:'auto'}}>
                  <div name="orders" id="orders" className="orders"
                  style={{height: "65px", width: "350px", textAlign: "center",borderRadius:'10px',borderColor:'#5BBA44',borderWidth:'3px'}}> 
                      <input id={this.state.productid} readOnly placeholder="1" value={1} style={{marginTop:'20px', width: "100px", fontSize:'18px',fontWeight:'bold',color:'#1F5626', textAlign:'center', border:'none'}}/>
                  </div>
                  <div className="button" style={{display: "grid", marginLeft: "20px"}}>
                      <Button onClick={()=>this.addItem()} style={{margin: "10px", marginBottom: 0,height: '40px', width: "150px", fontSize:'18px', borderRadius: '10px',backgroundColor: '#5BBA44', color: 'white', padding: '5px', paddingLeft: '25px', paddingRight: '25px', border:0 }}>+</Button>
                      <Button onClick={()=>this.removeItem()} style={{margin: "10px", height: '40px', width: "150px", fontSize:'18px', borderRadius: '10px',backgroundColor: '#5BBA44', color: 'white', padding: '5px', paddingLeft: '25px', paddingRight: '25px', border:0}}>-</Button>
                  </div>
                  <div style={{width: "366px", textAlign:"center", marginTop: "10px", marginLeft:'20px'}}>
                      <button className="button2" onClick={(e)=>this.addToOrder(e)} style={{width: "350px", height: "65px", fontSize:'18px', marginTop:'10px', fontWeight:'bold'}}>ADD TO ORDER</button>
                      <Link to='/products'>
                        <button id="addorder" className="button2" style={{width: "350px", height: "65px", fontSize:'18px', marginTop:'10px', fontWeight:'bold',display:'none'}}>ADD TO ORDER</button>
                      </Link>
                  </div>
              </div>
            </div>
            </form> :
            <>
          <form style={{height:'100%'}}>
                <div className="drawerWeb" style={{ height: 'auto', padding:'30px'}}>
              {/* <Toolbar/> */}
              <Link to={{pathname:`/products`}}>
                <button className="backWeb" >Back</button>
              </Link>
            </div>
            <h1 style={{textAlign:'center',marginTop:0}}>Add to Order</h1>
                <div style={{ textAlign:'center'}}>
                  <img src={this.state.imgurl} style={{width: '25%',height:'300px', margin:'auto',zIndex: -1,position: 'relative',boxShadow:'0 0 5px #f6f6f6'}}/>
                </div>
              
                <span className="imageTextWeb" style={{position:'relative'}}>
                  <h3 style={{ margin: 0, lineHeight: '50px', fontSize: '20px', width: '100%' }}>{this.state.name}</h3>
                  { this.state.discountpercent > 0 && this.state.discountvalue > 0 ?  <>{(this.state.price * ((100 - this.state.discountpercent)/100)) > 0 ? <><h3 style={{  fontWeight:'300',marginTop:'10px',marginRight:'10px',lineHeight: '30px',fontSize:'20px', textAlign:'right', color:'red'}}>
                        ${(this.state.price * ((100 - this.state.discountpercent)/100)).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',marginTop:'10px',lineHeight: '30px',fontSize:'20px',textAlign:'end', textDecoration:'line-through'}}>
                      ${this.state.price.toFixed(2)} </h3> </> :  <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                  ${this.state.price.toFixed(2)} </h3></>} </> : <></>}
                
                { this.state.discountpercent > 0 && (this.state.discountvalue === null || this.state.discountvalue === 0) ? <>{(this.state.price * ((100 - this.state.discountpercent)/100)) > 0 ? <><h3 style={{  fontWeight:'300',marginTop:'10px',marginRight:'10px',lineHeight: '30px',fontSize:'20px', textAlign:'right', color:'red'}}>
                        ${(this.state.price * ((100 - this.state.discountpercent)/100)).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',marginTop:'10px',lineHeight: '30px',fontSize:'20px',textAlign:'end', textDecoration:'line-through'}}>
                      ${this.state.price.toFixed(2)} </h3> </> : <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                  ${this.state.price.toFixed(2)} </h3></>} </> : <></>}
                  
                        {  this.state.discountvalue > 0 && (this.state.discountpercent === null || this.state.discountpercent === 0) ? <>{(this.state.price - this.state.discountvalue) > 0 ? <><h3 style={{ fontWeight:'300',marginTop:'10px',marginRight:'10px', lineHeight: '30px',fontSize:'20px', textAlign:'right', color:'red'}}>
                        ${(this.state.price - this.state.discountvalue).toFixed(2)} </h3> <h3 style={{ fontWeight:'300',marginTop:'10px',lineHeight: '30px',fontSize:'20px',textAlign:'end', textDecoration:'line-through'}}>
                       ${this.state.price.toFixed(2)} </h3> </> : <><h3 style={{ fontWeight:'300',margin:0, lineHeight: '30px',fontSize:'16px', width:'15%', textAlign:'right'}}>
                  ${this.state.price.toFixed(2)} </h3></>} </> : <></>}
                  
                        { (this.state.discountpercent === null || this.state.discountpercent === 0) && (this.state.discountvalue === null || this.state.discountvalue === 0) && <h3 style={{ fontWeight:'300',marginTop:'10px', lineHeight: '30px',fontSize:'20px', width:'15%', textAlign:'right'}}>
                    ${this.state.price.toFixed(2)} </h3>}
                  <p style={{ position: 'absolute', fontSize: '16px', fontWeight: '600', margin: 0, bottom: 0, right: 0, padding: '0px 350px 0px 350px'}}>{this.state.discountdesc !== 'null' && (this.state.discountpercent !== 0 || this.state.discountvalue !== 0) ? this.state.discountdesc : '' } </p>
                </span>
            {this.state.prefs.length > 0 && this.state.dinemode !== "99" ? 
              <div style={{ padding: '20px', maxHeight:'1110px', backgroundColor:'#FFFFF'}}>
              <h3 className="text" style={{color: '#6D7278',fontSize:'32px'}}><span style={{color: 'red'}}>*</span>Select Preferences <span style={{color: 'lightgray', fontWeight:'500', fontSize:'24px'}}> Pick 1 </span></h3>
              <div style={{display: "inline-flex", flexWrap: "wrap"}}>
                      <div style={{display:'inline-block', width:'100%', overflow:'auto'}}>
                        {this.state.prefs.map((pref, index) => (
                        
                        <div key={index} style={{ display: 'inline-flex', felxWrap:"wrap",marginRight: '20px' }}>
                          <h4 className="text" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200' }}>{pref.preference_name}</h4>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name={pref.preference_name}
                              style={{ display: 'inline-block' }}>
                            {pref.preference_options.map((opt, index) => (
                              <Card key={index} sx={{ boxShadow: 3, width: 1/4}} style={{width: '263px',height:'200px', borderRadius:'10px',position: 'relative',display: 'inline-block', margin:'10px 0'}}>
                                  <CardContent>
                                  <FormControlLabel className="text" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200' }} key={index} value={opt.preference_option_name} control={<Radio />} label={<Typography style={{fontSize:'24px'}}>{opt.preference_option_name}</Typography>} />
                                </CardContent>
                              </Card>
                              ))}
                            </RadioGroup>
                    </div>
                  ))}
                  </div>
              </div>
            </div>:<></>}
            {this.state.addons.length > 0 && this.state.dinemode !== "99" ? 
            <div style={{padding:'20px', paddingTop:0,paddingLeft:'80px',transformStyle: 'preserve-3d', margin:'auto', width:'90%'}}>
              <h3 className="textWeb" style={{marginLeft:'10px',color: '#6D7278',fontSize:'18px'}}>Select Add Ons <span style={{color: 'lightgray', fontWeight:'500', fontSize:'16px'}}> Optional </span></h3>
              <div> 
                <FormControl style={{display:'inline-block', width:'100%',height:'fit-content'}}>
                {this.state.addons.map((addon, index) => (
                  <div key={index} style={{display:'inline-block', margin:'10px'}}>
                    <Card sx={{ boxShadow: 3, width: 1 / 4 }} style={{ width: '265px', height: '150px', borderRadius: '10px', position: 'relative' }}>
                      <CardContent>
                        <h4 className="textWeb" style={{ marginTop: '10px', color: '#6D7278', fontWeight: '200' }}>{addon.product_name}</h4>
                        <div style={{position: 'absolute',bottom: '20px',width: '80%'}}>
                          <h5 className="textWeb" style={{ fontWeight: "bold", marginBottom: '10px', color: '#6D7278', width: '50%', margin: 0, display: 'inline' }}>${addon.product_price}</h5>
                          {addon.calorie_count !== -1 ? 
                          <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '30px',bottom: '10px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: addon.calorie_color_sys_code,
                            width:'110px',
                            fontSize:'16px',
                            padding:'15px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={addon.calorie_count + " kcals"} />
                          : <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '30px',bottom: '10px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: 'white',
                            width:'110px',
                            fontSize:'16px',
                            padding:'15px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={""} />
                        }
                          
                        </div>
                  </CardContent>
                </Card>
                <div style={{margin:'20px'}}>
                  <Button onClick={()=>this.removeAddon(addon.product_id)} variant="contained" style={{width:'30px',height:'50px',borderRadius:'10px', fontSize:'18px', backgroundColor:'#5BBA44', color:'white', marginRight:'10px'}}> - </Button>
                       <input id={addon.product_id} readOnly placeholder="0" value={0} max={addon.add_on_upper_limit}
                  style={{height: "50px", width: "80px", fontSize:'18px',fontWeight:'bold',color:'#1F5626', textAlign:'center', verticalAlign:'bottom',borderStyle:'solid',borderRadius:'10px',borderColor:'#5BBA44',borderWidth:'3px'}}
                  />
                  <Button onClick={()=>this.addAddon(addon.product_id)} variant="contained" style={{width:'30px',height:'50px',borderRadius:'10px',fontSize:'18px', backgroundColor:'#5BBA44', color:'white', marginLeft:'10px'}}> + </Button>
                </div>
                </div>
              ))}
              </FormControl>
              
              </div>
            </div>:<></>}
    
    
            <div className="checkoutWeb" style={{marginTop: "2%"}}>  
              <div className="p1" style={{display: "inline-flex",padding:'20px', margin:'auto'}}>
                  <div name="orders" id="orders" className="orders"
                  style={{height: "65px", width: "350px", textAlign: "center",borderRadius:'10px',borderColor:'#5BBA44',borderWidth:'3px'}}> 
                      <input id={this.state.productid} readOnly placeholder="1" value={1} style={{marginTop:'20px', width: "100px", fontSize:'18px',fontWeight:'bold',color:'#1F5626', textAlign:'center', border:'none'}}/>
                  </div>
                  <div className="button" style={{display: "grid", marginLeft: "20px"}}>
                      <Button onClick={()=>this.addItem()} style={{margin: "10px", marginBottom: 0,height: '40px', width: "150px", fontSize:'18px', borderRadius: '10px',backgroundColor: '#5BBA44', color: 'white', padding: '5px', paddingLeft: '25px', paddingRight: '25px', border:0 }}>+</Button>
                      <Button onClick={()=>this.removeItem()} style={{margin: "10px", height: '40px', width: "150px", fontSize:'18px', borderRadius: '10px',backgroundColor: '#5BBA44', color: 'white', padding: '5px', paddingLeft: '25px', paddingRight: '25px', border:0}}>-</Button>
                  </div>
                  <div style={{width: "366px", textAlign:"center", marginTop: "10px", marginLeft:'20px'}}>
                      <button className="button2" onClick={(e)=>this.addToOrder(e)} style={{width: "350px", height: "65px", fontSize:'18px', marginTop:'10px', fontWeight:'bold'}}>ADD TO ORDER</button>
                      <Link to='/products'>
                        <button id="addorder" className="button2" style={{width: "350px", height: "65px", fontSize:'18px', marginTop:'10px', fontWeight:'bold',display:'none'}}>ADD TO ORDER</button>
                      </Link>
                  </div>
              </div>
            </div>
            </form>
            </>
            
          }
          </div>
          
        );
   }
  }
}
 
export default Order;