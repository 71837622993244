import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Divider from '@mui/material/Divider';

const logo = localStorage.getItem('imgurl');

//nav container styling
const Header = tw.header`
  flex justify-between items-center
 mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */

 //navbar styling
export const NavLink = tw.a`
  text-lg lg:text-sm lg:mx-0 lg:my-0
  font-semibold tracking-wide transition duration-300
  px-4 py-3 rounded bg-white 
   hocus:text-white 
  border-b-0
`;

//register now button styling
export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-white text-black
  hocus:text-black 
  border-b-0
`;

//logo link styling
export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 hocus:bg-white`};

 
`;

//mobile link container styling
export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none text-black hocus:text-black transition duration-300 border-0 bg-white
`;

//desktop link styling
export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center 
`;

export default ({ roundedHeaderButton = false, logoLink,logoLinkMobile, links, cart, className, collapseBreakpointClass = "lg" }) => {
  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink href="/#">Open API</NavLink>
      <NavLink href="/#">Sign In</NavLink>
      <NavLink href="/#">Sign Up</NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="/#">Register Now</PrimaryLink>
    </NavLinks>
  ];

  // const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink >
      {/* <img src={logo} alt="logo" style={{width:'16rem'}}/> */}
    </LogoLink>
  );
  const defaultLogoLinkMobile = (
    <LogoLink href="/">
      {/* <img src={logo} alt="logo" style={{width:'13rem'}}/> */}
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  logoLinkMobile = logoLinkMobile || defaultLogoLinkMobile;

  links = links || defaultLinks;

  return (
    <>
      <Header className={className || "header-light"} style={{padding:'15px'}}>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {logoLink}
          {links}
        </DesktopNavLinks>
      </Header>
      <Divider/>
    </>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
