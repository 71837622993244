import React, { Component } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
    Link,
  } from "react-router-dom";
import Otp from '../../../common/modals/confirm_user_mobile_component'
class NotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: '',
      timeout: false,
      seconds: process.env.REACT_APP_TIMEOUT,
    }
    this.timer = 0;
    this.interval_start = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  updateMobile = (e) => {
    this.setState({
        mobile: e.target.value
    })
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let secondstime = this.state.seconds - 1000;
    ////console.log(secondstime);
   
    
    // Check if we're at zero.
    if (secondstime == 0) { 
      clearInterval(this.timer);
      // alert("Session Timeout");
      this.setState({
        timeout: true,
      });
    } else {
       this.setState({
      seconds: secondstime,
    });
    }
  }
  render() {
    clearInterval();
    this.startTimer();

    if (window.innerWidth < 481) {
      return (
        <>
        {/* <Bg/> */}
        <div >
        <div className="drawerWeb" style={{ height: 'auto', padding:'10px'}}>
          {/* <Toolbar/> */}
          <Link to={{pathname:`/products`}}>
            <button className="backMobile" >Back</button>
          </Link>
        </div>
          <div className="content" style={{padding:'20px', margin:'auto', width:'95%'}}>
            <div className="payment" style={{textAlign:'center'}}>
              <h2 className="text" style={{fontSize:'18px'}}>Give us a way to notify you</h2>
              <p className="text" style={{fontSize:'12px', fontWeight:'500'}}>A Whatsapp message will be sent to you once your food is available for collection.</p>
              <span>
                  <TextField id="outlined-basic" label="+65 -" variant="outlined" className="number" size="small" style={{width: '100%', fontSize:'12px' }} onChange={this.updateMobile} value={this.state.mobile} helperText="Tap on box to enter mobile"/>
              </span>
              <span className="button4">
                  <Otp mobile={this.state.mobile} />
              </span>
            </div>
          </div>
          </div>
          {/* {this.state.timeout == true ? <TimeoutAlert/> : <></>} */}
        </>
      
      );
    } else {
      return (
        <>
        {/* <Bg/> */}
        <div style={{margin:'20px'}}>
        <div className="drawerWeb" style={{ height: 'auto', padding:'30px'}}>
          {/* <Toolbar/> */}
          <Link to={{pathname:`/products`}}>
            <button className="backWeb" >Back</button>
          </Link>
        </div>
          <div className="content" style={{padding:'10%', paddingBottom:'5%', paddingTop:'5%', margin:'auto', width:'60%'}}>
            <div className="payment" style={{textAlign:'center'}}>
              <h2 className="text" style={{fontSize:'22px'}}>Give us a way to notify you</h2>
              <p className="text" style={{fontSize:'20px', fontWeight:'500'}}>A Whatsapp message will be sent to you once your food is available for collection.</p>
              <span>
                  <TextField id="outlined-basic" label="+65 -" variant="outlined" className="number" style={{width: '80%' }} onChange={this.updateMobile} value={this.state.mobile} helperText="Tap on box to enter mobile"/>
              </span>
              <span className="button4">
                  <Otp mobile={this.state.mobile} />
              </span>
            </div>
          </div>
          </div>
          {/* {this.state.timeout == true ? <TimeoutAlert/> : <></>} */}
        </>
      
      );
    }
  }
}
 
export default NotificationPage;