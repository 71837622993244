import React, { Component } from "react";
import { FaBars } from 'react-icons/fa';
import Button from '@mui/material/Button';
import sghawkerscentre from '../../../assets/web/sghawkerslogo.png'
import canberrahawker from '../../../assets/web/hawkermainpage.png'
import mobilephone from '../../../assets/web/mobilephonebanner.png'
import Slideshow from '../components/slideshow_component'
import Box from '../components/box_component'
import "./../../../styles/arrows.css";
import dishes from '../../../assets/web/dishes.gif'
import screens from '../../../assets/web/screens.gif'
import hawker from '../../../assets/web/hawkergif.gif'
import foods from '../../../assets/web/foods.png'

import { Link } from 'react-router-dom'
import { Divider } from "@mui/material";

export default class landing_layout extends Component{  
  // redirectAppDownload() {
  //   if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
  //     window.location.href = 'http://play.google.com/store/apps/details?id=com.truecaller&hl=en';
  //   }
  //   if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
  //     window.location.href = 'http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8';
  //   }
  // }
  render(){
    clearInterval();
    const screenHeight = window.innerHeight;

    if (window.innerWidth < 481) {
      return (
        <div style={{ position: 'relative', width: '100%'  , color:'#222344'}}>
          
          <div style={{ textAlign: 'center' }}>
          <Slideshow/>
          
          </div>
          <div style={{ display: 'inline-block', position: 'relative', margin:'10px 0'}}>
          <img src={dishes} alt="About" style={{ width: '100%' }} />

            <div style={{ textAlign: 'center', width:'70%',position:'absolute', top:0, left:'10px'}}>
              
              <h4>Welcome to SG Hawker Centres</h4>
              <p>Celebrating the flavours and traditions inscribing Hawker Culture in Singapore onto the UNESCO representative list of the intangible cultural heritage of humanity.</p>
              <div style={{ width: 'inherit', margin: 'auto' }}>
              <Link to={'/about'}>
                <Button variant="contained" color="success" sx={{ width: 150 }} style={{ backgroundColor: "#5BBA44", borderRadius: '20px', fontSize: '12px', height: 'inherit  ', marginTop: '10px' , color:'#222344', fontWeight:'bold'}} >ABOUT</Button>
              </Link>
              </div>
              
            </div>
          </div>
          <div style={{ display:'inline-block', width:'100%', position:'relative', margin:'10px 0'}}>
          
            <img src={screens} alt="About" style={{ width: '100%' }} />
            <div style={{ textAlign:'center', position:'absolute',width:'65%',top:0,right:'10px'}}>
              <h4>Discover what's on offer by Hawkers and Heartland Merchants</h4>
              <p>Order in a Click!</p>
              <div style={{ width: 'inherit', margin: 'auto' }}>
                <Link to={'/locations'}>
                <Button variant="contained" color="success" sx={{ width: 150 }} style={{ backgroundColor: "#5BBA44", borderRadius: '20px', fontSize: '12px', height: 'inherit  ', marginTop: '10px' , color:'#222344', fontWeight:'bold'}} >ORDER NOW</Button>
                </Link>
             
              </div>
              
            </div>
          </div>
        
          </div>
      )
    } else {
      return (
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ textAlign: 'center', position: 'relative' }}>
            <Slideshow/>
          </div>
          <Box componentText={<>         
            <div style={{ textAlign: 'left', width: '100%', height: `${screenHeight - 500}`, position:'relative' }}>

            <img src={dishes} alt="About" style={{ width: '100%' }} />

            <div style={{position:'absolute', top:`${screenHeight - 600}px`, height: `${screenHeight - 500}`, left:'30px',margin:0}}>
              <h1>Welcome to SG Hawker Centres</h1>
              <p style={{ width: '50%' }}>Celebrating the flavours and traditions inscribing Hawker Culture in Singapore onto the UNESCO representative list of the intangible cultural heritage of humanity.</p>
              <Link to={'/about'}>
                <Button variant="contained" color="success" sx={{ width: 200 }} style={{ backgroundColor: "#5BBA44", borderRadius: '20px', fontSize: '16px', height: 'inherit  ', marginTop: '10px', color:'#222344', fontWeight:'bold'}} >ABOUT</Button>
              </Link>  
            </div>
              
            </div>
          </>
            }/>
           
          <Box componentText={
            <div style={{ textAlign: 'right', width: '100%', height: `${screenHeight - 500}`, position:'relative' }}>
              <img src={screens} alt="About" style={{ width: '100%' }} />
              <div style={{ position: 'absolute', top: `${screenHeight - 600}px`, height: `${screenHeight - 500}`, right: '30px', margin: 0 }}>
                <h1>Discover what's on offer by Hawkers and Heartland Merchants</h1>
                <p>Order in a Click!</p>
                <div style={{ width: 'inherit', margin: 'auto' }}>
                  <Link to={'/locations'}>
                  <Button variant="contained" color="success" sx={{ width: 200 }} style={{ backgroundColor: "#5BBA44", borderRadius: '20px', fontSize: '16px', height: 'inherit  ', marginTop: '10px', color:'#222344', fontWeight:'bold' }} >ORDER NOW</Button>
                  </Link>
                  
                </div>
              </div>
              
            </div>}/>
          </div>
      )
    }
  }
}