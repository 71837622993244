import React, { createContext, useState } from "react";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';

const navigationRoutes = [
  {
    title: "Introduction",
    path: "/documentation/introduction",
    icon: HomeOutlinedIcon,
    current: true,
  },
  {
    title: "Order Fulfillment & Notice",
    path: "/documentation/order/update-order-status",
    icon: MarkChatUnreadOutlinedIcon,
    subNav: [
      {
        title: "Update Order Status",
        path: "/documentation/order/update-order-status",
      },
      {
        title: "Get Consumer Orders",
        path: "/documentation/order/get-consumer-orders",
      },
      {
        title: "Get Orders by ID",
        path: "/documentation/order/get-orders-by-id",
      },
    ]
  },
  {
    title: "Food Nutrition",
    path: "/documentation/nutrition/get-top-5-healthy",
    icon: RestaurantMenuOutlinedIcon,
    subNav: [
      {
        title: "Get Top Healthy",
        path: "/documentation/nutrition/get-top-5-healthy",
      },
      {
        title: "Get Top Unhealthy",
        path: "/documentation/nutrition/get-top-5-unhealthy",
      },
      {
        title: "Get by Line Graphs",
        path: "/documentation/nutrition/get-by-line-graphs",
      },
      {
        title: "Get by Pie Chart",
        path: "/documentation/nutrition/get-by-pie-chart",
      },
      {
        title: "Get Calorie Transactions",
        path: "/documentation/nutrition/get-calories-transactions",
      }
    ]
  },
  {
    title: "Back to Landing",
    path: "/",
    icon: HomeOutlinedIcon,
  },
];
console.log(navigationRoutes);

export const NavigationRoutesContext = createContext();
export const CurrentNavigationRouteContext = createContext();

export const NavigationContextProvider = (props) => {
  const [currentRoute, setCurrentRoute] = useState(navigationRoutes[0].title);
  const contextValue = { currentRoute, setCurrentRoute };
  return (
    <NavigationRoutesContext.Provider value={{ navigationRoutes }}>
      <CurrentNavigationRouteContext.Provider value={contextValue}>
        {props.children}
      </CurrentNavigationRouteContext.Provider>
    </NavigationRoutesContext.Provider>
  );
};