import { Box, Drawer, List, Stack, Toolbar } from "@mui/material";
import React, { useState, useContext } from "react";
import LayoutConfig from "../styles/layoutConfig";
import SidebarItem from "./sidebarItem";
import {
  CurrentNavigationRouteContext,
  NavigationRoutesContext,
} from "./contexts/navigationContext.jsx";
import logo from "../../../assets/web/sghawkerslogo.png"
import { Outlet } from "react-router-dom";
import { Link } from 'react-router-dom'

import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';

const drawerWidth = 350;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const SidebarMobile = () => {
  // const [showSidebar, setShowSidebar] = useState(false);
  const { navigationRoutes } = useContext(NavigationRoutesContext);
  const { currentRoute, setCurrentRoute } = useContext(
    CurrentNavigationRouteContext
  );
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openCollapse, setOpenCollapse] = React.useState(null);

  const handleClickCollapse = (index) => {
    setOpenCollapse(openCollapse === index ? null : index);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AppBar position="fixed" style={{width: `${window.innerWidth}px`, left:0, backgroundColor:LayoutConfig.sidebar.bg}} open={open}>
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" color="black">
            SG Hawker Centres - Open API Documentation
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader style={{backgroundColor:LayoutConfig.sidebar.bg}}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navigationRoutes.map((text, index) => (
            // <ListItem key={index} disablePadding  component={Link}
            //   to={text.path} style={{ color: 'black' }} onClick={handleDrawerClose}>
            <>
               {!text.subNav ? <ListItemButton key={index} disablePadding  component={Link}
              to={text.path} style={{ color: 'black' }} onClick={handleDrawerClose}>
                <ListItemIcon>
                <text.icon/>
                </ListItemIcon>
                <ListItemText primary={text.title} />
              </ListItemButton> : <><ListItemButton key={index} onClick={() => handleClickCollapse(index)}>
                <ListItemIcon>
                    <text.icon />
                  </ListItemIcon>
                <ListItemText primary={text.title} />
                {openCollapse === index ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openCollapse === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {text.subNav.map((item, index) => (
                    <ListItemButton key={index} sx={{ pl: 4 }} component={Link}
                    to={item.path} onClick={handleDrawerClose} >
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  ))}
                </List>
                </Collapse>
              </>}
            </>
             
            // </ListItem>
          ))}
        </List>
       
      </Drawer>
      <Main open={open} style={{margin:'0',paddingTop:'50px', width:`${window.innerWidth}px`}}>
        {/* <DrawerHeader /> */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 2,
            width: `100%`,
            minHeight: "100vh",
            backgroundColor: '#fffff',
            display: 'flex'
          }}
        >
          {/* <Toolbar /> */}
          <Outlet />
        </Box>
      </Main>
      {/* <Drawer
      variant="permanent"
      sx={{
        width: LayoutConfig.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: LayoutConfig.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: LayoutConfig.sidebar.bg,
          color: LayoutConfig.sidebar.color
        }
      }}
    >
      <List disablePadding>
        <Toolbar sx={{ marginBottom: "8px" }}>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="center"
          >
            <Box component="img" src={logo} alt="food canopy logo" sx={{ height: "63px", width: "100%", marginTop: "15px" }} />
          </Stack>
        </Toolbar>
        {navigationRoutes.map((item) => (
          <SidebarItem
            key={item.title}
            item={item}
            callback={(title) => {
              setCurrentRoute(title);
            }}
            selectedTitle={currentRoute}
          />
        ))}
      </List>
    </Drawer> */}
    </>
   
  );
    
};

export default SidebarMobile;
