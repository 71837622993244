import React, { Component } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import sghawkerscentre from '../../../assets/web/sghawkerslogo.png'

import hc1 from '../../../assets/web/hc1.png'
import hc2 from '../../../assets/web/hc2.png'
import hc3 from '../../../assets/web/hc3.png'
import hc4 from '../../../assets/web/hc4.png'
import hc5 from '../../../assets/web/hc5.png'
import { Link } from "react-router-dom";

import commonConfigs from "../../../config";
import axios from "axios";

const getOutletsUrl = commonConfigs.apiUrls.GetOutlets(':org_id',10);

export default class landing_hawker_layout extends Component{  
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      hawkerslist: [],
      hawkerslistdisplay: [],
    }
  }
  
  onChangeSearch(e) {
    console.log(e.target.value)
    var results = this.state.hawkerslist.filter(menu =>
      menu.location_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({
      inputValue: e.target.value,
      hawkerslistdisplay:results
    })
  }
  async getHawkers() {
    //get list of outlets
    await axios
      .get(getOutletsUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        var response = res.data.result;
        localStorage.clear();
        this.setState({
          hawkerslist: response.data,
          hawkerslistdisplay: response.data
        })
      })
      .catch((err) => {
        console.log("Error Message: ", err);
      });
  }
  setSessions(orgid, projectname, loccode, hawkername) {
    console.log(orgid, projectname, loccode,hawkername);
    localStorage.setItem('projectname', projectname)
    localStorage.setItem('orgid', orgid)
    localStorage.setItem('loccode', loccode)
    localStorage.setItem('hawkername', hawkername)
    if(projectname === process.env.REACT_APP_PROJECT_NAME && projectname) {
      // sghc - internal
      localStorage.setItem('platformSyscode',  parseInt(process.env.REACT_APP_WEBSITE_PLATFORM_SYSCODE));
    } else {
      // fc - external
      localStorage.setItem('platformSyscode', parseInt(process.env.REACT_APP_ECOMMERCE_WEBSITE_PLATFORM_SYSCODE));
    }
  }
  componentDidMount(){
    this.getHawkers();
  }

  render() {
    if (window.innerWidth < 481) { 
      return (
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ textAlign: 'center', margin:'30px 0 30px 0'}}>
          <Link to={'/'}>
              <img src={sghawkerscentre} alt="SG Hawker Logo" style={{width:'60%'}}/>
            </Link>        </div>
          <div style={{ margin: '20px' }}>
            <input type="text" value={this.state.inputValue} onChange={(e)=>this.onChangeSearch(e)} placeholder="Search" style={{ width: '100%', borderRadius: 8, border: 0, background: '#f7f7f7', padding: 10, outline:'none' }} />
          </div>
          <h2 style={{textAlign:'center'}}>Select Locations</h2>
          <ul style={{display:'flex', flexWrap:'wrap', padding:0, margin:'auto',width:'100%'}}>
          {
              this.state.hawkerslistdisplay.map((hawker, index) => (
                <Link to={`/options/${hawker.location_name}`} style={{ width: '50%', padding:'10px'}}>
                <li key={index} style={{ marginBottom: '20px', listStyle: 'none'}} onClick={() => this.setSessions(hawker.org_id, hawker.project_name, hawker.location_code, hawker.location_name)}>
                  <Card sx={{ maxWidth: 450 }} style={{height:'220px', borderRadius: '10px' }}>
                    <CardMedia
                        component="img"
                        height="150"
                        image={hawker.location_img_url}
                        alt="burger"
                    />
                    <CardContent style={{textAlign:'center'}}>
                      <div>
                          <Typography variant="h5" component="span" style={{color:'#6D7278',fontWeight:400, fontSize:'16px'}}>
                          {hawker.location_name}
                        </Typography>
                      </div>
                    </CardContent>
                    </Card>
                </li>
              </Link>  
            ))
            }
          </ul>
        </div>
        )
    }else{
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div style={{ textAlign: 'center', margin:'30px 0 30px 0'}}>
        <Link to={'/'}>
            <img src={sghawkerscentre} alt="SG Hawker Logo" style={{width:'40%'}}/>
          </Link>        </div>
        <div style={{ margin: '0 200px 0 200px' }}>
          <input type="text" value={this.state.inputValue} onChange={(e)=>this.onChangeSearch(e)} placeholder="Search" style={{ width: '100%', borderRadius: 8, border: 0, background: '#f7f7f7', padding: 10, outline:'none' }} />
        </div>
        <h1 style={{textAlign:'center'}}>Select Locations</h1>
        <ul style={{ display: 'flex', flexWrap: 'wrap', justifyContent:'center',padding:'20px', margin:'auto',width:'100%'}}>
        {
            this.state.hawkerslistdisplay.map((hawker, index) => (
            <Link to={`/options/${hawker.location_name}`} style={{width:'30%', padding:'20px'}} >
              <li key={index} style={{marginBottom:'20px',listStyle:'none', width:'100%'}} onClick={()=> this.setSessions(hawker.org_id, hawker.project_name, hawker.location_code, hawker.location_name)}>
                <Card sx={{ maxWidth: 450 }} style={{height:'320px', borderRadius: '10px', margin:'auto'}}>
                  <CardMedia
                      component="img"
                      height="250"
                      image={hawker.location_img_url}
                      alt="burger"
                  />
                  <CardContent style={{display: "inline-flex"}}>
                    <div>
                        <Typography variant="h5" component="span" style={{color:'#6D7278',fontWeight:400}}>
                        {hawker.location_name}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </li>
            </Link>
          ))
          }
        </ul>
      </div>
      )
    }
  }
}