import React, { Component } from "react";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link } from "react-router-dom";

export default class ync_layout extends Component{  
  constructor(props) {
    super(props);
    this.state = {
      display: "English",
      chineseStyle: {
        backgroundColor: 'white',
        borderBottom: 0,
        color: 'black',
        borderRadius:0
      },
      englishStyle: {
        backgroundColor: 'white',
        borderBottom: '1px black solid',
        color: 'black',
        borderRadius:0
      }
    }
  }

  languageChange(lang) {
    var styleOn = {
      backgroundColor: 'white',
      borderBottom: '1px black solid',
      color: 'black',
      borderRadius:0
    }
    var styleOff = {
      backgroundColor: 'white',
      borderBottom: 0,
      color: 'black',
      borderRadius:0
    }
    
    if (lang == "English") {
    
      this.setState({
        display: lang,
        englishStyle: styleOn,
        chineseStyle: styleOff
      });
    } else {
      this.setState({
        display: lang,
        englishStyle: styleOff,
        chineseStyle: styleOn
      });
    }
    
  }

  render(){
    return (
      <div style={{ position: 'relative', width: '100%', padding: '70px 30px', textAlign:'center' }}>
         <Link to={{pathname:`/`}} style={{position: 'absolute', top: "10px", left:'5%'}}>
            <button className="backWeb" style={{fontSize: '12px', height:'inherit', width:'auto'}} >Back</button>
          </Link>
        <h1 style={{ textAlign: 'center' }}>Youth &amp; Community</h1>
        <div>
          <button className="backWeb" onClick={() => this.languageChange("English")} style={this.state.englishStyle}>English</button>
          <button className="backWeb" onClick={() => this.languageChange("Chinese")} style={this.state.chineseStyle}>中文</button>
        </div>
       
        {this.state.display == "English" ? <>
        <br />  <br />
        {/* <p>{this.state.policy}</p> */}
        <h3>Digital Hawker Centre Launchpads for Youth &amp; Community</h3>
        <p>SG Hawker Centres was built from scratch by a team of 16 youths from Republic Polytechnic
        (RP), Singapore Polytechnic (SP), Ngee Ann Polytechnic (NP), Singapore Institute of
        Management (SIM), Singapore Institute of Technology (SiT), and the Institute of Technical
        Education (ITE).</p>
        <br />
        <p>When the youth team first embarked on this trailblazing work in a Hawker Centre
        environment to develop digital solutions integrating real-time orders from multiple
        platforms like self-ordering kiosks, mobile apps, and point-of-sales systems, it synergised
        lessons outside of the classroom allowing the youth team to experience first-hand that
        &#39;tech&#39; is not all about &#39;tech&#39;. Digital savvy features which come across as second nature to
        digital natives like the youth themselves may not be applicable to the stallholder&#39;s rush hour
        operations. The youth team had to walk the ground and gather continuous feedback to
        adapt their solutions to fit different stallholders&#39; business operations, manage various
        stallholders&#39; expectations, and offer customisation to cater for different languages. When
        faced with technical issues that required to be resolved before deadlines, soft skills like
        teamwork, troubleshooting calmly with laser sharp focus on one solution at a time brought
        realism into the learning process. Variations in design, implementation challenges, and the
        agility to pivot workarounds made work cut out for the youth team a constant adrenaline
        rush.</p>
        <br />
        <p>To ensure that the youth team had a fail-safe environment, digital solutions developed were
        rigorously tested in a staging software development lab in Republic Polytechnic before the
        solutions could be rolled out into a &#39;live&#39; environment like Bukit Canberra Hawker Centre.
        The team had the opportunity to work with frontier industries like Samsung and NETS who
        provided software development tools and technical expertise for the self-ordering kiosks
        and ePayment gateways respectively; CodeForeword who provided mentorship and staging
        software environments; and BevEat who provided system integration environments for
        mobile, web, backend, and ePayments fintech software development.</p>
        <br />
        <p>To enable more youth to benefit from this experience, SG Hawker Centres will double up as
        a &#39;live&#39; digital launchpad for Youth &amp; Community to connect their digital solutions or digital
        campaigns to heartland merchants. With digital projects curated to focus on heartland
        communities in various Hawker Centre locations, these launchpads will become melting
        pots for Hawkers, Market stallholders, Youth &amp; Community to work together. Bonds
        fostered during project execution will go a long way in ensuring that Singapore&#39;s Hawker
        Culture remains relevant to the next generation. Ongoing projects curated to date include:</p>
        <br />
        <p>1. Voice Ordering and ePayments enabling &#39;Every Hawker Centre, An Inclusive Hawker
          Centre for Visually Impaired &amp; Persons with Disabilities&#39;.</p>
        <p>2. Hyperledger Blockchain Meal Donations &amp; Claims Ecosystem Ensuring Transparency
Between Public-Private-Community Partnerships.</p>
        <br />
        <p>Prospective interns or collaborating Institutes of Higher Learning (IHL), Schools, Youth &amp;
Community organisations may contact us at community@beveat.com.</p>
      
        </> : <>
        <br />  <br />
        {/* <p>{this.state.policy}</p> */}
        <h3>数字小贩中心青年与社区启动站</h3>
        <p>SG Hawker Centres是由来自共和理工学院 (RP)、新加坡理工学院 (SP)、义安理工学
院 (NP)、新加坡管理学院 (SIM)、新加坡理工学院 (SiT) 和工艺教育学院(ITE) 。</p>
        <br />
        <p>年轻团队首次踏上这项开创性的工作时,他们在小贩中心的环境中开展了数字解决方
案的开发工作,整合了来自多个平台的实时订单,如自助点餐机、移动应用程序和销
售点系统,这使他们在课堂之外获得了协同的经验,让年轻团队亲身体验到&#39;科技&#39;并非
仅限于&#39;科技&#39;。对于数字原住民(如年轻人自己) 来说,看似轻而易举的数字精通功能
可能并不适用于摊主的高峰运营时段。年轻团队不得不深入实地,不断收集反馈,调
整他们的解决方案以适应不同摊主的业务运营,管理各种摊主的期望,并提供定制以
满足不同语言的需求。面对需要在截止日期之前解决的技术问题时,团队合作、冷静
解决问题,并专注于一次解决方案的锐利焦点,使现实感融入学习过程。在设计上的
差异、实施挑战以及灵活应对的敏捷性使年轻团队的工作成为持续的肾上腺素飙升。</p>
        <br />
        <p>为了确保年轻团队有一个安全可靠的环境,他们开发的数字解决方案在共和理工学院
的一个分阶段软件开发实验室中经过了严格的测试,然后才能在像武吉堪巴拉小贩中
心这样的&#39;实际&#39;环境中推出。团队有机会与三星和NETS等领先行业合作,他们分别提
供了自助点餐机和电子支付网关的软件开发工具和技术专业知识;CodeForeword提
供了指导和分阶段软件环境;BevEat提供了移动端、网站、后端和电子支付金融科技
软件开发的系统集成环境。</p>
        <br />
      
        <p>为了让更多年轻人从这一经验中受益,SG Hawker Centres将充当&#39;实际&#39;数字启动站,
供年轻人和社区将他们的数字解决方案或数字活动连接到市区商家。通过专注于各小
贩中心位置的市区社区的数字项目,这些启动站将成为小贩、市场摊主、年轻人和社
区共同合作的熔炉。在项目执行过程中培养的联系将在很大程度上确保新加坡的小贩
文化对下一代仍然具有相关性。到目前为止,策划的正在进行的项目包括：</p>
        <br />
        <p>1. 语音订购和电子支付,实现&#39;每个小贩中心,一个面向视障人士和残障人士的包
容性小贩中心&#39;。</p>
        <p>2. 账本区块链膳食捐赠与索赔生态系统,确保公私社区合作之间的透明度。</p>
        <br />
        <p>有意向的实习生或合作的高等教育机构(IHL) 、学校、青年和社区组织可通过
community@beveat.com与我们联系。</p>
        <br />
        </>}
        
      
      </div>
    )
  }
}