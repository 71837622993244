import React from "react";

import hc1 from '../../../assets/web/hc1.png'
import hc3 from '../../../assets/web/hc3.png'
import hc4 from '../../../assets/web/hc4.png'
import hc5 from '../../../assets/web/hc5.png'

import sghawkerscentre from '../../../assets/web/sghawkerslogo.png'
import { Link } from 'react-router-dom'

const colors = [hc1, hc3, hc4, hc5];
const delay = 2500;

function Slideshow() {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  let logo;

  if (window.innerWidth < 481) {
    logo = <Link to={'/'}>
      {/* <img src={sghawkerscentre} alt="SG Hawker Logo" style={{width:'90%'}}/> */}
      <div style={{ alignItems:'center', height:'200px', position:'relative'}}>
        <img src={sghawkerscentre} alt="SG Hawker Logo" style={{ width: '90%', transform:'translate(0, 50%)' }} />
      </div>
    </Link>
  } else {
    logo = <Link to={'/'} style={{ textDecoration: 'none', cursor: 'default', color: 'black' }}>
      <div style={{ alignItems:'center', height:'790px'}}>
        <img src={sghawkerscentre} alt="SG Hawker Logo" style={{ width: '60%', transform:'translate(0, 100%)' }} />
        
        <div class="arrow-container">
          <div class="arrow"></div>
          <div class="arrow"></div>
          <div class="arrow"></div>
        </div>
      </div>
      
    </Link>
  }

  
  return (
    <div>
      {logo}
      <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {colors.map((image, index) => (
          <>
            {window.innerWidth < 481 ? <img className="slide" key={index} src={image} alt=""/> : <img className="slideWeb" key={index} src={image} alt="" />}
            {/* <img className="slideWeb" key={index} src={image} alt="" /> */}
          </>
          
          
        ))}
      </div>

      <div className="slideshowDots">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
    </div>
    
  );
}

export default Slideshow;