import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
    Link,
  } from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ErrorAlert(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  if (window.innerWidth < 481) {
    return (
      <div style={{display: 'inline-block'}}> 
        {/* <Button variant="contained" sx={{ width: 250 }} style={props.buttonStyle} onClick={handleOpen}>{ props.buttonTrigger }</Button> */}
        {/* <button className="backWeb" style={{width:'70px', height:'25px', fontSize:'10px'}} onClick={handleOpen} >{ props.buttonTrigger }</button> */}
        <Modal
          open={open}
          backdrop="static"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ textAlign: "center", borderRadius: '20px', width: '95%', border: 0 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          { props.errorMsgDisplay.title }
          </Typography>
            <div id="modal-modal-description" sx={{ mt: 2 }} >
             <p style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '18px',fontWeight: 'bold',letterSpacing: 0.16,marginTop: '30px'
              }}>{ props.errorMsgDisplay.body }</p>
            </div>
            <div className="button" style={{display: "inline-flex"}}>
            {/* <Link to='/stalls'> */}
              {/* <button className="button5" style={{ marginBottom: "10px", width: "100px", height: '30px', color: '#5BBA44', background: 'white', fontSize:'12px'}} onClick={() => handleClose()}>{ props.buttonLeft }</button> */}
            {/* </Link> */}
              <Link to={`/options/${localStorage.getItem('hawkername')}`}>
                <button className="button5" style={{ marginBottom: "10px", width: "100px", height: '30px', marginLeft: "20px",fontSize:'12px' }}>Back to Home</button>
            </Link>
            </div>
          </Box>
        </Modal>
      </div>
    );
  } else {
    return (
      <div style={{display: 'inline-block', margin:'20px', marginTop:'20px'}}> 
        {/* <Button variant="contained" sx={{ width: 250 }} style={props.buttonStyle} onClick={handleOpen}>{ props.buttonTrigger }</Button> */}
        <button className="backWeb" onClick={handleOpen} >{ props.buttonTrigger }</button>
        <Modal
          open={open}
          backdrop="static"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ textAlign: "center", borderRadius: '20px', width: '60%', border: 0 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          { props.errorMsgDisplay.title }
          </Typography>
            <div id="modal-modal-description" sx={{ mt: 2 }} >
             <p style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '20px',fontWeight: 'bold',letterSpacing: 0.16,marginTop: '30px'
              }}>{ props.errorMsgDisplay.body }</p>
            </div>
            <div className="button" style={{display: "inline-flex"}}>
              <Link to={`/`}>
                <button className="button5" style={{ marginBottom: "10px", width: "150px", height: '30px', marginLeft: "20px",fontSize:'18px' }}>Back to Home</button>
            </Link>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}