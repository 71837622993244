import React, { Component } from "react";
import {
  Route,
  Routes,
  NavLink,
  HashRouter,
  BrowserRouter
} from "react-router-dom";
import "tw-elements";
////////////////////////////////////////////////WEB VERSION//////////////////////////////////////////////
import Navbar from "./web/common/components/navbar_component"
import NavbarWrapper from "./web/open_api_module/components/navbarWrapper";

import CheckoutWeb from "./web/checkout_module/views/checkout_layout"
import NotificationPageWeb from "./web/checkout_module/views/notification_layout";
import PaymentWeb from "./web/payments_module/views/payment_layout";
import SuccessfulPaymentWeb from "./web/payments_module/views/successful_payment_layout"

import StallsWeb from "./web/merchant_module/views/merchant_layout"
import LandingWeb from "./web/home_module/views/home_page_platform_layout"
import LandingHawkerWeb from "./web/home_module/views/locations_layout"
import PrivacyPolicyWeb from "./web/home_module/views/privacy_policy_layout"
import AboutWeb from "./web/home_module/views/about_layout"
import YNCWeb from "./web/home_module/views/youth_community_layout"
import DiningModeWeb from "./web/home_module/views/home_page_user_layout"
import PastOrdersWeb from "./web/home_module/views/past_orders_layout"
import OrderWeb from "./web/product_module/views/product_layout"
import EditOrderWeb from "./web/order_module/views/order_options_layout"

// API Documentation
import OpenApiWeb from "./web/open_api_module/pages/mainLayout"
// import Documentation from "./web/open_api_module/pages/documentLayout"
import Intro from "./web/open_api_module/components/views/introductionApi";
import UpdateOrderStatus from "./web/open_api_module/components/views/order_fulfillment_pages/updateOrderStatus";
import GetConsumerOrders from "./web/open_api_module/components/views/order_fulfillment_pages/getConsumerOrders";
import GetOrderById from "./web/open_api_module/components/views/order_fulfillment_pages/getOrderbyId";
import GetTopFiveHealthy from "./web/open_api_module/components/views/food_nutrition_pages/getTopFiveHealthy";
import GetTopFiveUnhealthy from "./web/open_api_module/components/views/food_nutrition_pages/getTopUnhealthy";
import GetLineGraphs from "./web/open_api_module/components/views/food_nutrition_pages/getLineGraphs";
import GetPieChart from "./web/open_api_module/components/views/food_nutrition_pages/getPieChart";
import GetCaloriesTransactions from "./web/open_api_module/components/views/food_nutrition_pages/getCaloriesTransaction";

import history from './history';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
class Main extends Component {
  
  render() {
    document.title = "SG Hawker Centres";
      return (
        <BrowserRouter>
          <div>
          <Navbar />
            <Routes history={history}>
              {/* Open API routing */} 
              <Route
            path="/documentation"
            element={
                <NavbarWrapper />
            }
              >
              <Route path="/documentation/introduction" element={<Intro/>}/>
              <Route path="/documentation/order/update-order-status" element={<UpdateOrderStatus/>}/>
              <Route path="/documentation/order/get-consumer-orders" element={<GetConsumerOrders/>}/>
              <Route path="/documentation/order/get-orders-by-id" element={<GetOrderById/>}/>

              <Route path="/documentation/nutrition/get-top-5-healthy" element={<GetTopFiveHealthy/>}/>
              <Route path="/documentation/nutrition/get-top-5-unhealthy" element={<GetTopFiveUnhealthy/>}/>
              <Route path="/documentation/nutrition/get-by-line-graphs" element={<GetLineGraphs/>}/>
              <Route path="/documentation/nutrition/get-by-pie-chart" element={<GetPieChart/>}/>
              <Route path="/documentation/nutrition/get-calories-transactions" element={<GetCaloriesTransactions/>}/>

          </Route>
              {/* <Route restricted={false} path="/documentation" element={<OpenApiWeb/>}> */}
                {/* <Route index element={<Documentation />} /> */}
             
          {/* </Route> */}
               {/* <Route restricted={false} path="/documentation"element={<NavbarWrapper /> }>
                <Route index element={<Documentation />} />
                <Route path="/documentation/introduction" element={<Intro/>}/>
                
                <Route path="/documentation/order/update-order-status" element={<UpdateOrderStatus/>}/>
                <Route path="/documentation/order/get-consumer-orders" element={<GetConsumerOrders/>}/>
                <Route path="/documentation/order/get-orders-by-id" element={<GetOrderById/>}/>

                <Route path="/documentation/nutrition/get-top-5-healthy" element={<GetTopFiveHealthy/>}/>
                <Route path="/documentation/nutrition/get-top-5-unhealthy" element={<GetTopFiveUnhealthy/>}/>
                <Route path="/documentation/nutrition/get-by-line-graphs" element={<GetLineGraphs/>}/>
                <Route path="/documentation/nutrition/get-by-pie-chart" element={<GetPieChart/>}/>
                <Route path="/documentation/nutrition/get-calories-transactions" element={<GetCaloriesTransactions/>}/>

                <Route path="/documentation/cleaning/request-dishware" element={<RequestDishware/>}/>
                <Route path="/documentation/cleaning/get-dishware-requests" element={<GetDishwareRequest/>}/>
                <Route path="/documentation/cleaning/update-dishware-status" element={<UpdateDishwareStatus/>}/>
              </Route> */}
            <Route exact path="/options/:hawkername" element={<DiningModeWeb authed={true} />}></Route>
            <Route exact path="/" element={<LandingWeb authed={true} />}></Route>
            <Route exact path="/locations" element={<LandingHawkerWeb authed={true} />}></Route>            
            <Route exact path="/policy/privacy" element={<PrivacyPolicyWeb authed={true} />}></Route>
            <Route exact path="/about" element={<AboutWeb authed={true} />}></Route>
            <Route exact path="/community/youth" element={<YNCWeb authed={true}/>}></Route>
            <Route exact path="/orders/history" element={<PastOrdersWeb authed={true}/>}></Route>
            <Route exact path="/merchants" element={<StallsWeb authed={true} />}></Route>
            <Route exact path="/products" element={<OrderWeb authed={true}/>}></Route>
            <Route exact path="/orders" element={<EditOrderWeb authed={true}/>}></Route>
            <Route exact path="/checkout" element={<CheckoutWeb authed={true}/>}></Route>
            <Route exact path="/checkout/contact" element={<NotificationPageWeb authed={true}/>}></Route>
            <Route exact path="/payments" element={<PaymentWeb authed={true}/>}></Route>
            <Route exact path="/success" element={<SuccessfulPaymentWeb authed={true} />}></Route>
              
          </Routes>
        </div>
      </BrowserRouter>
      );
    
  }
}
 
export default Main;