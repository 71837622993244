import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import dish from '../../../assets/dish.jpeg'
import Card from '@mui/material/Card';
import Badge from '@mui/material/Badge';
import cart from '../../../assets/web/cart.png'
import {
    Link,
} from "react-router-dom";
  
const badgeStyleLow = {
  "& .MuiBadge-badge": {
    color: 'white',
    backgroundColor: '#FFBF00',
    width:'max-content',
    fontSize:'12px',
    padding:'15px',
    borderRadius:'15px'

  },
  
    
}
class checkout_details_component extends Component {
  
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //       orders:this.props.data,
    //     }
    //   }
  render() {
      let numofitems = 0;
      let price = 0;
      if(JSON.parse(localStorage.getItem("orders"))){
        // numofitems = JSON.parse(localStorage.getItem("orders")).length;
        var orders = JSON.parse(localStorage.getItem("ordersdisplay"));
        for(var i = 0;i<orders.length;i++){
          //console.log(orders[i].product_price);
          //console.log(orders[i].add_ons);
          numofitems += parseInt(orders[i].quantity)
          if (orders[i].add_ons.length > 0) {
            for (var x = 0;x<orders[i].add_ons.length;x++){
              price += parseFloat(orders[i].add_ons[x].product_price)*parseInt(orders[i].add_ons[x].quantity)*parseInt(orders[i].quantity);
            }
          }
          if (parseInt(orders[i].product_discount_percent) > 0 && parseFloat(orders[i].product_discount_value) > 0) {
            price += parseFloat(orders[i].product_price) * ((100 - parseInt(orders[i].product_discount_percent))/100)
          }else if (parseInt(orders[i].product_discount_percent) > 0 && (parseFloat(orders[i].product_discount_value) == 0 || parseFloat(orders[i].product_discount_value) == null)) {
            price += parseFloat(orders[i].product_price) * ((100 - parseInt(orders[i].product_discount_percent))/100)
          } else if (parseFloat(orders[i].product_discount_value) > 0 && (parseFloat(orders[i].product_discount_percent) == 0 || parseFloat(orders[i].product_discount_percent) == null)) {
            price += parseFloat(orders[i].product_price) - parseFloat(orders[i].product_discount_value)
          } else {
            price += parseFloat(orders[i].product_price)*parseInt(orders[i].quantity);            
          }
        }
        // price = "11";
      }else{
        numofitems = 0;
        price = "0";
      }
      let mode;
      //console.log(localStorage.getItem('dinemode'));
      if(localStorage.getItem('dinemode') == '2'){
        mode = "Take Away";
      }else if(localStorage.getItem('dinemode') == '4'){
        mode = "Dine-In";
      }else{
        mode = "PayEatFwd (" + localStorage.getItem('peftype') + ")";
      }
    // return (
    //     <div className="checkoutOrderWeb" >  
    //     <div className="c1Web" style={{margin: "10px", marginBottom:0}}>
    //         <h2 style={{marginTop: "0px", marginBottom: "0px", fontSize:'18px', color:'white'}}> Mode: {mode} </h2>
    //         <div style={{display: "inline-flex", width: "100%", marginTop: "0px", textAlign:'center'}}>
    //             <div className="price" style={{textAlign: "center"}}>
    //                 <div className="payment">
    //                     <>
    //                         <h3 style={{marginBottom: "0px", marginTop: "5px", width: "40%", display:'inline-block',float:'left', fontSize:'18px'}}>Subtotal <span style={{color:'#1F5626'}}>{numofitems}</span> item(s) : </h3>
    //                          {price !== "0" ? <h2 style={{textAlign:'end',color:'#1F5626',marginTop: "5px",marginBottom: 0, width: "40%", display:'inline-block', fontSize:'18px'}}>${price.toFixed(2)}</h2>:<><h2 style={{textAlign:'end',color:'#1F5626',marginTop: "5px",marginBottom: 0, width: "40%", display:'inline-block', fontSize:'18px'}}>${price}</h2></>}
    //                         {/* <Link to='/order' >
    //                             <button className="button2" style={{width: "20%",display:'inline-block',float:'right'}}>Edit</button>
    //                         </Link> */}
    //                     </>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //     <div style={{textAlign:'center'}}>
    //         <Link to=''>
    //         <Alert buttonStyle={buttonStyles} buttonTrigger="Cancel Order" message="Are you sure you want to cancel and start over?" linkurl="/" buttonLeft="STAY" buttonRight="YES"/>
    //         </Link>
    //       <Link to='/checkout' style={{textDecoration:'none'}}>
    //           <Button variant="contained" sx={{width: 250}} style={{backgroundColor:'white',color: "#5BBA44", borderRadius:'10px', fontSize:'16px',textDecoration:'none', height:"35px"}}>Check Out</Button>
    //         </Link>
           
    //         </div>
    //   </div>
    // );
    if (window.innerWidth < 481) {
      return (
        <div style={{ marginTop: "25px", position: 'fixed', borderRadius: '10px', margin: 'auto', bottom: '20px', right: '20px', background: '#5BBA44', height: '70px',zIndex: 1 }}>
         <Badge sx={badgeStyleLow} badgeContent={numofitems}  anchorOrigin={{  vertical: 'top', horizontal: 'right'}}>
           <Card sx={{ maxWidth: 350 }} style={{ marginTop: "25px", borderRadius: '10px', margin: 'auto', background: '#5BBA44', height: '70px' }} >
            <Link to='/checkout' style={{ textDecoration: 'none' }}>
              <img src={cart} alt="Cart" width={'70px'} height={'70px'} style={{padding:'20px'}}/>
            </Link>
          </Card>
        </Badge>
        </div>
        
      );
    } else {
      return (
        <div style={{ marginTop: "25px", position: 'fixed', borderRadius: '10px', margin: 'auto', bottom: '20px', right: '20px', background: '#5BBA44', height: '100px' }}>
         <Badge sx={badgeStyleLow} badgeContent={numofitems}  anchorOrigin={{  vertical: 'top', horizontal: 'right'}}>
           <Card sx={{ maxWidth: 350 }} style={{ marginTop: "25px", borderRadius: '10px', margin: 'auto', background: '#5BBA44', height: '100px' }} >
            <Link to='/checkout' style={{ textDecoration: 'none' }}>
              <img src={cart} alt="Cart" width={'100px'} height={'100px'} style={{padding:'20px'}}/>
            </Link>
          </Card>
        </Badge>
        </div>
        
      );
    }
  }
}
 
export default checkout_details_component;