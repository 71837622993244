export const MERCHANT_TYPE = {
  Supplier_Generic: 0,
  Supplier_Goods: 1,
  Supplier_Services: 2,
  Food_Beverage: 3,
  Retail: 4,
  Market: 5,
  Heartland: 6,
}

export const MerchantTypeString = {
  [MERCHANT_TYPE.Supplier_Generic]: "Supplier-Generic",
  [MERCHANT_TYPE.Supplier_Goods]: "Supplier-Goods",
  [MERCHANT_TYPE.Supplier_Services]: "Supplier-Services",
  [MERCHANT_TYPE.Food_Beverage]: "Food-Beverage",
  [MERCHANT_TYPE.Retail]: "Retail",
  [MERCHANT_TYPE.Market]: "Market",
  [MERCHANT_TYPE.Heartland]: "Heartland",
};