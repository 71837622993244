import React, { Component } from "react";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link } from "react-router-dom";

export default class privacy_policy_layout extends Component{  
  constructor(props) {
    super(props);
    this.state = {
      display: "English",
      chineseStyle: {
        backgroundColor: 'white',
        borderBottom: 0,
        color: 'black',
        borderRadius:0
      },
      englishStyle: {
        backgroundColor: 'white',
        borderBottom: '1px black solid',
        color: 'black',
        borderRadius:0
      }
    }
  }

  languageChange(lang) {
    var styleOn = {
      backgroundColor: 'white',
      borderBottom: '1px black solid',
      color: 'black',
      borderRadius:0
    }
    var styleOff = {
      backgroundColor: 'white',
      borderBottom: 0,
      color: 'black',
      borderRadius:0
    }
    
    if (lang == "English") {
    
      this.setState({
        display: lang,
        englishStyle: styleOn,
        chineseStyle: styleOff
      });
    } else {
      this.setState({
        display: lang,
        englishStyle: styleOff,
        chineseStyle: styleOn
      });
    }
    
  }

  render(){
    return (
      <div style={{ position: 'relative', width: '100%', padding: '70px 30px', textAlign:'center' }}>
         <Link to={{pathname:`/`}} style={{position: 'absolute', top: "10px", left:'5%'}}>
            <button className="backWeb" style={{fontSize: '12px', height:'inherit', width:'auto'}} >Back</button>
          </Link>
        <h1 style={{ textAlign: 'center' }}>About</h1>
        <div>
          <button className="backWeb" onClick={() => this.languageChange("English")} style={this.state.englishStyle}>English</button>
          <button className="backWeb" onClick={() => this.languageChange("Chinese")} style={this.state.chineseStyle}>中文</button>
        </div>
       
        {this.state.display == "English" ? <>
        <br />  <br />
        {/* <p>{this.state.policy}</p> */}
        <h2>Preserving Singapore&#39;s Heartland Markets &amp; Hawker Culture</h2>
        <p>Hawker Centres are more than just places to satisfy your taste buds; they are bustling hubs of
        culture, heritage, and gastronomic adventure. Heartland wet and dry markets collocated at Hawker
        Centres add on to the hustle and bustle of heartland activities bringing different communities
        together in a marketplace where heartlanders stopover at Hawker stalls as part of their grocery
        marketing activities.</p>
        <br />
        <p>In Singapore, Hawker Centres play a vital role by providing communal dining spaces in the city state&#39;s
        urban environment. They provide common spaces for people to enjoy their meals whilst fostering
        community spirit. Heartlanders from all walks of life come together to enjoy a symphony of flavours
        that is nothing short of a culinary delight.</p>
        <br />
        <p>Post COVID-19 many Singaporeans have become accustomed to online and mobile platforms
        providing the convenience of eOrdering at one&#39;s fingertips. In keeping pace with social norms, SG
        Hawker Centres is a dedicated digital platform for Hawkers, Market stallholders and their patrons to
        preserve Singapore&#39;s Heartland Markets &amp; Hawker Culture in the following ways:</p>
        <br />
        <br />
        <h2 style={{color:'#ff1b1f'}}>Scan, Order, Pay, Notify</h2>
        <h3>Seamless Customer Experience at Hawker Centres</h3>
        <p>Scan a QR code at Hawker stalls, order from multiple stalls, and pay from the convenience of where
        you are seated. You will be notified when your prepared food is ready for collection.</p>
        <br />
        <p>When you select a Hawker Centre from the list, choose the &#39;Dine In&#39; option to order and pay. Your
        mobile number will be required for notifying you when your orders are ready for collection. Present
        your notification order number at the point of collection.</p>
        <br />
        <br />
        <h2 style={{color:'#72B84C'}}>Save Time, Self-Pick-up</h2>
        <h3>Pre-Order from Hawker Centre and Market Web Stores</h3>
        <p>Order online at SG Hawker Centres web stores to reserve your freshest heartland market produce or
        your favourite Hawker food. You will be notified when your takeaway pre-order is ready for
        collection.</p>
        <br />
        <p>When you select a Hawker Centre from the list, choose the &#39;Self Pick-up&#39; option to pre-order your
        market groceries or takeaway prepared food for a specific date and time. Your mobile number will
        be required for notifying you when your orders are ready for collection. Present your notification
        order number at the point of collection.</p>
        <br />
        <br />
          <h2 style={{color:'#F8991D'}}>Coming Soon…Hawker Party Sets</h2>
          <h3>For Home and Corporate Social Gatherings</h3>
        <p>Specializing in Hawker meal party sets, SG Hawker Centres allows Hawkers to extend their
        multicultural culinary practices into every home and corporate social gathering.</p>
        <br />
        <p>When you select a Hawker Centre from the list, choose the &#39;Party Set&#39; option to mix and match
        different Hawker party sets for your home or corporate event. Your mobile number and address will
        be required for party set delivery services.</p>
      <br/>
        </> : <>
        <br />  <br />
        {/* <p>{this.state.policy}</p> */}
        <h2>保留新加坡的中心市场和小贩文化</h2>
        <p>小贩中心不仅仅是满足您味蕾的地方；它们是繁华的文化、遗产和美食探险中心。位于小贩中
        心的中心地带湿市场和干市场增添了中心地带活动的喧嚣，将不同的社区聚集在一个市场中，
        中心地带的人在小贩摊位中途停留，来购买家居用品。</p>
        <br />
        <p>在新加坡，小贩中心在新加坡的城市环境中提供公共用餐空间，发挥着至关重要的作用。它们
        不仅为人们提供用餐的公共空间，同时还可以培养社区精神。来自各行各业的中心地带居民齐
        聚一堂，享受一场美食交响乐。</p>
        <br />
        <p>新冠肺炎 (COVID-19) 疫情过后，许多新加坡人已经习惯了在线和移动平台提供的在线订购服务
        。为了与社会规范保持同步，&#39;SG Hawker Centres&#39; 是一个专门为小贩、市场摊主及其顾客提供
        的数字平台，通过以下方式保护新加坡的中心市场和小贩文化：</p>
        <br />
        <br />
            <h2 style={{color:'#ff1b1f'}}>扫描、订购、支付、通知</h2>
              <h3>小贩中心的无缝客户体验</h3>
        <p>在小贩摊位扫描二维码，从多个摊位点餐，然后在您就座的便利位置付款。当您准备好的食物
        可供领取时，您将会收到通知。</p>
        <br />
        <p>当您从列表中选择小贩中心时，请选择&#39;Dine In(在此用餐)&#39;选项来订购并付款。当您的订单准备
        就绪，将会通过您的手机号码来通知您。在领取时出示您的通知订单号。</p>
        <br />
        <br />
            <h2 style={{color:'#72B84C'}}>节省时间，自助取货</h2>
              <h3>从小贩中心和市场网上商店预订</h3>
        <p>在 &#39;SG Hawker Centres&#39; 网上商店在线订购，预订您最新鲜的中心地带市场农产品或您最喜欢的
        小贩食品。当您预订的外卖可供领取时，您将会收到通知。</p>
        <br />
        <p>当您从列表中选择小贩中心时，选择&#39;Self Pick-up(自提)&#39;选项即可在特定日期和时间预订市场杂
        货或外卖预制食品。当您的订单准备就绪，将会通过您的手机号码来通知您。在领取时出示您
        的通知订单号。</p>
        <br />
        <br />
            <h2 style={{color:'#F8991D'}}>即将推出…小贩派对套装</h2>
              <h3>适合家庭和企业社交聚会</h3>
        <p>&#39;SG Hawker Centres&#39; 专注于小贩餐饮派对套餐，让小贩能够将他们的多元文化烹饪实践扩展到
        每个家庭和企业社交聚会。</p>
        <br />
        <p>当您从列表中选择小贩中心时，请选择&#39;Party Set(派对套装)&#39;选项，为您的家庭或公司活动混合
        搭配不同的小贩派对套装。派对套餐送货服务需要您的手机号码和地址。</p>
      <br/>
        </>}
        
      
      </div>
    )
  }
}