import { format } from "date-fns";

export const formatDate = (value) => {
  var date = new Date(value);
  var newDate = date.setHours(date.getHours() - 8);
  return format(newDate, "yyyy-MM-dd");
};

export const formatDate2 = (value) => {
  var date = new Date(value);
  return format((date, "dd MMMM yyyy"));
};

export const formatMonth = (value) => {
  var date = new Date(value);
  var newDate = date.setHours(date.getHours() - 8);
  return format(newDate, "yyyy-MM");
};
export const formatDateTime = (value) => {
  var date = new Date(value);
  var newDate = date.setHours(date.getHours() - 8);
  return format(newDate, "yyyy-MM-dd HH:mm");
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function getBase64(file, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
}

export function getRandomUUID() {
  return new Date().getUTCMilliseconds();
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

export function formatKeyData(key) {
  return key.replace(/(_|-)/g, " ").trim();
}
