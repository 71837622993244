import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
import "./styles/index.css"
import "./styles/style.css"
import "./styles/menu_styles.css"
import "./styles/order_styles.css"
// require('dotenv').config()


ReactDOM.render(
  <Main/>, 
  document.getElementById("root")
);