import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ListItem, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { classNames } from "../components/contexts/helper";
import LayoutConfig from "../styles/layoutConfig";

const SidebarItem = ({ item, callback, selectedTitle }) => {
  const [showSubItems, setShowSubItems] = useState(false);

  const handleItemClick = () => {
    if (!item.subNav) {
      callback(item.title);
    } else {
      setShowSubItems(!showSubItems);
    }
  };

  return (
    <>
      <ListItem
        id='hover'
        component={Link}
        to={item.path}
        onClick={handleItemClick}
        className={classNames(
          item.title === selectedTitle
            ? "text-yellow-800 bg-white"
            : "text-gray-300 hover:bg-yellow-800 hover:text-white",
          "group flex items-center my-1 px-2 py-2 text-base font-medium rounded-md justify-between"
        )}
        style={{color:'black', justifyContent:'space-between'}}
      >
        <div className="flex" style={{display:'flex', flexFlow:'wrap', alignItems:'center'}}>
          <item.icon
            className={classNames(
              item.title === selectedTitle
                ? "text-yellow-800"
                : "text-gray-400 group-hover:text-gray-300",
              "mr-4 flex-shrink-0 h-6 w-6"
            )}
            aria-hidden="true"
          />
          <ListItemText primary={item.title} style={{paddingLeft:'12px'}}/>
        </div>
        {item.subNav &&
          (showSubItems ? (
            <ArrowUp2
              className={classNames(
                item.title === selectedTitle
                  ? "text-yellow-700"
                  : "text-gray-400 group-hover:text-gray-300",
                "mr-4 flex-shrink-0 h-6 w-6"
              )}
              aria-hidden="true"
            />
          ) : (
            <ArrowDown2
              className={classNames(
                item.title === selectedTitle
                  ? "text-yellow-700"
                  : "text-gray-400 group-hover:text-gray-300",
                "mr-4 flex-shrink-0 h-6 w-6"
              )}
              aria-hidden="true"
            />
          ))}
      </ListItem>
      {item.subNav && (
        <Collapse in={showSubItems} timeout="auto" unmountOnExit>
          {item.subNav.map((subItem, index) => (
            <ListItem
              id='hover-sub'
              key={index}
              component={Link}
              to={subItem.path}
              onClick={() => callback(subItem.title)}
              className={classNames(
                subItem.title === selectedTitle
                  ? "text-yellow-800 bg-white"
                  : "text-gray-300 hover:bg-yellow-800 hover:text-white",
                "group flex items-center my-1 px-2 py-2 text-base font-medium rounded-md pl-8"
              )}
              style={{color:'black'}}

            >
              <ListItemText primary={subItem.title} />
            </ListItem>
          ))}
        </Collapse>
      )}
    </>
  );
};

export default SidebarItem;
