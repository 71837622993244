import React , { Component } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Nav from 'react-bootstrap/Nav';
import Header, { LogoLink, NavLink as NavLinkBase } from './light';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom'
import { HambergerMenu } from "iconsax-react";


const StyledHeader = styled(Header)`
  ${tw`justify-between `}
  ${tw`bg-white mx-12`}
  ${LogoLink} {
    ${tw`mr-8 p-2`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-2
`;

export default class Navbar extends Component {
    constructor(props){
        super(props);
          this.state = {
            itemsCount:0,
            navLinksDesktop : [
                <Nav key={1}>                         
                  <NavLink href="/locations" style={{color: '#000000', fontSize: '16px',padding: '0.5rem 1rem', textDecoration:'none'}}>Locations</NavLink>
                  <NavLink href="/community/youth" style={{ color: '#000000', fontSize: '16px', padding: '0.5rem 1rem', textDecoration: 'none' }}>Youth &amp; Community</NavLink>
                  <NavLink href="/documentation/introduction" style={{ color: '#000000', fontSize: '16px', padding: '0.5rem 1rem', textDecoration: 'none' }}>Open API</NavLink>
                  <NavLink href="/policy/privacy" style={{ color: '#000000', fontSize: '16px', padding: '0.5rem 1rem', textDecoration: 'none' }}>Privacy Policy</NavLink>
                  <NavLink href="/orders/history" style={{color: '#000000', fontSize: '16px',padding: '0.5rem 1rem', textDecoration:'none'}}>View Past Orders</NavLink>
                </Nav>,
            ],
            navLinksMobile : [
              {
                name: "Locations",
                link: '/locations'
              },
              {
                name: "Youth & Community",
                link: '/community/youth'
              },
              {
                name: "Open API",
                link: '/documentation/introduction'
              },
              {
                name: "Privacy Policy",
                link: '/policy/privacy'
              },
              {
                name: "View Past Orders",
                link: '/orders/history'
              },
          ],
            navLinksLoginDesktop: [],
            isOpen: false
         }
  }

  toggleDrawer = (booleanState) => {
    this.setState({
      isOpen: booleanState
    })
  };

  render() {
    if (window.innerWidth < 481) { 
      return (
        <>
          {!window.location.pathname.includes("/documentation") && <>
        <React.Fragment key={'left'}>
          <Button onClick={()=>this.toggleDrawer(true)}><HambergerMenu
            size="32"
            color="#000"
            /></Button>
          <Drawer
            anchor={'left'}
            open={this.state.isOpen}
            onClose={()=>this.toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={()=>this.toggleDrawer(false)}
              onKeyDown={()=>this.toggleDrawer(false)}
            >
              <List>
                <ListItem disablePadding>
                  <Link to={'/'} style={{textDecoration:'none',color:'black'}}> 
                    <ListItemButton>
                      <ListItemText primary={"Home"} />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <Divider/>
                {this.state.navLinksMobile.map((text, index) => (
                  <ListItem key={index} disablePadding>
                    <Link to={text.link} style={{textDecoration:'none',color:'black'}}> 
                      <ListItemButton>
                        <ListItemText primary={text.name} />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </React.Fragment>
        <Divider/>
      </> }
        </>
        
      )
    } else {
      return(
        <StyledHeader links={this.state.navLinksDesktop} collapseBreakpointClass="sm" />
      )
    }
  }
}