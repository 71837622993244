import React, { Component } from "react";
import {  Link } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Table from "../../../web/common/components/table_component";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import axios from "axios";
import firebaseConfig from '../../../firebase_config'
import loading from '../../../assets/payments/loading.gif'

import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app'


import '../../../styles/menu_styles.css'

import commonConfigs from "../../../config";

const isCluster = process.env.REACT_APP_IS_CLUSTER;

export default class past_orders_layout extends Component{
  constructor(props) {
    super(props);
    this.state = {
      hawkername: "",
      display: false,
      mobile: "",
      otp:"",
      data: [],
      columns: "",
      loading: false,
      totalData:0
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.getTxnHistory = this.getTxnHistory.bind(this);
    this.handleOTPChange = this.handleOTPChange.bind(this);
  }
  
  handleMobile = (e) => {
    this.setState({
      mobile: e.target.value
    })
  }
  handleOTPChange = (e) => {
    this.setState({
      otp: e.target.value
    })
  }
  getRowId(row) {
    console.log(row);
    return row.consumer_order_id;
  }
  
  async handleSubmit(event) {
    event.preventDefault();
    if (this.state.otp !== "") {
      window.confirmationResult
        .confirm(this.state.otp)
        .then(function (result) {
          console.log(result);
          // return result;
         
          alert('Verification Success');
         
        })
        .then((result) => {
          this.setState({
            loading:true
          })
          var columns = [
            {
              // field: "Order #",
              headerName: "Order #",
              field: "order_no",
              width: 90,
            },
            {
              // field: "Payment ID",
              headerName:"Payment ID",
              field: "payment_id",
              width: 150,
            },
            {
              // field: "Order Datetime",
              headerName:"Order Datetime",
              field: "order_datetime",
              width: 200,
            },
            {
              // field: "Order Datetime",
              headerName:"Platform",
              field: "order_platform",
              width: 100,
            },
            {
              // field: "Order Datetime",
              headerName:"Payment Type",
              field: "order_payment_type",
              width: 120,
            },
            // {
            //   // field: "Order Datetime",
            //   headerName:"Location",
            //   field: "location_name",
            //   width: 150,
            // },
            {
              // field: "Order Datetime",
              headerName:"Merchant",
              field: "merchant_name",
              width: 150,
            },
            {
              // field: "Order Status",
              headerName:"Order Status",
              field: "order_status",
              width: 150,
            },
            {
              // field: "Delivery Mode",
              headerName: "Mode",
              field: "order_type",
              width: 200,
            },
            // {
            //   // field: "Delivery Status",
            //   headerName: "Status",
            //   field: "collection_status",
            //   width: 100,
            // },
          ]
          // this.setState({
          //   // data: data,
          //   columns:columns
              // })
          const getTxnHistoryUrl = commonConfigs.apiUrls.GetTxnHistory(this.state.mobile, '', "foodcanopy");
              console.log(getTxnHistoryUrl);
          axios
            .get(getTxnHistoryUrl, { headers: commonConfigs.apiHeader })
            .then((res) => {
              console.log(res);

              var response = res.data.result;
    
              this.setState({
                data: response.data,
                columns:columns,
                display: true,
                loading:false
              })
              
            })
            .catch((err) => {
              console.log("Error Message: ", err);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      alert("Please enter 6 digits OTP number.");
    }
  }
  sendCode() {
    this.setState({
      mobile: this.state.mobile,
      disabled: false,
    });
    var number = "+65 " + this.state.mobile;
    console.log(number);

    window.recaptchaVerifier = new RecaptchaVerifier(getAuth(), "recaptcha-container", {
      'size': 'invisible'
    });
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(getAuth(), number, appVerifier)
    .then(function (confirmationResult) {
      console.log("Success");
      window.confirmationResult = confirmationResult;
      console.log(confirmationResult);
   
      alert("OTP sent");
    })
    .catch(function (error) {
      alert(error);
      console.log("Error:" + error);
    });
   
  }
  componentDidMount() {
    initializeApp(firebaseConfig);
  }

  render() {
    clearInterval();

    let linkurl;
    if (isCluster == 0 || isCluster == 1) {
      linkurl = "/merchants";
    } else {
      linkurl = "/web/clusters";
    }

    return (
    <>
    
    <div style={{position:'relative', width:'100%', height:'100vh'}}>
    
      {/* <Bg/> */}
      <Link to={{pathname:`/`}} style={{position: 'absolute', top: "10px", left:'5%'}}>
      {window.innerWidth < 481 ? <button className="backWeb" style={{fontSize: '12px', height:'inherit', width:'auto'}} >Back</button>:<button className="backWeb" >Back</button>}
      </Link>
      <h2 className="textdiningmode" style={{ fontSize: '24px', top: '15%'}}>Enter Mobile Number</h2>
      <div className="container" style={{padding:0, top:'22%'}}>
        <div className="clearfix" style={{textAlign:'left'}}>
        <form onSubmit={this.handleSubmit} style={{width:'35%', margin:'auto'}}>
          <div style={{display:'inline-flex'}}>
              <div className="col-span-6 sm:col-span-3 mt-5">
                <Typography id="mobile" variant="h6" component="h2" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '18px',fontWeight: 'bold',letterSpacing: 0.16,marginTop: '30px'}}>Mobile</Typography>
                <TextField id="mobile" label="Enter Mobile Number" variant="standard"  onChange={this.handleMobile} value={this.state.mobile}/>
                      </div>
                      <div style={{padding: '20px',margin: 'auto',paddingBottom: 0,marginBottom: 0}}>
                        <label
                          htmlFor="button"
                          className="block text-sm font-medium text-gray-700"
                        ></label>
                    <button
                          id="sign-in-button"
                          type="button"
                          className="continueWeb"
                          style={{ width: '170px', background: '#009CEB'}}
                          onClick={() => this.sendCode()}
                        >
                          Get SMS OTP
                        </button>
                      </div>
                    </div>
                  <div>
                    <Typography id="otp" variant="h6" component="h2" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '18px',fontWeight: 'bold',letterSpacing: 0.16,marginTop: '30px'}}>OTP</Typography>
                    <TextField id="otp" label="Enter SMS OTP" variant="standard"  value={this.state.otp}
                        onChange={this.handleOTPChange}
                        disabled={this.state.disabled}
                        style={{width:'100%'}}/>
                    </div>

                    <div style={{textAlign:'center', margin:'20px'}}>
                      <button
                        className="continueWeb"
                        type="button"
                        onClick={this.handleSubmit}
                        style={{width:'200px'}}
                      >
                        Retrieve
                      </button>
                </div>
                <div id="recaptcha-container" />
                  </form>
                  
          </div>
          {this.state.display && <div className="clearfix" style={{ textAlign: 'center' }}>
            <h2 style={{ textAlign: 'center', fontSize: '24px', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold', letterSpacing: '0.16', marginTop: '20px', marginBottom: '20px' }}>Past Records for Mobile { this.state.mobile}</h2>
            <DataGrid
              getRowId={(e)=>this.getRowId(e)}
              rows={this.state.data}
              columns={this.state.columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              style={{width:'70%', margin:'auto'}}
            />
               {/* <Table
                columns={this.state.columns}
                data={this.state.data}
                filters={this.state.columns.map((column) => column.accessor)}
                pageChangeHandler={this.setCurrentPage()}
                totalData={this.state.totalData}
              /> */}
            </div>}
            {this.state.loading && <img height="200px" width="200px" style={{ display:'block', marginLeft:'auto', marginRight:'auto', marginTop:'20px'}}src={loading}/>}
        </div>
      </div>
    </>
    
        
      
    );
  }
}