import { AppBar, Toolbar, Typography } from "@mui/material"
import layoutConfig from "../styles/layoutConfig"

const Topbar = () => {
    return (
        <AppBar
        position="fixed"
        sx={{
            width: `calc(100% - ${layoutConfig.sidebar.width})`,
            ml: layoutConfig.sidebar.width,
            boxShadow: 'unset',
            backgroundColor: layoutConfig.topbar.bg,
            color: layoutConfig.topbar.colour
        }}>
            <Toolbar>
                <h5 style={{color: 'black'}}>
                    SG Hawker Centres - Open API Documentation
                </h5>
            </Toolbar>
        </AppBar>
    )
};

export default Topbar;