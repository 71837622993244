import { Box, Drawer, List, Stack, Toolbar } from "@mui/material";
import React, { useState, useContext } from "react";
import LayoutConfig from "../styles/layoutConfig";
import SidebarItem from "./sidebarItem";
import {
  CurrentNavigationRouteContext,
  NavigationRoutesContext,
} from "./contexts/navigationContext.jsx";
import logo from "../../../assets/web/sghawkerslogo.png"


const Sidebar = () => {
  // const [showSidebar, setShowSidebar] = useState(false);
  const { navigationRoutes } = useContext(NavigationRoutesContext);
  const { currentRoute, setCurrentRoute } = useContext(
    CurrentNavigationRouteContext
  );
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: LayoutConfig.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: LayoutConfig.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: LayoutConfig.sidebar.bg,
          color: LayoutConfig.sidebar.color
        }
      }}
    >
      <List disablePadding>
        <Toolbar sx={{ marginBottom: "8px" }}>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="center"
          >
            <Box component="img" src={logo} alt="food canopy logo" sx={{ height: "63px", width: "100%", marginTop: "15px" }} />
          </Stack>
        </Toolbar>
        {navigationRoutes.map((item) => (
          <SidebarItem
            key={item.title}
            item={item}
            callback={(title) => {
              setCurrentRoute(title);
            }}
            selectedTitle={currentRoute}
          />
        ))}
      </List>
    </Drawer>
  );
    
};

export default Sidebar;
