import { NavigationContextProvider } from "./contexts/navigationContext";
import MainLayout from "./../pages/mainLayout";


const NavbarWrapper = () => {
  return (
    <NavigationContextProvider>
      <MainLayout />
    </NavigationContextProvider>
  );
};

export default NavbarWrapper;
