import React, { Component } from "react";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Shops from '../components/product_category_component'
import CheckoutDetails from '../../checkout_module/components/checkout_details_component'
import loading from '../../../assets/payments/loading.gif'
import Alert from '../../../web/common/components/alert_user_component'
import {
  Link,
} from "react-router-dom";
import '../../../styles/menu_styles.css'
import banner1 from '../../../../src/assets/background/BCHC_1_VECTOR.png';
import banner2 from '../../../../src/assets/background/BCHC_2_VECTOR.png';
import banner3 from '../../../../src/assets/background/BCHC_3_VECTOR.png';
import banner4 from '../../../../src/assets/background/BCHC_4_VECTOR.png';
import banner5 from '../../../../src/assets/background/BCHC_5_VECTOR.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Badge from '@mui/material/Badge';
import ErrorAlert from "../../common/components/alert_error_component";

import axios from "axios";
import commonConfigs from "../../../config";

const clusterName = process.env.REACT_APP_CLUSTER_NAME;
var bannerurl = "";

if (clusterName=== "Cluster_1 Neon Forest (Stall 21 - 28)") {
  bannerurl = banner1;
}else if (clusterName=== "Cluster_2 Forest of Dreams (Stall 12 -20)") {
  bannerurl = banner2;
}else if (clusterName=== "Cluster_3 Living Forest (Stall 47 - 55)") {
  bannerurl = banner3;
}else if (clusterName=== "Cluster_4 Vibrant Forest (Stall 38 - 46)") {
  bannerurl = banner4;
}else {
  bannerurl = banner5;
}

const buttonStyles = {
  position: 'absolute',
  top: "20px",
  left: '15%',
  borderRadius: '25px', 
  color: 'white',
  padding: '5px',
  paddingLeft: '25px',
  paddingRight: '25px',
  backgroundColor: '#5BBA44',
  textDecoration: 'none',
  border:0,
  fontSize: '24px',
  width: '145px',
  height: '50px',
  textTransform: 'capitalize'
}
const badgeStyleLow = {
  "& .MuiBadge-badge": {
    color: 'white',
    backgroundColor: '#FFBF00',
    width:'max-content',
    fontSize:'16px',
    padding:'15px',
    borderRadius:'15px'

  },
  
    
}
const badgeStyleOut = {
  "& .MuiBadge-badge": {
    color: 'white',
    backgroundColor: '#FA5F55',
    width:'max-content',
    fontSize:'16px',
    padding:'15px',
    borderRadius:'15px'
  }    
}

const badgeStyleLowMobile = {
  "& .MuiBadge-badge": {
    color: 'white',
    backgroundColor: '#FFBF00',
    width:'max-content',
    fontSize:'12px',
    padding:'10px',
    borderRadius:'15px'

  },
  
    
}
const badgeStyleOutMobile = {
  "& .MuiBadge-badge": {
    color: 'white',
    backgroundColor: '#FA5F55',
    width:'max-content',
    fontSize:'12px',
    padding:'10px',
    borderRadius:'15px'
  }    
}
const emenu_hpbcats = localStorage.getItem('emenu_hpbcats');

export default class product_layout extends Component{
  constructor(props) {
    super(props);
    const url = window.location.href.split('/');
    const merchantid = localStorage.getItem('merchantid');

    this.state = {
      merchantid: merchantid,
      orgid: localStorage.getItem('orgid'),
      allproductsOG: [],
      allproducts:[],
      allcattags:[],
      allsidetags:[],
      hpbcat:[],
      load: true,
      display:false,
      itemqty:1,
      details:[],
      open:false,
      dinemode: localStorage.getItem('dinemode'),
      timeout: false,
      seconds: process.env.REACT_APP_TIMEOUT,
      productslistdisplay: [],
      inputValue: "",
      errorMsg: "",
      functionIndex:""
    };
    this.timer = 0;
    this.interval_start = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  startTimer() {
    if (this.timer=== 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let secondstime = this.state.seconds - 1000;
    // Check if we're at zero.
    if (secondstime=== 0) { 
      clearInterval(this.timer);
      this.setState({
        timeout: true,
      });
    } else {
       this.setState({
      seconds: secondstime,
    });
    }
  }
  async getAllProducts() {
    try {
      const dinemode = localStorage.getItem('dinemode');
    
      const pefGetMenuProducts = commonConfigs.apiUrls.GetMenuProducts(this.state.orgid, this.state.merchantid, 1);
      const GetMenuProducts = commonConfigs.apiUrls.GetMenuProducts(this.state.orgid, this.state.merchantid, '');
      var url = ""
      if (this.state.dinemode === '99') {
        url = pefGetMenuProducts;
      } else {
        url = GetMenuProducts;
      }
      console.log(url);
      await axios
        .get(url, {
          headers: commonConfigs.apiHeader,
        })
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            var response = res.data.result.data;

            var allCatTags = [];
            var allProductsArr = [];
            for (var i = 0; i < response.length; i++) {
              allProductsArr.push(response[i]);
              allCatTags.push(response[i].product_cat);
            }
            //concat arrays
            var concatCatTags = [];
            for (var a = 0; a < allCatTags.length; a++) {
              concatCatTags = concatCatTags.concat(allCatTags[a]);
            }

            //filter and remove duplicate
            var finalCatTags = [];

            const filteredCatTags = concatCatTags.filter(element => {
              const isDuplicate = finalCatTags.includes(element.product_cat_id);
        
              if (!isDuplicate) {
                finalCatTags.push(element.product_cat_id);
            
                return true;
              }
        
              return false;
            });

            localStorage.setItem('emenu_products', JSON.stringify(allProductsArr));
            var newFiltered = [{
              product_cat_id: 0,
              product_cat_img_url: "https://www.cspinet.org/sites/default/files/styles/700x530/public/2021-11/december21_bn_hero_700x530.jpg?h=61bc1599",
              product_cat_name: "All Items"
            }];

            for (var i = 0; i < filteredCatTags.length; i++) {
              if (filteredCatTags[i].product_cat_name !== "PAYEATFWD" && filteredCatTags[i].product_cat_name !== "KIOSK" && filteredCatTags[i].product_cat_name !== "Test Items" && filteredCatTags[i].product_cat_name !== "Kiosk" && filteredCatTags[i].product_cat_name !== "MPOS" && filteredCatTags[i].product_cat_name !== "PayEatFwd") {
                newFiltered.push(filteredCatTags[i]);
              }
            }
            localStorage.setItem('emenu_product_cats', JSON.stringify(newFiltered));

            let arrOfProductsDisplay;

            let arrOfProducts = [];
            let allProducts = [];
    
            for (var i = 0; i < allProductsArr.length; i++) {
              if (allProductsArr[i].is_add_on !== 1 && allProductsArr[i].is_visible !== 0) {
                allProducts.push(allProductsArr[i]);
            
              }
    
              if (allProductsArr[i].is_add_on !== 1 && allProductsArr[i].is_visible !== 0) {
                var isPEF = 0;
                for (var x = 0; x < allProductsArr[i].product_cat.length; x++) {
                  if (allProductsArr[i].product_cat[x].product_cat_name === "PayEatFwd" || allProductsArr[i].product_cat[x].product_cat_name === "PAYEATFWD") {
                    isPEF = 1;
                  }
                }
    
                if (isPEF === 1) {
                  arrOfProducts.push(allProductsArr[i]);
                }
              }
            }
    
            if (dinemode === '99') {
              arrOfProductsDisplay = arrOfProducts;
            } else {
              arrOfProductsDisplay = allProducts;
            }

            this.setState({
              allsidetags: newFiltered,
              allproductsOG: arrOfProductsDisplay,
              allproducts: arrOfProductsDisplay,
              productslistdisplay: arrOfProductsDisplay
            }, () => this.getHPBCat());
          } else {
            alert(`Error Message: `, res);
          }
        })
        .catch((err) => {
          console.log("Error Message: ", err);
        });
    } catch (e) {
      this.setState({
        errorMsg: "System Error Code",
        functionIndex: "01"
      })
    }
  }
  handleCatChange(tagName){
    this.setState({
      load:true
    });
    var filteredProducts = [];
    if (tagName=== 'All Items') {
      this.setState({
        allproducts: this.state.allproductsOG,
        productslistdisplay: this.state.allproductsOG,
        load:false
      });
    } else {
      for (var i = 0; i < this.state.allproductsOG.length; i++){
        const resultFilter = this.state.allproductsOG[i].product_cat.find(({ product_cat_name }) => product_cat_name === tagName);
  
        if (resultFilter) {
          filteredProducts.push(this.state.allproductsOG[i]);  
        }
      }
      this.setState({
        allproducts: filteredProducts,
        productslistdisplay: filteredProducts,
        load:false
      });
    }
  };
  async getHPBCat() {
    try {
      if (emenu_hpbcats !== null) { 
        this.setState({
          hpbcat: JSON.parse(emenu_hpbcats),
          load:false
        });
      } else {
        await axios
          .get(commonConfigs.apiUrls.GetHPBCategories(), {
            headers: commonConfigs.apiHeader,
          })
          .then((res) => {
            console.log(res);
            if (res.data.status=== "success") { 
              var response = res.data.result;
              
              localStorage.setItem('emenu_hpbcats',JSON.stringify(response.data));
  
              this.setState({
                hpbcat: response.data,
                load:false
              });
            } else {
              console.log("Error Message: " + res)
            }
          })
          .catch((err) => {
            console.log("Error Message: ", err)
          });
        } 
    }catch (e) {
      this.setState({
        errorMsg: "System Error Code",
        functionIndex: "02"
      })
    }
  }
  closeModal(imgurl,productavailqty,productid,name,price,addons, prefs){
    localStorage.removeItem('productid');
    localStorage.removeItem('name');
    localStorage.removeItem('price');
    localStorage.removeItem('imgurl');
    localStorage.removeItem('addons');
    localStorage.removeItem('prefs');
   
    this.setState({
      display:false,
      itemqty:1
    })
  }
  displayModal(imgurl,productavailqty,productid,name,price,addons, prefs){
    localStorage.removeItem('productid');
    localStorage.removeItem('name');
    localStorage.removeItem('price');
    localStorage.removeItem('imgurl');
    localStorage.removeItem('addons');
    localStorage.removeItem('prefs');
    localStorage.setItem('addons',JSON.stringify(addons));
    localStorage.setItem('prefs',JSON.stringify(prefs));
    localStorage.setItem('name',name);
    localStorage.setItem('price',price);
    if(imgurl=== "" || imgurl=== null){
      imgurl = "https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg"
    }
    localStorage.setItem('imgurl',imgurl);
    localStorage.setItem('productid',productid);
    localStorage.setItem('productavailqty',productavailqty);

    this.setState({
      display:true
    })
  }
  redirectToDetail(imgurl,productavailqty,productid,name,price,addons, prefs, hpb, minaddonqty, discountdesc, discountpercent, discountvalue, productvouchercode){
      localStorage.removeItem('merchantid');
      localStorage.removeItem('productid');
      localStorage.removeItem('name');
      localStorage.removeItem('price');
      localStorage.setItem('productvouchercode', productvouchercode);
      localStorage.removeItem('imgurl');
      localStorage.removeItem('addons');
      localStorage.removeItem('prefs');
      localStorage.removeItem('hpb');
      localStorage.removeItem('minadonqty');
      localStorage.removeItem('discountdesc');
      localStorage.removeItem('discountpercent');
      localStorage.removeItem('discountvalue');
    

      localStorage.setItem('merchantid',this.state.merchantid);
      localStorage.setItem('addons',JSON.stringify(addons));
      localStorage.setItem('prefs',JSON.stringify(prefs));
      localStorage.setItem('name',name);
      localStorage.setItem('price', price);
      localStorage.setItem('productvouchercode', productvouchercode);
      localStorage.setItem('hpb', hpb);
      localStorage.setItem('minaddonqty', minaddonqty);
      localStorage.setItem('discountdesc',discountdesc);
      localStorage.setItem('discountpercent',discountpercent);
      localStorage.setItem('discountvalue',discountvalue);
      if(imgurl=== "" || imgurl=== null){
        imgurl = "https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg"
      }
      localStorage.setItem('imgurl',imgurl);
      localStorage.setItem('productid',productid);
      localStorage.setItem('productavailqty',productavailqty);
         
  }
  addToOrder(imgurl, productid, name, price, productvouchercode) {
    try {
      //console.log(name);
      if(localStorage.getItem("orders")=== undefined && localStorage.getItem("ordersdisplay")=== undefined || localStorage.getItem("orders")=== null && localStorage.getItem("ordersdisplay")=== null || localStorage.getItem("orders")=== "" && localStorage.getItem("ordersdisplay")=== ""){
        var item = [{
          product_id: productid,
          quantity: this.state.itemqty,
          product_voucher_code: productvouchercode,
          add_ons:[],
          order_pref: "",
          remarks: ""
        }]
        var itemdisplay = [{
          product_id: productid,
          product_name: name,
          product_imgurl: imgurl,
          product_price: price,
          quantity: this.state.itemqty,
          product_voucher_code: productvouchercode,
          add_ons:[],
          order_pref: "",
          remarks: ""
        }]
        localStorage.setItem("orders", JSON.stringify(item));  
        localStorage.setItem("ordersdisplay", JSON.stringify(itemdisplay));  
        window.location.reload();

      }else{
        var items = JSON.parse(localStorage.getItem("orders"));
        var itemsdisplay = JSON.parse(localStorage.getItem("ordersdisplay"));
        var item = {
          product_id: productid,
          quantity: this.state.itemqty,
          product_voucher_code: productvouchercode,
          add_ons:[],
          order_pref:"",
          remarks: ""

        }
        var itemdisplay = {
          product_id: productid,
          product_name: name,
          product_imgurl: imgurl,
          product_price: price,
          quantity: this.state.itemqty,
          product_voucher_code: productvouchercode,
          add_ons:[],
          order_pref: "",
          remarks: ""
        }

        items.push(item);
        itemsdisplay.push(itemdisplay);
        localStorage.removeItem("orders");
        localStorage.removeItem("ordersdisplay");
        localStorage.setItem("orders", JSON.stringify(items));
        localStorage.setItem("ordersdisplay", JSON.stringify(itemsdisplay));
        window.location.reload();

      }
    }catch (e) {
      this.setState({
        errorMsg: "System Error Code",
        functionIndex: "03"
      })
    }
  }
  addItem(avail){
    if(this.state.itemqty <= avail){
      this.setState({
        itemqty: this.state.itemqty + 1
      })
    }
  }
  removeItem(){
    if(this.state.itemqty > 1){
      this.setState({
        itemqty: this.state.itemqty - 1
      })
    }
  }
  toggleDetails(index) {
    const position = this.state.details.indexOf(index)
    let newDetails = this.state.details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...this.state.details, index]
    }
    this.setState({
      details:newDetails
    });
  }
 
  handleClickOpen = () =>{
    this.setState({
      open: true
    });
  };
  handleClose = () =>{
    this.setState({
      open: false
    });

  };
  onChangeSearch(e) {
    console.log(e.target.value)
    var results = this.state.allproducts.filter(menu =>
      menu.product_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({
      inputValue: e.target.value,
      productslistdisplay:results
    })
  }
  componentDidMount(){
    this.getAllProducts();
  }

  render() {
    clearInterval();
    if (window.innerWidth < 481) {
      return (
        <div >
        <Box sx={{ display: 'block' }}>
          <CssBaseline />
            <div style={{ display: 'inline-flex', height:'115px', width:'100%' }}>
            <div className="drawerWeb" style={{paddingTop:'30px'}}>
              {localStorage.getItem('orders') ? <Link to=''>
                    <Alert buttonStyle={ buttonStyles} buttonTrigger="Back" message="Going back will clear your cart. Are you sure?" linkurl="/merchants" buttonLeft="STAY" buttonRight="YES"/>
                </Link> : <Link to={{pathname:`/merchants`}} >
                <button className="backMobile" >Back</button>
                </Link>}
                <div style={{textAlign:'center', fontSize:'12px',width:'100%'}}>
                <h4 style={{color:'#1F5626',fontWeight:'bold', fontSize:'12px',margin:0}}> {localStorage.getItem('merchantname')} </h4>
                <h4 style={{color:'#6D7278', fontSize:'12px',margin:0,marginBottom:'10px'}}>{localStorage.getItem('merchantunit')} </h4>
              </div>
              </div>
              <Divider orientation="vertical" flexItem/>
              <List style={{ maxHeight: '185px', overflow: 'auto',whiteSpace: 'nowrap'}}>
                {this.state.allsidetags && this.state.allsidetags.map((tag, index) => (
                  <ListItem key={index} disablePadding onClick={() => this.handleCatChange(tag.product_cat_name)} style={{display: 'inline-block', width:'fit-content'}}>
                    <ListItemButton>
                      <Shops data={tag}/>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
             </div>
             <Divider />
    
             <div style={{ margin: '20px' }}>
              <input type="text" value={this.state.inputValue} onChange={(e)=>this.onChangeSearch(e)} placeholder="Search" style={{ width: '100%', borderRadius: 8, border: 0, background: '#f7f7f7', padding: 10, outline:'none' }} />
            </div>
             
              <h2 style={{textAlign:'center'}}>Products</h2>
          {this.state.load=== false ? <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gridGap: 10, marginTop:0, paddingTop:0}}
            >
              {this.state.productslistdisplay && this.state.productslistdisplay.map((prods,index)=>(
                 <div key={index} style={{ width: "100%", marginBottom: "0px", position:'relative', margin:'auto'}}>
                    <div key={index} style={{ marginBottom: "0px" }}>
                  {prods.product_stock_status=== "Out of Stock" ?
                  <>
                  <Card style={{position:'relative',opacity:'0.5', borderRadius:'10px', display:'inline-flex'}}>
                  {prods.product_img_url !== null && prods.product_img_url !== "" && prods.product_img_url.includes('http')?
                    <div key={index} style={{display:'inline-block'}}>

                      {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleLowMobile} badgeContent={prods.product_stock_status} />}
                      {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleOutMobile} badgeContent={prods.product_stock_status} />}
                      <CardMedia
                          component="img"
                          height="80"
                          image={prods.product_img_url}
                                alt="burger"
                                style={{width:'auto',padding:'10px',borderRadius:'20px'}}
                        />
                    </div>
                      :
                      <div key={index} style={{display:'inline-block'}}>
                      {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleLowMobile} badgeContent={prods.product_stock_status} />}
                      {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleOutMobile} badgeContent={prods.product_stock_status} />}                  
                      <CardMedia
                        component="img"
                        height="80"
                        image="https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg"
                                alt="burger"
                                style={{width:'auto',padding:'10px',borderRadius:'20px'}}
                      />
                      </div>
                      
                  }
                  <CardContent style={{display: "inline-block", fontSize:'12px', padding: '10px'}}>
                    <div >
                    <Typography gutterBottom variant="p" component="span">
                      {prods.product_name}
                    </Typography>
                    </div>
                            <div >
                            {prods.product_discount_percent > 0 && prods.product_discount_value > 0 ? <>{(prods.product_price * ((100 - prods.product_discount_percent) / 100)) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{ textAlign: 'end', fontSize: '12px', color: 'red' }}>
                      ${(prods.product_price * ((100 - prods.product_discount_percent) / 100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{ textAlign: 'end', fontSize: '12px', textDecoration: 'line-through' }}>
                        ${prods.product_price.toFixed(2)} </Typography>  <br/>
                        <p style={{textAlign:'left',fontSize:'10px', fontWeight:'600', margin:0, width:'90%'}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </>  : <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                                ${prods.product_price.toFixed(2)} </Typography>} </> : <></>}
                              
                      { prods.product_discount_percent > 0 && (prods.product_discount_value=== 0 || prods.product_discount_value=== null) ? <>{(prods.product_price * ((100 - prods.product_discount_percent) / 100)) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', color:'red'}}>
                        ${(prods.product_price * ((100 - prods.product_discount_percent)/100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', textDecoration:'line-through'}}>
                        ${prods.product_price.toFixed(2)} </Typography>  <br/>
                        <p style={{textAlign:'left',fontSize:'10px', fontWeight:'600', margin:0, width:'90%'}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </> : <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                                ${prods.product_price.toFixed(2)} </Typography>} </> : <></>}
                              
                        { prods.product_discount_value > 0 && (prods.product_discount_percent=== 0 || prods.product_discount_percent=== null) ? <>{(prods.product_price - prods.product_discount_value) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', color:'red'}}>
                        ${(prods.product_price - prods.product_discount_value).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', textDecoration:'line-through'}}>
                                      ${prods.product_price.toFixed(2)} </Typography> <br/>
                        <p style={{textAlign:'left',fontSize:'10px', fontWeight:'600', margin:0, width:'90%'}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </> : <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                        ${prods.product_price.toFixed(2)} </Typography>} </> : <></>}
                                      { (prods.product_discount_percent=== null || prods.product_discount_percent=== 0) && (prods.product_discount_value=== null || prods.product_discount_value=== 0) && <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                        ${prods.product_price.toFixed(2)} </Typography>}
                       
                        
                        {prods.calorie.calorie_count=== -1 || prods.hpb_health_choice.hpb_cat_id !== 0 ? <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '25px',bottom: '18px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: 'white',
                            width:'99px',
                            fontSize:'16px',
                            padding:'15px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={""} />:<Badge style={{position: "absolute",right: "50px",bottom: "20px"}} max={1000} sx={{ "& .MuiBadge-badge": {
                          color: 'white',
                          backgroundColor: prods.calorie.calorie_color_sys_code,
                          width:'80px',
                          fontSize:'12px',
                          padding:'10px',
                          borderRadius:'15px'
                        } }} badgeContent={prods.calorie.calorie_count +" kcals"} />
                      }    
                    
    
                    </div>
                  </CardContent>
                  </Card>
                  </>:<>
                  {prods.add_ons.length < 1 && prods.product_preference < 1 ? 
                  <>
                  <Link to='/orders' style={{textDecoration: 'none'}}>
                  <Card  style={{position:'relative',width:'100%', borderRadius:'10px', display:'inline-flex'}} onClick={(e)=>this.redirectToDetail(prods.product_img_url,prods.product_stock_qty,prods.product_id,prods.product_name,prods.product_price,prods.add_ons, prods.product_preference,prods.hpb_health_choice.hpb_cat_id, prods.product_min_add_on_qty,prods.product_discount_desc,prods.product_discount_percent,prods.product_discount_value, prods.product_voucher_code)}>
                  {prods.product_img_url !== null && prods.product_img_url !== "" && prods.product_img_url.includes('http')?
                    <div key={index} style={{display:'inline-block'}}>
                      {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleLowMobile} badgeContent={prods.product_stock_status} />}
                      {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleOutMobile} badgeContent={prods.product_stock_status} />}
                      <CardMedia
                          component="img"
                          height="80"
                          image={prods.product_img_url}
                          alt="burger"
                          style={{width:'auto',padding:'10px',borderRadius:'20px'}}
                        />
                    </div>
                      :
                      <div key={index}>
                      {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleLowMobile} badgeContent={prods.product_stock_status} />}
                      {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleOutMobile} badgeContent={prods.product_stock_status} />}                  
                      <CardMedia
                        component="img"
                        height="80"
                        image="https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg"
                                      alt="burger"
                                      style={{width:'auto',padding:'10px',borderRadius:'20px'}}
                      />
                      </div>
                      
                  }
                  <CardContent style={{display: "inline-block", fontSize:'12px', padding: '10px'}}>
                    <div >
                    <Typography gutterBottom variant="p" component="span">
                      {prods.product_name}
                    </Typography>
                    </div>
                                  <div>
                                  {prods.product_discount_percent > 0 && prods.product_discount_value > 0 ? <>{(prods.product_price * ((100 - prods.product_discount_percent) / 100)) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{ textAlign: 'end', fontSize: '12px', color: 'red' }}>
                      ${(prods.product_price * ((100 - prods.product_discount_percent) / 100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{ textAlign: 'end', fontSize: '12px', textDecoration: 'line-through' }}>
                        ${prods.product_price.toFixed(2)} </Typography><br/>
                        <p style={{textAlign:'left',fontSize:'10px', fontWeight:'600', margin:0, width:'90%'}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </> : <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                                      ${prods.product_price.toFixed(2)} </Typography>} </> : <></>}
                                    
                      { prods.product_discount_percent > 0 && (prods.product_discount_value=== 0 || prods.product_discount_value=== null) ? <>{(prods.product_price * ((100 - prods.product_discount_percent) / 100)) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', color:'red'}}>
                        ${(prods.product_price * ((100 - prods.product_discount_percent)/100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', textDecoration:'line-through'}}>
                        ${prods.product_price.toFixed(2)} </Typography><br/>
                        <p style={{textAlign:'left',fontSize:'10px', fontWeight:'600', margin:0, width:'90%'}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </> : <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                                      ${prods.product_price.toFixed(2)} </Typography>} </> : <></>}
                                    
                        { prods.product_discount_value > 0 && (prods.product_discount_percent=== 0 || prods.product_discount_percent=== null) ? <>{(prods.product_price - prods.product_discount_value) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', color:'red'}}>
                        ${(prods.product_price - prods.product_discount_value).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', textDecoration:'line-through'}}>
                                      ${prods.product_price.toFixed(2)} </Typography><br/>
                        <p style={{textAlign:'left',fontSize:'10px', fontWeight:'600', margin:0, width:'90%'}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </> : <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                        ${prods.product_price.toFixed(2)} </Typography>} </> : <></>}
                                      { (prods.product_discount_percent=== null || prods.product_discount_percent=== 0) && (prods.product_discount_value=== null || prods.product_discount_value=== 0) && <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                        ${prods.product_price.toFixed(2)} </Typography>}
                        
                        
                        {prods.calorie.calorie_count=== -1 || prods.hpb_health_choice.hpb_cat_id !== 0 ? <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '25px',bottom: '18px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: 'white',
                            width:'80px',
                            fontSize:'12px',
                            padding:'10px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={""} />:<Badge style={{position: "absolute",right: "50px",bottom: "20px"}} max={1000} sx={{ "& .MuiBadge-badge": {
                      color: 'white',
                      backgroundColor: prods.calorie.calorie_color_sys_code,
                      width:'80px',
                      fontSize:'12px',
                      padding:'10px',
                      borderRadius:'15px'
                    } }} badgeContent={prods.calorie.calorie_count +" kcals"} />}
                                      
    
                    </div>
                  </CardContent>
                  </Card>
                  <Dialog
                    key={index}
                    onClose={() =>this.handleClose()}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                    maxWidth="md"
                  >
                    <DialogTitle id="customized-dialog-title" onClose={() =>this.handleClose()}>
                    {prods.product_img_url !== null && prods.product_img_url !== "" && prods.product_img_url.includes('http') ?
                    <CardMedia
                    component="img"
                    height="100%"
                    image={prods.product_img_url}
                    alt="burger"
                  /> : 
                  <CardMedia
                      component="img"
                      height="250"
                      image="https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg"
                      alt="burger"
                    />
                   }
                    
                    </DialogTitle>
                    <DialogContent dividers>
                    <div style={{padding:'20px'}}>
                        <h3 style={{margin:0, display:'inline-block',lineHeight: '50px',fontSize:'42px'}}>{prods.product_name}</h3>
                        <h3 style={{margin:0, display:'inline-block',float:'right',fontWeight:'300', lineHeight: '50px',fontSize:'42px'}}>${prods.product_price}</h3>
                    </div> 
                      
                    </DialogContent>
                    <DialogActions>
                    <div name="orders" id="orders" className="orders"
                    style={{height: "100px", width: "366px", textAlign: "center",borderRadius:'10px',borderColor:'#5BBA44',borderWidth:'3px'}}> 
                    <h1 className="text" style={{marginTop: "5px",fontSize:'42px', lineHeight:2}}>{this.state.itemqty}</h1>
                    </div>
                    <div className="button" style={{display: "grid", marginLeft: "20px"}}>
                      <button className="button2" onClick={()=>this.addItem(prods.product_stock_qty)} style={{margin: "10px", marginBottom: 0,height: '58px', width: "200px", fontSize:'42px'}}>+</button>
                      <button className="button2" onClick={()=>this.removeItem()} style={{margin: "10px", height: '58px', width: "200px", fontSize:'42px'}}>-</button>
                    </div>
                    <div style={{width: "366px", textAlign:"center", marginTop: "10px", marginLeft:'20px'}}>
                    <Link to='/products'>
                      <button className="button2" onClick={()=>this.addToOrder(prods.product_img_url, prods.product_id, prods.product_name, prods.product_price)} style={{width: "366px", height: "100px", fontSize:'32px', marginTop:'10px', fontWeight:'bold'}}>ADD TO ORDER</button>
                    </Link>
                    </div>
                    </DialogActions>
                  </Dialog>
                  </Link>
                  </>:
                  <Link to='/orders' style={{textDecoration: 'none'}}>
                    <Card  style={{position:'relative',width:'100%', borderRadius:'10px', display:'inline-flex'}} onClick={(e)=>this.redirectToDetail(prods.product_img_url,prods.product_stock_qty,prods.product_id,prods.product_name,prods.product_price,prods.add_ons, prods.product_preference,prods.hpb_health_choice.hpb_cat_id, prods.product_min_add_on_qty,prods.product_discount_desc,prods.product_discount_percent,prods.product_discount_value, prods.product_voucher_code)}>
                      {prods.product_img_url !== null && prods.product_img_url !== "" && prods.product_img_url.includes('http')?
                        <div key={index} style={{display:'inline-block'}}>
                          {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleLowMobile} badgeContent={prods.product_stock_status} />}
                          {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleOutMobile} badgeContent={prods.product_stock_status} />}
                          <CardMedia
                              component="img"
                              height="80"
                              image={prods.product_img_url}
                                    alt="burger"
                                    style={{width:'auto',padding:'10px',borderRadius:'20px'}}
                            />
                        </div>
                          :
                          <div key={index}>
                          {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleLowMobile} badgeContent={prods.product_stock_status} />}
                          {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",left: "50px",top: "60px"}} sx={badgeStyleOutMobile} badgeContent={prods.product_stock_status} />}                  <CardMedia
                            component="img"
                            height="80"
                            image="https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg"
                                    alt="burger"
                                    style={{width:'auto',padding:'10px',borderRadius:'20px'}}
                          />
                          </div>
                          
                      }
                    <CardContent style={{display: "inline-block", fontSize:'12px', padding: '10px'}}>
                      <div >
                      <Typography gutterBottom variant="p" component="span">
                        {prods.product_name}
                      </Typography>
                      </div>
                                <div >
                    {prods.product_discount_percent > 0 && prods.product_discount_value > 0 ? <>{(prods.product_price * ((100 - prods.product_discount_percent) / 100)) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{ textAlign: 'end', fontSize: '12px', color: 'red' }}>
                      ${(prods.product_price * ((100 - prods.product_discount_percent) / 100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{ textAlign: 'end', fontSize: '12px', textDecoration: 'line-through' }}>
                        ${prods.product_price.toFixed(2)} </Typography> <br/>
                        <p style={{textAlign:'left',fontSize:'10px', fontWeight:'600', margin:0, width:'90%'}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p></> : <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                                    ${prods.product_price.toFixed(2)} </Typography>} </> : <></>}
                                  
                      { prods.product_discount_percent > 0 && (prods.product_discount_value=== 0 || prods.product_discount_value=== null) ? <>{(prods.product_price * ((100 - prods.product_discount_percent) / 100)) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', color:'red'}}>
                        ${(prods.product_price * ((100 - prods.product_discount_percent)/100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', textDecoration:'line-through'}}>
                        ${prods.product_price.toFixed(2)} </Typography><br/>
                        <p style={{textAlign:'left',fontSize:'10px', fontWeight:'600', margin:0, width:'90%'}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </> : <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                                    ${prods.product_price.toFixed(2)} </Typography>} </> : <></>}
                                  
                        { prods.product_discount_value > 0 && (prods.product_discount_percent=== 0 || prods.product_discount_percent=== null) ? <>{(prods.product_price - prods.product_discount_value) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', color:'red'}}>
                        ${(prods.product_price - prods.product_discount_value).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px', textDecoration:'line-through'}}>
                                      ${prods.product_price.toFixed(2)} </Typography><br/>
                        <p style={{textAlign:'left',fontSize:'10px', fontWeight:'600', margin:0, width:'90%'}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </> : <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                        ${prods.product_price.toFixed(2)} </Typography>} </> : <></>}
                                      { (prods.product_discount_percent=== null || prods.product_discount_percent=== 0) && (prods.product_discount_value=== null || prods.product_discount_value=== 0) && <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'12px'}}>
                        ${prods.product_price.toFixed(2)} </Typography>}
                        
                        
                        {prods.calorie.calorie_count=== -1 || prods.hpb_health_choice.hpb_cat_id !== 0 ? <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '25px',bottom: '18px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: 'white',
                            width:'80px',
                            fontSize:'12px',
                            padding:'10px',
                            borderRadius:'15px'
                          }
                        }} badgeContent={""} /> : <Badge style={{position: "absolute",right: "50px",bottom: "20px"}} max={1000} sx={{ "& .MuiBadge-badge": {
                        color: 'white',
                        backgroundColor: prods.calorie.calorie_color_sys_code,
                        width:'80px',
                        fontSize:'12px',
                        padding:'10px',
                        borderRadius:'15px'
                      } }} badgeContent={prods.calorie.calorie_count+" kcals"} />}
                      </div>
                    </CardContent>
                    </Card>
                  </Link>
                  }
                      </>}
                    </div>
                </div>
               
                
              ))}
              
            </Box> : <> <img height="200px" width="200px" style={{ display:'block', marginLeft:'auto', marginRight:'auto', marginTop:'20px'}}src={loading}/></>}
          
        </Box>
          <CheckoutDetails />
          {this.state.errorMsg != "" ? <ErrorAlert errorMsgDisplay={{
            title: this.state.errorMsg,
            body: "Product Layout " + this.state.functionIndex,
          }}/>:<></>}
        </div>
        );
        
    } else {
      return (
        <div style={{height: '150vh'}}>
        <Box sx={{ display: 'block' }}>
        
          <CssBaseline />
          <div style={{ display: 'inline-flex' }}>
            <div className="drawerWeb" style={{paddingTop:'30px'}}>
              {/* <Toolbar/> */}
              {localStorage.getItem('orders') ? <Link to=''>
                    <Alert buttonStyle={ buttonStyles} buttonTrigger="Back" message="Going back will clear your cart. Are you sure?" linkurl="/merchants" buttonLeft="STAY" buttonRight="YES"/>
                </Link> : <Link to={{pathname:`/merchants`}} >
                <button className="backWeb" >Back</button>
                </Link>}
                <div style={{textAlign:'center', fontSize:'18px',width:'100%'}}>
                <h4 style={{color:'#1F5626',fontWeight:'bold', fontSize:'18px',margin:0}}> {localStorage.getItem('merchantname')} </h4>
                <h4 style={{color:'#6D7278', fontSize:'18px',margin:0,marginBottom:'10px'}}>{localStorage.getItem('merchantunit')} </h4>
              </div>
              </div>
              <Divider orientation="vertical" flexItem/>
              {/* <Toolbar style={{ height: '250px' }}> */}
              <List style={{ maxHeight: '185px',display:'inline-flex'}}>
                {this.state.allsidetags && this.state.allsidetags.map((tag, index) => (
                  <ListItem key={index} disablePadding onClick={() => this.handleCatChange(tag.product_cat_name)}>
                    <ListItemButton>
                      <Shops data={tag}/>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
             </div>
             <Divider />
    
             <div style={{ margin: '20px 200px 0 200px' }}>
              <input type="text" value={this.state.inputValue} onChange={(e)=>this.onChangeSearch(e)} placeholder="Search" style={{ width: '100%', borderRadius: 8, border: 0, background: '#f7f7f7', padding: 10, outline:'none' }} />
            </div>
             
              <h1 style={{textAlign:'center'}}>Products</h1>
          {this.state.load=== false ? <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 10, margin:'100px', marginTop:0}}
    
            >
              {this.state.productslistdisplay && this.state.productslistdisplay.map((prods,index)=>(
                 <div key={index} style={{marginTop: "3%", width: "100%", marginBottom: "0px", position:'relative', margin:'auto'}}>
                    <div key={index} style={{ marginTop: "3%", marginBottom: "0px" }}>
                  {prods.product_stock_status=== "Out of Stock" ?
                  <>
                  <Card sx={{ maxWidth: 370 }}style={{marginTop:'25px', height:'400px',position:'relative',opacity:'0.5', borderRadius:'10px'}}>
                  {prods.product_img_url !== null && prods.product_img_url !== "" && prods.product_img_url.includes('http')?
                    <>
                      {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleLow} badgeContent={prods.product_stock_status} />}
                      {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleOut} badgeContent={prods.product_stock_status} />}
                      <CardMedia
                          component="img"
                          height="250"
                          image={prods.product_img_url}
                          alt="burger"
                        />
                    </>
                      :
                      <div key={index}>
                      {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleLow} badgeContent={prods.product_stock_status} />}
                      {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleOut} badgeContent={prods.product_stock_status} />}                  
                      <CardMedia
                        component="img"
                        height="250"
                        image="https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg"
                        alt="burger"
                      />
                      </div>
                      
                  }
                  <CardContent style={{display: "inline-flex"}}>
                    <div style={{width: "180px"}}>
                    <Typography gutterBottom variant="p" component="span">
                      {prods.product_name}
                    </Typography>
                    </div>
                            <div style={{ width: "150px", textAlign: 'end' }}>
                            {prods.product_discount_percent > 0 && prods.product_discount_value > 0 ? <>{(prods.product_price * ((100 - prods.product_discount_percent)/100)) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', color:'red'}}>
                          ${(prods.product_price * ((100 - prods.product_discount_percent)/100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', textDecoration:'line-through'}}>
                          ${prods.product_price.toFixed(2)} </Typography> <br/>
                        <p style={{textAlign:'end',fontSize:'12px', fontWeight:'600', margin:0}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p></>: <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography></> } </> : <></>}
                          
                          { prods.product_discount_percent > 0 && (prods.product_discount_value=== 0 || prods.product_discount_value=== null) ? <>{(prods.product_price * ((100 - prods.product_discount_percent)/100)) > 0 ?<><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', color:'red'}}>
                          ${(prods.product_price * ((100 - prods.product_discount_percent)/100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', textDecoration:'line-through'}}>
                          ${prods.product_price.toFixed(2)} </Typography><br/>
                        <p style={{textAlign:'end',fontSize:'12px', fontWeight:'600', margin:0}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </>: <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography></> } </> : <></>}
                                  
                          { prods.product_discount_value > 0 && (prods.product_discount_percent=== 0 || prods.product_discount_percent=== null) ? <>{(prods.product_price - prods.product_discount_value) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', color:'red'}}>
                          ${(prods.product_price - prods.product_discount_value).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', textDecoration:'line-through'}}>
                          ${prods.product_price.toFixed(2)} </Typography> <br/>
                        <p style={{textAlign:'end',fontSize:'12px', fontWeight:'600', margin:0}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </>: <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography></> } </> : <></>}
                          
                          { (prods.product_discount_percent=== null || prods.product_discount_percent=== 0) && (prods.product_discount_value=== null || prods.product_discount_value=== 0) && <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography>}
                        
                        
                        {prods.calorie.calorie_count=== -1 || prods.hpb_health_choice.hpb_cat_id !== 0 ? <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '25px',bottom: '18px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: 'white',
                            width:'99px',
                            fontSize:'16px',
                            padding:'15px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={""} />:<Badge style={{position: "absolute",right: "70px",bottom: "30px"}} max={1000} sx={{ "& .MuiBadge-badge": {
                          color: 'white',
                          backgroundColor: prods.calorie.calorie_color_sys_code,
                          width:'99px',
                          fontSize:'16px',
                          padding:'15px',
                          borderRadius:'15px'
                        } }} badgeContent={prods.calorie.calorie_count +" kcals"} />
                      }    
                    
    
                    </div>
                  </CardContent>
                  </Card>
                  </>:<>
                  {prods.add_ons.length < 1 && prods.product_preference < 1 ? 
                  <>
                  <Link to='/orders' style={{textDecoration: 'none'}}>
    
                  <Card sx={{ maxWidth: 370 }} style={{marginTop:'25px',position:'relative',height:'400px', borderRadius:'10px'}} onClick={(e)=>this.redirectToDetail(prods.product_img_url,prods.product_stock_qty,prods.product_id,prods.product_name,prods.product_price,prods.add_ons, prods.product_preference,prods.hpb_health_choice.hpb_cat_id, prods.product_min_add_on_qty,prods.product_discount_desc,prods.product_discount_percent,prods.product_discount_value, prods.product_voucher_code)}>
                  {prods.product_img_url !== null && prods.product_img_url !== "" && prods.product_img_url.includes('http')?
                    <div key={index}>
                      {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleLow} badgeContent={prods.product_stock_status} />}
                      {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleOut} badgeContent={prods.product_stock_status} />}
                      <CardMedia
                          component="img"
                          height="250"
                          image={prods.product_img_url}
                          alt="burger"
                        />
                    </div>
                      :
                      <div key={index}>
                      {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleLow} badgeContent={prods.product_stock_status} />}
                      {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleOut} badgeContent={prods.product_stock_status} />}                  
                      <CardMedia
                        component="img"
                        height="250"
                        image="https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg"
                        alt="burger"
                      />
                      </div>
                      
                  }
                  <CardContent style={{display: "inline-flex"}}>
                    <div style={{width: "180px"}}>
                    <Typography gutterBottom variant="p" component="span">
                      {prods.product_name}
                    </Typography>
            
                    </div>
                                  <div style={{ width: "150px", textAlign: 'end' }}>
                                  {prods.product_discount_percent > 0 && prods.product_discount_value > 0 ? <>{(prods.product_price * ((100 - prods.product_discount_percent)/100)) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', color:'red'}}>
                          ${(prods.product_price * ((100 - prods.product_discount_percent)/100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', textDecoration:'line-through'}}>
                          ${prods.product_price.toFixed(2)} </Typography> <br/>
                        <p style={{textAlign:'end',fontSize:'12px', fontWeight:'600', margin:0}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p></>: <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography></> } </> : <></>}
                          
                          { prods.product_discount_percent > 0 && (prods.product_discount_value=== 0 || prods.product_discount_value=== null) ? <>{(prods.product_price * ((100 - prods.product_discount_percent)/100)) > 0 ?<><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', color:'red'}}>
                          ${(prods.product_price * ((100 - prods.product_discount_percent)/100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', textDecoration:'line-through'}}>
                          ${prods.product_price.toFixed(2)} </Typography> <br/>
                        <p style={{textAlign:'end',fontSize:'12px', fontWeight:'600', margin:0}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p></>: <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography></> } </> : <></>}
                                  
                          { prods.product_discount_value > 0 && (prods.product_discount_percent=== 0 || prods.product_discount_percent=== null) ? <>{(prods.product_price - prods.product_discount_value) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', color:'red'}}>
                          ${(prods.product_price - prods.product_discount_value).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', textDecoration:'line-through'}}>
                          ${prods.product_price.toFixed(2)} </Typography> <br/>
                        <p style={{textAlign:'end',fontSize:'12px', fontWeight:'600', margin:0}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p> </>: <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography></> } </> : <></>}
                          
                          { (prods.product_discount_percent=== null || prods.product_discount_percent=== 0) && (prods.product_discount_value=== null || prods.product_discount_value=== 0) && <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography>}
                        
                        
                        {prods.calorie.calorie_count=== -1 || prods.hpb_health_choice.hpb_cat_id !== 0 ? <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '25px',bottom: '18px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: 'white',
                            width:'99px',
                            fontSize:'16px',
                            padding:'15px',
                            borderRadius:'15px'
                              }
                              }} badgeContent={""} />:<Badge style={{position: "absolute",right: "70px",bottom: "30px"}} max={1000} sx={{ "& .MuiBadge-badge": {
                      color: 'white',
                      backgroundColor: prods.calorie.calorie_color_sys_code,
                      width:'99px',
                      fontSize:'16px',
                      padding:'15px',
                      borderRadius:'15px'
                    } }} badgeContent={prods.calorie.calorie_count +" kcals"} />}
                                      
                    </div>
                  </CardContent>
                  </Card>
                  <Dialog
                    key={index}
                    onClose={() =>this.handleClose()}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                    maxWidth="md"
                  >
                    <DialogTitle id="customized-dialog-title" onClose={() =>this.handleClose()}>
                    {prods.product_img_url !== null && prods.product_img_url !== "" && prods.product_img_url.includes('http') ?
                    <CardMedia
                    component="img"
                    height="100%"
                    image={prods.product_img_url}
                    alt="burger"
                  /> : 
                  <CardMedia
                      component="img"
                      height="250"
                      image="https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg"
                      alt="burger"
                    />
                   }
                    
                    </DialogTitle>
                    <DialogContent dividers>
                    <div style={{padding:'20px'}}>
                        <h3 style={{margin:0, display:'inline-block',lineHeight: '50px',fontSize:'42px'}}>{prods.product_name}</h3>
                        <h3 style={{margin:0, display:'inline-block',float:'right',fontWeight:'300', lineHeight: '50px',fontSize:'42px'}}>${prods.product_price}</h3>
                    </div> 
                      
                    </DialogContent>
                    <DialogActions>
                    <div name="orders" id="orders" className="orders"
                    style={{height: "100px", width: "366px", textAlign: "center",borderRadius:'10px',borderColor:'#5BBA44',borderWidth:'3px'}}> 
                    <h1 className="text" style={{marginTop: "5px",fontSize:'42px', lineHeight:2}}>{this.state.itemqty}</h1>
                    </div>
                    <div className="button" style={{display: "grid", marginLeft: "20px"}}>
                      <button className="button2" onClick={()=>this.addItem(prods.product_stock_qty)} style={{margin: "10px", marginBottom: 0,height: '58px', width: "200px", fontSize:'42px'}}>+</button>
                      <button className="button2" onClick={()=>this.removeItem()} style={{margin: "10px", height: '58px', width: "200px", fontSize:'42px'}}>-</button>
                    </div>
                    <div style={{width: "366px", textAlign:"center", marginTop: "10px", marginLeft:'20px'}}>
                    <Link to='/products'>
                      <button className="button2" onClick={()=>this.addToOrder(prods.product_img_url, prods.product_id, prods.product_name, prods.product_price)} style={{width: "366px", height: "100px", fontSize:'32px', marginTop:'10px', fontWeight:'bold'}}>ADD TO ORDER</button>
                    </Link>
                    </div>
                    </DialogActions>
                  </Dialog>
                  </Link>
                  </>:
                  <Link to='/orders' style={{textDecoration: 'none'}}>
                    <Card sx={{ maxWidth: 370 }} style={{marginTop:'25px',position:'relative',height:'400px', borderRadius:'10px'}} onClick={(e)=>this.redirectToDetail(prods.product_img_url,prods.product_stock_qty,prods.product_id,prods.product_name,prods.product_price,prods.add_ons, prods.product_preference,prods.hpb_health_choice.hpb_cat_id, prods.product_min_add_on_qty,prods.product_discount_desc,prods.product_discount_percent,prods.product_discount_value, prods.product_voucher_code)}>
                      {prods.product_img_url !== null && prods.product_img_url !== "" && prods.product_img_url.includes('http')?
                        <div key={index}>
                          {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleLow} badgeContent={prods.product_stock_status} />}
                          {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleOut} badgeContent={prods.product_stock_status} />}
                          <CardMedia
                              component="img"
                              height="250"
                              image={prods.product_img_url}
                              alt="burger"
                            />
                        </div>
                          :
                          <div key={index}>
                          {prods.product_stock_status=== "Low Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleLow} badgeContent={prods.product_stock_status} />}
                          {prods.product_stock_status=== "Out of Stock" && <Badge style={{position: "absolute",right: "70px",top: "30px"}} sx={badgeStyleOut} badgeContent={prods.product_stock_status} />}                  <CardMedia
                            component="img"
                            height="250"
                            image="https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_21/2870431/190524-classic-american-cheeseburger-ew-207p.jpg"
                            alt="burger"
                          />
                          </div>
                          
                      }
                    <CardContent style={{display: "inline-flex"}}>
                      <div style={{width: "180px"}}>
                      <Typography gutterBottom variant="p" component="span">
                        {prods.product_name}
                      </Typography>
                     
                      </div>
                        <div style={{ width: "150px", textAlign: 'end' }}>
                          {prods.product_discount_percent > 0 && prods.product_discount_value > 0 ? <>{(prods.product_price * ((100 - prods.product_discount_percent)/100)) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', color:'red'}}>
                          ${(prods.product_price * ((100 - prods.product_discount_percent)/100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', textDecoration:'line-through'}}>
                          ${prods.product_price.toFixed(2)} </Typography> <br/>
                        <p style={{textAlign:'end',fontSize:'12px', fontWeight:'600', margin:0}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p></>: <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography></> } </> : <></>}
                          
                          { prods.product_discount_percent > 0 && (prods.product_discount_value=== 0 || prods.product_discount_value=== null) ? <>{(prods.product_price * ((100 - prods.product_discount_percent)/100)) > 0 ?<><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', color:'red'}}>
                          ${(prods.product_price * ((100 - prods.product_discount_percent)/100)).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', textDecoration:'line-through'}}>
                          ${prods.product_price.toFixed(2)} </Typography> <br/>
                        <p style={{textAlign:'end',fontSize:'12px', fontWeight:'600', margin:0}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p></>: <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography></> } </> : <></>}
                                  
                          { prods.product_discount_value > 0 && (prods.product_discount_percent=== 0 || prods.product_discount_percent=== null) ? <>{(prods.product_price - prods.product_discount_value) > 0 ? <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', color:'red'}}>
                          ${(prods.product_price - prods.product_discount_value).toFixed(2)} </Typography> <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px', textDecoration:'line-through'}}>
                          ${prods.product_price.toFixed(2)} </Typography>  <br/>
                        <p style={{textAlign:'end',fontSize:'12px', fontWeight:'600', margin:0}}>
                        {prods.product_discount_desc !== 'null' ? prods.product_discount_desc : ''  } </p></>: <><Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography></> } </> : <></>}
                          
                          { (prods.product_discount_percent=== null || prods.product_discount_percent=== 0) && (prods.product_discount_value=== null || prods.product_discount_value=== 0) && <Typography gutterBottom variant="h6" component="span" style={{textAlign:'end',fontSize:'18px'}}>
                          ${prods.product_price.toFixed(2)} </Typography>}
                        
                        {prods.calorie.calorie_count=== -1 || prods.hpb_health_choice.hpb_cat_id !== 0 ? <Badge style={{display: 'inline',width: '88%',position: 'absolute',right: '25px',bottom: '18px'}} max={1000} sx={{ "& .MuiBadge-badge": {
                            color: 'white',
                            backgroundColor: 'white',
                            width:'99px',
                            fontSize:'16px',
                            padding:'15px',
                            borderRadius:'15px'
                          }
                        }} badgeContent={""} /> : <Badge style={{position: "absolute",right: "70px",bottom: "30px"}} max={1000} sx={{ "& .MuiBadge-badge": {
                        color: 'white',
                        backgroundColor: prods.calorie.calorie_color_sys_code,
                        width:'99px',
                        fontSize:'16px',
                        padding:'15px',
                        borderRadius:'15px'
                      } }} badgeContent={prods.calorie.calorie_count+" kcals"} />}
                                </div>
                               
                              </CardContent>
                             
                    </Card>
                  </Link>
                  }
                      </>}
                    </div>
                </div>
                
              ))}
              
          </Box>:<> <img height="fit-content" style={{margin:'auto', padding:'30px', display:'block'}}src={loading}/></>}
          
        </Box>
          <CheckoutDetails />
          {this.state.errorMsg != "" ? <ErrorAlert errorMsgDisplay={{
            title: this.state.errorMsg,
            body: "Product Layout " + this.state.functionIndex,
          }}/>:<></>}
        </div>
      );
    }
  }
}