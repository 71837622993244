import styles from '../../../styles/styles.module.css';

const UpdateOrderStatus = () => {
    if (window.innerWidth < 481) {
        return (
            <a name="update-order-status" style={{width:'inherit'}}>
            <div style={{ maxWidth: '100%'}}> 
            
            {/* Details component */}
            <div style={{gridArea: 'details', marginBottom: '0px'}}>
                <div className={styles.cardbod}>
                    <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#72b84c'}}>Update Order Status</h3>
                    <hr />
                    <p style={{marginTop: '10px'}}> This API updates the transactions once the order has updated from PEF. </p>
    
                    {/* API Parameters */}
                    <div style={{marginTop: '20px'}}>
                        <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a. order_id : consumer's order ID</p>
                        </div>
    
                        <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a. order_status:</p>
                            <span style={{color: '#72b84c', marginLeft: '15px'}}> 
                            0 : pending <br/> 
                            </span>
                            <span style={{color: '#72b84c', marginLeft: '15px'}}> 
                            1 : preparing <br/> 
                            </span>
                            <span style={{color: '#72b84c', marginLeft: '15px'}}> 
                            2 : ready-for-collection <br/> 
                            </span>
                            <span style={{color: '#72b84c', marginLeft: '15px'}}> 
                            3 : completed <br/> 
                            </span>
                            <span style={{color: '#72b84c', marginLeft: '15px'}}> 
                            4 : cancelled <br/> 
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            {/* Code component */}
            <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
                <div style={{backgroundColor: '#72b84c', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                    Update Order Status API
                </div>
    
                <div>
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Request (PUT)</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                            <code className={styles.code}>/api/v1/transactions/orders/:order_id/status</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Example</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                            <code className={styles.code}>/api/v1/transactions/orders/80/status</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Header</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            {/* <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>dy1UG9zWBfLRVNTQtWQr</span> */}
                                        <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>{'{apikey}' }</span>
                            <br />
                            <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response (Mobile)</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                            <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <div style={{marginLeft: '20px'}}>
                                <span className={styles.codestyle2}>"consumer_order_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 80<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2}>"order_status"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 1</span>
                                <br />
                            </div>
                            <span className={styles.codestyle1}>{"}"}</span>
                            </code>
                        </blockquote>   
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response (PEF)</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                            <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <div style={{marginLeft: '20px'}}>
                                <span className={styles.codestyle2}>"consumer_order_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 80<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2}>"title"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Order No #123 Updated"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2}>"body"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Collection No: 123 ready"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2}>"order_status"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 2</span>
                                <br />
                            </div>
                            <span className={styles.codestyle1}>{"}"}</span>
                            </code>
                        </blockquote>   
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                            <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            
                            <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '20px'}}>"message"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "success"</span>
                            <br />
                            <span className={styles.codestyle1}style={{marginLeft: '20px'}}>{"}"}</span>
                            <br/>
                            <span className={styles.codestyle1}>{"}"}</span>
                            </code>
                        </blockquote>   
                    </div>
    
                </div>
            </div>
            </div>
        </a>
        );
    } else {
        return (
            <a name="update-order-status">
            <div style={{display: 'grid', 'gridTemplateAreas':`'details details code`,'gridTemplateColumns': 'repeat(3, minmax(0, 1fr))', gap:3, maxWidth: 'inherit'}}> 
            
            {/* Details component */}
            <div style={{gridArea: 'details', marginBottom: '0px'}}>
                <div className={styles.cardbod}>
                    <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#72b84c'}}>Update Order Status</h3>
                    <hr />
                    <p style={{marginTop: '10px'}}> This API updates the transactions once the order has updated from PEF. </p>
    
                    {/* API Parameters */}
                    <div style={{marginTop: '20px'}}>
                        <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a. order_id : consumer's order ID</p>
                        </div>
    
                        <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a. order_status:</p>
                            <span style={{color: '#72b84c', marginLeft: '15px'}}> 
                            0 : pending <br/> 
                            </span>
                            <span style={{color: '#72b84c', marginLeft: '15px'}}> 
                            1 : preparing <br/> 
                            </span>
                            <span style={{color: '#72b84c', marginLeft: '15px'}}> 
                            2 : ready-for-collection <br/> 
                            </span>
                            <span style={{color: '#72b84c', marginLeft: '15px'}}> 
                            3 : completed <br/> 
                            </span>
                            <span style={{color: '#72b84c', marginLeft: '15px'}}> 
                            4 : cancelled <br/> 
                            </span>
                        </div>
                    </div>
                </div>
            </div>
    
            {/* Code component */}
            <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
                <div style={{backgroundColor: '#72b84c', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                    Update Order Status API
                </div>
    
                <div>
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Request (PUT)</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                            <code className={styles.code}>/api/v1/transactions/orders/:order_id/status</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Example</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                            <code className={styles.code}>/api/v1/transactions/orders/80/status</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Header</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            {/* <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>dy1UG9zWBfLRVNTQtWQr</span> */}
                                        <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>{'{apikey}' }</span>
                            <br />
                            <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response (Mobile)</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                            <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <div style={{marginLeft: '20px'}}>
                                <span className={styles.codestyle2}>"consumer_order_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 80<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2}>"order_status"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 1</span>
                                <br />
                            </div>
                            <span className={styles.codestyle1}>{"}"}</span>
                            </code>
                        </blockquote>   
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response (PEF)</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                            <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <div style={{marginLeft: '20px'}}>
                                <span className={styles.codestyle2}>"consumer_order_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 80<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2}>"title"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Order No #123 Updated"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2}>"body"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Collection No: 123 ready"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2}>"order_status"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 2</span>
                                <br />
                            </div>
                            <span className={styles.codestyle1}>{"}"}</span>
                            </code>
                        </blockquote>   
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                            <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            
                            <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '20px'}}>"message"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "success"</span>
                            <br />
                            <span className={styles.codestyle1}style={{marginLeft: '20px'}}>{"}"}</span>
                            <br/>
                            <span className={styles.codestyle1}>{"}"}</span>
                            </code>
                        </blockquote>   
                    </div>
    
                </div>
            </div>
            </div>
        </a>
        );
   }
  };
  
  export default UpdateOrderStatus;
