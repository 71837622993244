import { colors } from "@mui/material";

const LayoutConfig = {
    sidebar: {
        bg: "#face8e",
        color: "#00000",
        hoverBg: "#f5a633",
        activeBg: "#f5a633",
        width: "300px"
    },
    mainBg: colors.grey["100"],
    topbar: {
        bg: "#fff",
        color: "#000"
    },
};

export default LayoutConfig;