import React, { useState, useContext } from "react";
import { Box, Toolbar,Collapse } from "@mui/material";
import layoutConfig from "../styles/layoutConfig";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar";
import SidebarMobile from "../components/sidebarMobile";
import Topbar from "../components/topbar";
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import SidebarItem from "../components/sidebarItem";
import { Link } from 'react-router-dom'
import { HambergerMenu } from "iconsax-react";
import {
  CurrentNavigationRouteContext,
  NavigationRoutesContext,
} from "../components/contexts/navigationContext";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ArrowDown2, ArrowUp2 } from "iconsax-react";

const MainLayout = () => {
  const { navigationRoutes } = useContext(NavigationRoutesContext);
  const { currentRoute, setCurrentRoute } = useContext(
    CurrentNavigationRouteContext
  );

  console.log(navigationRoutes);
  if (window.innerWidth < 481) {
    return (
      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{
            width: layoutConfig.sidebar.width,
            flexShrink: 0
          }}
        >
          <SidebarMobile/>
        </Box>
        
      </Box>
    )
  } else {
    return (
      <Box sx={{ display: "flex" }}>
        <Topbar />
        <Box
          component="nav"
          sx={{
            width: layoutConfig.sidebar.width,
            flexShrink: 0
          }}
        >
          <Sidebar />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 2,
            width: `calc(100% - ${layoutConfig.sidebar.width})`,
            minHeight: "100vh",
            backgroundColor: layoutConfig.mainBg
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    );
  }
  };
  
  export default MainLayout;