import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
    Link,
  } from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Otp(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  // //console.log( document.querySelector('#wMobile'));
  // if (props.mobile == "") {
  //   document.querySelector('#wMobile').disabled = true;
  // }
  if (window.innerWidth < 481) {
    return (
      <div> 
        { props.mobile ? <Button id="wMobile" variant="contained" style={{ backgroundColor: "#5BBA44", borderRadius: '10px', fontSize: '12px', height: '30px', marginTop: '10px'}} sx={{ width: 300 }} onClick={handleOpen}>PROCEED TO PAYMENT WITH MOBILE</Button> : <Button id="wMobile" variant="contained" style={{ backgroundColor: "#5BBA44", borderRadius: '10px', fontSize: '12px', height: '30px', marginTop: '10px'}} sx={{ width: 300 }} disabled onClick={handleOpen}>PROCEED TO PAYMENT WITH MOBILE</Button>}
        
        {/* <Button id="woMobile" variant="contained" style={{ backgroundColor: "#24a0ed", borderRadius: '10px', fontSize: '28px', height: '85px', marginTop: '20px'}} sx={{width:  880}} onClick={handleOpen2}>PROCEED TO PAYMENT WITHOUT MOBILE</Button> */}
  
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{textAlign: "center", borderRadius:'20px',width:'90%',border:0}}>
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '18px',fontWeight: 'bold',letterSpacing: 0.16,marginTop: '20px'
      }}>
              +65 - {props.mobile}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} className="text" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '14px',color:'#6D7278',fontWeight: 'bold',letterSpacing: 0.16,lineHeight:'normal', marginBottom:'30px'
      }}>
              Confirm that the number entered is correct
            </Typography>
            <div className="button" style={{display: "inline-flex"}}>
            <Link to='/payments'>
                <button className="button5Web" style={{marginBottom: "10px", width: "80px", height: '30px', fontSize:'12px'}} onClick={()=>localStorage.setItem('mobile',props.mobile)}>YES</button>
            </Link>
            {/* <Link to='/checkout/contact'> */}
                <button className="button5Web" style={{marginBottom: "10px", width: "80px", marginLeft: "20px" , height: '30px', color:'#5BBA44',background:'white', fontSize:'12px'}} onClick={handleClose}>BACK</button>
            {/* </Link> */}
            </div>
          </Box>
        </Modal>
  
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title2"
          aria-describedby="modal-modal-description2"
        >
          <Box sx={style} style={{textAlign: "center", borderRadius:'20px',width:'90%',border:0}}>
            <Typography id="modal-modal-title2" variant="h6" component="h2" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '52px',fontWeight: 'bold',letterSpacing: 0.16,marginTop: '30px'
      }}>
             Are you sure?
            </Typography>
            <Typography id="modal-modal-description2" sx={{ mt: 2 }} className="text" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '42px',color:'#6D7278',fontWeight: 'bold',letterSpacing: 0.16,lineHeight:'normal', marginBottom:'80px'
      }}>
              You will not receive your order number and order status if you continue.
              {/* If proceed, snap a picture of order number at the end of payment. Thank you! */}
            </Typography>
            <Typography id="modal-modal-description2" sx={{ mt: 2 }} className="text" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '42px',color:'#6D7278',fontWeight: 'bold',letterSpacing: 0.16,lineHeight:'normal', marginBottom:'80px'
      }}>
              {/* You will not receive your order number and order status if you continue. */}
              If proceed, show bank transaction to merchant. Thank you!
            </Typography>
            <div className="button" style={{ display: "inline-flex" }}>
              {/* <Link to='/checkout/contact'> */}
              <button className="button5" style={{marginBottom: "10px", width: "324px" , height: '65px', color:'#5BBA44',background:'white'}} onClick={handleClose2}>BACK</button>
            {/* </Link> */}
            <Link to='/payment'>
                <button className="button5" style={{marginBottom: "10px", width: "324px", marginLeft: "20px", height: '65px'}} onClick={()=>localStorage.setItem('mobile',props.mobile)}>PROCEED</button>
            </Link>
            
            </div>
          </Box>
        </Modal>
      </div>
    );
  } else {
    return (
      <div> 
        { props.mobile ? <Button id="wMobile" variant="contained" style={{ backgroundColor: "#5BBA44", borderRadius: '10px', fontSize: '18px', height: '50px', marginTop: '20px'}} sx={{ width: 500 }} onClick={handleOpen}>PROCEED TO PAYMENT WITH MOBILE</Button> : <Button id="wMobile" variant="contained" style={{ backgroundColor: "#5BBA44", borderRadius: '10px', fontSize: '18px', height: '50px', marginTop: '20px'}} sx={{ width: 500 }} disabled onClick={handleOpen}>PROCEED TO PAYMENT WITH MOBILE</Button>}
        
        {/* <Button id="woMobile" variant="contained" style={{ backgroundColor: "#24a0ed", borderRadius: '10px', fontSize: '28px', height: '85px', marginTop: '20px'}} sx={{width:  880}} onClick={handleOpen2}>PROCEED TO PAYMENT WITHOUT MOBILE</Button> */}
  
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{textAlign: "center", borderRadius:'20px',width:'50%',border:0}}>
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '24px',fontWeight: 'bold',letterSpacing: 0.16,marginTop: '30px'
      }}>
              +65 - {props.mobile}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} className="text" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '20px',color:'#6D7278',fontWeight: 'bold',letterSpacing: 0.16,lineHeight:'normal', marginBottom:'30px'
      }}>
              Confirm that the number entered is correct
            </Typography>
            <div className="button" style={{display: "inline-flex"}}>
            <Link to='/payments'>
                <button className="button5Web" style={{marginBottom: "10px", width: "200px", height: '50px', fontSize:'18px'}} onClick={()=>localStorage.setItem('mobile',props.mobile)}>YES</button>
            </Link>
            {/* <Link to='/checkout/contact'> */}
                <button className="button5Web" style={{marginBottom: "10px", width: "200px", marginLeft: "20px" , height: '50px', color:'#5BBA44',background:'white', fontSize:'18px'}} onClick={handleClose}>BACK</button>
            {/* </Link> */}
            </div>
          </Box>
        </Modal>
  
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title2"
          aria-describedby="modal-modal-description2"
        >
          <Box sx={style} style={{textAlign: "center", borderRadius:'20px',width:'90%',border:0}}>
            <Typography id="modal-modal-title2" variant="h6" component="h2" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '52px',fontWeight: 'bold',letterSpacing: 0.16,marginTop: '30px'
      }}>
             Are you sure?
            </Typography>
            <Typography id="modal-modal-description2" sx={{ mt: 2 }} className="text" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '42px',color:'#6D7278',fontWeight: 'bold',letterSpacing: 0.16,lineHeight:'normal', marginBottom:'80px'
      }}>
              You will not receive your order number and order status if you continue.
              {/* If proceed, snap a picture of order number at the end of payment. Thank you! */}
            </Typography>
            <Typography id="modal-modal-description2" sx={{ mt: 2 }} className="text" style={{fontFamily: 'Arial, Helvetica, sans-serif',fontSize: '42px',color:'#6D7278',fontWeight: 'bold',letterSpacing: 0.16,lineHeight:'normal', marginBottom:'80px'
      }}>
              {/* You will not receive your order number and order status if you continue. */}
              If proceed, show bank transaction to merchant. Thank you!
            </Typography>
            <div className="button" style={{ display: "inline-flex" }}>
              {/* <Link to='/checkout/contact'> */}
              <button className="button5" style={{marginBottom: "10px", width: "324px" , height: '65px', color:'#5BBA44',background:'white'}} onClick={handleClose2}>BACK</button>
            {/* </Link> */}
            <Link to='/payment'>
                <button className="button5" style={{marginBottom: "10px", width: "324px", marginLeft: "20px", height: '65px'}} onClick={()=>localStorage.setItem('mobile',props.mobile)}>PROCEED</button>
            </Link>
            
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}