let commonUrl = `${process.env.REACT_APP_BEVEAT_URL}`;
let pefUrl = `${process.env.REACT_APP_PAYEATFWD_URL}`;

const commonConfigs = {
  accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
  redirectUrl: "http://localhost:3000/portal/signin",
  apiHeader: {
    'api-key': `${process.env.REACT_APP_API_KEY}`,
    'project-id': `${process.env.REACT_APP_PROJECT_ID}`,
    'platform-syscode': `${process.env.REACT_APP_ECOMMERCE_WEBSITE_PLATFORM_SYSCODE}`
  },
 
  apiUrls: {
    //Request QR
    // RequestQr: (projectname) => `${commonUrl}/v1/hub/${projectname}/payments/nets-qr/request`,
    // QueryQr: (projectname) => `${commonUrl}/v1/hub/${projectname}/payments/nets-qr/query`,
    // ReversalQr: (projectname) => `${commonUrl}/v1/hub/${projectname}/payments/nets-qr/reversal`,
    requestNetsApi: () => `${commonUrl}/v1/common/payments/nets-qr/request`,
    queryNetsApi: () => `${commonUrl}/v1/common/payments/nets-qr/query`,
    ReversalQr: () => `${commonUrl}/v1/common/payments/nets-qr/reversal`,
    //Get Stalls
    // GetStalls: (projectname, orgid, loccode) => `${commonUrl}/v1/hub/${projectname}/org/${orgid}/locations/outlets/merchants?location_code=${loccode}&cluster=`,
    GetStalls: (orgid, loccode,merchanttype) => `${commonUrl}/v1/common/org/${orgid}/locations/outlets/merchants?location_code=${loccode}&cluster=&merchant_type=${merchanttype}`,
    //Get HPB Categories
    GetHPBCategories: () => `${commonUrl}/v1/common/hpb/categories`,
    //Get Menu
    GetMenuProducts: (orgid,merchantid,pef) => `${commonUrl}/v1/common/org/${orgid}/merchants/${merchantid}/products?platform_type_sys_code=${process.env.REACT_APP_ECOMMERCE_WEBSITE_PLATFORM_SYSCODE}&payeatfwd=${pef}`,
    //Checkout  
    CreateOrder: (projectname) =>
    `${commonUrl}/v1/hub/${projectname}/transactions/orders`,
    // View Past Orders
    GetOutlets: (orgid, outlettype) =>
      `${commonUrl}/v1/common/org/${orgid}/locations/outlets?outlet_type=${outlettype}`,
    GetTxnHistory: (mobile, page, projectname) =>
      `${commonUrl}/v1/hub/${projectname}/transactions/consumers/${mobile}/orders?page=${page}`,
    SendBrickMortar: () =>
      `${commonUrl}/v1/common/hpb/rdcs/brick-mortar`,
    GetVouchers: (projectname, orgid, vouchercode,orderval, accountid) =>
      `${commonUrl}/v1/hub/${projectname}/vouchers?org_id=${orgid}&platform_type_sys_code=${process.env.REACT_APP_ECOMMERCE_WEBSITE_PLATFORM_SYSCODE}&voucher_code=${vouchercode}&lat=&lng=&consumer_id=${accountid}&order_val=${orderval}`,
    Webhook: (retrieval_ref) =>
      // `${commonUrl}/v1/common/payments/nets-qr/webhook?retrieval_ref=${retrieval_ref}`
    `${commonUrl}/v1/common/payments/nets/webhook?retrieval_ref=${retrieval_ref}`
  },
};
// const configs = Object.assign(commonConfigs, envConfigs);
export default commonConfigs;
