import styles from '../../../styles/styles.module.css';

const GetLineGraphs = () => {
    if (window.innerWidth < 481) {
        return (
            <div style={{maxWidth: '100%'}}> 
            
            {/* Details component */}
            <div style={{gridArea: 'details', marginBottom: '0px'}}>
                <div className={styles.cardbod}>
                    <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#72b84c'}}>Get by Line Graphs</h3>
                    <hr />
                    <p style={{marginTop: '10px'}}> This API retrieves the nutrition data in the form of a line graph. </p>
    
                    {/* API Parameters */}
                    <div style={{marginTop: '20px'}}>
                        <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a.SSO ID</p>
                            <p>b. date filter option : 1-d, 1-m, 1-y</p>
                        </div>
    
                        <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a. high values</p>
                            <p>b. mid values</p>
                            <p>c. low values</p>
                            <p>d. average values</p>
                            <p>e. label values</p>
                        </div>
                    </div>
                </div>
            </div>
    
            {/* Code component */}
            <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
                <div style={{backgroundColor: '#72b84c', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                    Get by Line Graphs API
                </div>
    
                <div>
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                            <code className={styles.code}>/api/v1/common/health/individual-consumption-trends/line-charts/calorie-counts?sso_id=&date_filter=</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'10px', color: 'white'}}>Example</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                            <code className={styles.code}>api/v1/common/health/individual-consumption-trends/line-charts/calorie-counts?sso_id={'{sso_id}'}&date_filter=1-m</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'10px', color: 'white'}}>Header</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>{'{apikey}'}</span>
                            <br />
                            <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                            <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            
                            <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2}>"data"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
    
                            <div style={{marginLeft: '15px'}}>
                            {/* start of data */}   
                            <span className={styles.codestyle2}>"high"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                            <div style={{marginLeft: '35px'}}>
                                <span className={styles.codestyle3}> null<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 2299<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 4221</span>
                                <br />
                                </div>
    
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
    
                            <span className={styles.codestyle2}>"mid"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                            <div style={{marginLeft: '35px'}}>
                                <span className={styles.codestyle5}> 7459<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 8414<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 675</span>
                                <br />
                                </div>
    
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
    
                            <span className={styles.codestyle2}>"low"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                            <div style={{marginLeft: '35px'}}>
                                <span className={styles.codestyle5}> 6058<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 3863<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 965<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 494</span>
                                <br />
                                </div>
    
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
    
                            <span className={styles.codestyle2}>"avg"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                            <div style={{marginLeft: '35px'}}>
                                <span className={styles.codestyle5}> 13512<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 14531<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 5842<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 486</span>
                                <br />
                                </div>
    
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
    
                            <span className={styles.codestyle2}>"label"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                            <div style={{marginLeft: '35px'}}>
                                <span className={styles.codestyle4}> "Feb 2023"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle4}> "Mar 2023"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle4}> "Apr 2023"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle4}> "May 2023"</span>
                                <br />
                                </div>
    
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span>
                            <br/>
                            </div>
    
                            {/* ending brackets */}
                            <span className={styles.codestyle1} style={{marginLeft: '20px'}}>{"}"}</span>
                            <br/>
                            <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"}"}</span>
                            <br/>
                            <span className={styles.codestyle1}>{"}"}</span>
                            </code>
                        </blockquote>   
                    </div>
    
                </div>
            </div>
            </div>
        );
    } else {
        return (
            <div style={{display: 'grid', 'gridTemplateAreas':`'details details code`,'gridTemplateColumns': 'repeat(3, minmax(0, 1fr))', gap:3, maxWidth: 'inherit'}}> 
            
            {/* Details component */}
            <div style={{gridArea: 'details', marginBottom: '0px'}}>
                <div className={styles.cardbod}>
                    <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#72b84c'}}>Get by Line Graphs</h3>
                    <hr />
                    <p style={{marginTop: '10px'}}> This API retrieves the nutrition data in the form of a line graph. </p>
    
                    {/* API Parameters */}
                    <div style={{marginTop: '20px'}}>
                        <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a.SSO ID</p>
                            <p>b. date filter option : 1-d, 1-m, 1-y</p>
                        </div>
    
                        <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a. high values</p>
                            <p>b. mid values</p>
                            <p>c. low values</p>
                            <p>d. average values</p>
                            <p>e. label values</p>
                        </div>
                    </div>
                </div>
            </div>
    
            {/* Code component */}
            <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
                <div style={{backgroundColor: '#72b84c', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                    Get by Line Graphs API
                </div>
    
                <div>
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                            <code className={styles.code}>/api/v1/common/health/individual-consumption-trends/line-charts/calorie-counts?sso_id=&date_filter=</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'10px', color: 'white'}}>Example</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                            <code className={styles.code}>api/v1/common/health/individual-consumption-trends/line-charts/calorie-counts?sso_id={'{sso_id}'}&date_filter=1-m</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'10px', color: 'white'}}>Header</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>{'{apikey}'}</span>
                            <br />
                            <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                            <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            
                            <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2}>"data"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
    
                            <div style={{marginLeft: '15px'}}>
                            {/* start of data */}   
                            <span className={styles.codestyle2}>"high"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                            <div style={{marginLeft: '35px'}}>
                                <span className={styles.codestyle3}> null<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 2299<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 4221</span>
                                <br />
                                </div>
    
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
    
                            <span className={styles.codestyle2}>"mid"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                            <div style={{marginLeft: '35px'}}>
                                <span className={styles.codestyle5}> 7459<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 8414<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 675</span>
                                <br />
                                </div>
    
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
    
                            <span className={styles.codestyle2}>"low"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                            <div style={{marginLeft: '35px'}}>
                                <span className={styles.codestyle5}> 6058<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 3863<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 965<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 494</span>
                                <br />
                                </div>
    
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
    
                            <span className={styles.codestyle2}>"avg"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                            <div style={{marginLeft: '35px'}}>
                                <span className={styles.codestyle5}> 13512<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 14531<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 5842<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle5}> 486</span>
                                <br />
                                </div>
    
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
    
                            <span className={styles.codestyle2}>"label"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                            <div style={{marginLeft: '35px'}}>
                                <span className={styles.codestyle4}> "Feb 2023"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle4}> "Mar 2023"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle4}> "Apr 2023"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle4}> "May 2023"</span>
                                <br />
                                </div>
    
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span>
                            <br/>
                            </div>
    
                            {/* ending brackets */}
                            <span className={styles.codestyle1} style={{marginLeft: '20px'}}>{"}"}</span>
                            <br/>
                            <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"}"}</span>
                            <br/>
                            <span className={styles.codestyle1}>{"}"}</span>
                            </code>
                        </blockquote>   
                    </div>
    
                </div>
            </div>
            </div>
        );
    }
  };
  
  export default GetLineGraphs;
