import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import dish from '../../../assets/dish.jpeg'
import {
    Link,
  } from "react-router-dom";
import { ThirtyFpsSelect } from "@mui/icons-material";
import { Divider } from "@mui/material";
 
class OrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayorders: this.props.displaydata,
            orders: this.props.data,
            
        }
    }
    
    removeItem(order) {
        var newOrders = [];
        // var this.state.orders = JSON.parse(localStorage.getItem('orders'));
        var newSessionOrders = [];

        //console.log(order.product_id);

        if (this.state.displayorders.includes(order)) {
            var index = this.state.displayorders.indexOf(order);
            // var indexOrder = this.state.orders.indexOf(order.product_id);
            console.log(index);
            if (index > -1) { 
                this.state.displayorders.splice(index, 1); 
                this.state.orders.splice(index, 1); 
                for (var i = 0; i < this.state.displayorders.length;i++){
                    newOrders.push(this.state.displayorders[i]);
                }
                for (var x = 0; x < this.state.orders.length;x++){
                    newSessionOrders.push(this.state.orders[x]);
                }
            }
        }

        // if (this.state.orders.includes(order)) {
            // var indexOrder = this.state.displayorders.indexOf(order);
            // var indexOrder = this.state.orders.indexOf(order.product_id);
            // console.log(indexOrder);
            // if (index > -1) { 
                // this.state.orders.splice(indexOrder, 1); 
                // this.state.orders.splice(indexOrder, 1); 
                // for (var x = 0; x < this.state.orders.length;x++){
                    // newSessionOrders.push(this.state.orders[x]);
                // }
                // for (var i = 0; i < this.state.orders.length;i++){
                //     newSessionOrders.push(this.state.orders[i]);
                // }
            // }
        // }
        
        console.log(newOrders);
        console.log(newSessionOrders);
        this.setState({
            displayorders: newOrders,
            orders: newSessionOrders
        });
        localStorage.setItem('ordersdisplay',JSON.stringify(newOrders));
        localStorage.setItem('orders', JSON.stringify(newSessionOrders));
    
        this.props.onUpdate(JSON.stringify(newOrders));
        
        
    }
  render() {
    //   console.log(this.state.orders);
      if (window.innerWidth < 481) {
        return (
            <>
                {this.state.displayorders.map((ord, index) => (
                    <>
                        <div key={index} className="order" style={{margin:'auto',width:'95%', paddingBottom:0}}>
                            <Grid container spacing={0.5}>
                                <Grid item xs={4}>
                                    <img src={ord.product_imgurl} width={100} style={{borderRadius:'10px'}}/>
                                
                                </Grid>
                                <Grid item xs={6}>
                                    <h2 className="orderDetailsWeb" style={{ fontSize:'12px',marginBottom: '20px' }}>
                                    {ord.quantity}x {ord.product_name}
                                    </h2>
                                    {/* <p>
                                        Add On:  {ord.addons.length}
                                    </p> */}
                                    {ord.add_ons && ord.add_ons.length > 0 && 
                                    <p key={index} className="orderDetailSubWeb" style={{fontSize:'12px'}}>
                                        Add On:
                                        </p>
                                    }
                                    {ord.add_ons && ord.add_ons.length > 0 && ord.add_ons.map((addon, index) => (
                                        <>
                                            {addon.quantity != 0 ? <p key={index} className="orderDetailSubWeb" style={{fontSize:'12px'}}>
                                                {addon.quantity}x  {addon.product_name}
                                            </p> : <></>}
                                        </>
                                        
                                        
                                    ))}
    
                                
                                {ord.order_pref ?  <p className="orderDetailSubWeb" style={{fontSize:'12px'}}>
                                        Preference: {ord.order_pref}
                                    </p>: <></>}
                                    {/* {ord.add_ons && ord.add_ons.length > 0 ? 
                                        <p className="orderDetailSubWeb">
                                            Sub Total:
                                        </p> :
                                        <p className="orderDetailSubWeb">
                                            
                                        </p>
                                    } */}
                                    
                                </Grid>
                                <Grid item xs={2} className="pricing" >
                                {/* ,display:'inline-flex', position:'absolute', top:0, right:0 */}
                                    <p className="orderDetailsWeb" style={{ marginBottom: '20px', fontSize: '12px' }}>
                                        {parseInt(ord.product_discount_percent) > 0 && parseFloat(ord.product_discount_value) > 0 ? <>{(parseFloat(ord.product_price) * ((100 - parseInt(ord.product_discount_percent))/100)) > 0 ? <><p className="orderDetailsWeb" style={{ color:'red',fontSize:'12px'}}>
                                            ${(parseFloat(ord.product_price) * ((100 - parseInt(ord.product_discount_percent))/100)).toFixed(2)} </p> <p className="orderDetailsWeb" style={{  textDecoration:'line-through',fontSize:'12px'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p> </> : <><p className="orderDetailsWeb" style={{fontSize:'12px'}}>
                                            ${parseFloat(ord.product_price).toFixed(2)} </p></>} </> : <></>}
                                        

                                    {  parseInt(ord.product_discount_percent) > 0 && (parseFloat(ord.product_discount_value) == null || parseFloat(ord.product_discount_value) == 0) ? <>{(parseFloat(ord.product_price) * ((100 - parseInt(ord.product_discount_percent))/100)) > 0 ? <><p className="orderDetailsWeb" style={{ color:'red',fontSize:'12px'}}>
                                            ${(parseFloat(ord.product_price) * ((100 - parseInt(ord.product_discount_percent))/100)).toFixed(2)} </p> <p className="orderDetailsWeb" style={{  textDecoration:'line-through',fontSize:'12px'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p> </>  : <><p className="orderDetailsWeb" style={{fontSize:'12px'}}>
                                            ${parseFloat(ord.product_price).toFixed(2)} </p></>} </> : <></>}
                                    
                                            { parseFloat(ord.product_discount_value) > 0 && (parseFloat(ord.product_discount_percent) == null || parseFloat(ord.product_discount_percent) == 0) ? <>{(parseFloat(ord.product_price) - parseFloat(ord.product_discount_value)) > 0 ? <><p className="orderDetailsWeb" style={{color:'red',fontSize:'12px'}}>
                                            ${(parseFloat(ord.product_price) - parseFloat(ord.product_discount_value)).toFixed(2)} </p> <p className="orderDetailsWeb" style={{ textDecoration:'line-through',fontSize:'12px'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p> </>  : <><p className="orderDetailsWeb" style={{fontSize:'12px'}}>
                                            ${parseFloat(ord.product_price).toFixed(2)} </p></>} </> : <></>}
                                    
                                            { (parseInt(ord.product_discount_percent) == null || parseInt(ord.product_discount_percent) == 0) && (parseFloat(ord.product_discount_value) == null || parseFloat(ord.product_discount_value) == 0) && <p className="orderDetailsWeb" style={{fontSize:'12px'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p>}
                                        {/* ${(parseFloat(ord.product_price).toFixed(2)*parseInt(ord.quantity))} */}
                                    </p>
                                    {ord.add_ons && ord.add_ons.length > 0 && ord.add_ons.map((addon, index) => (
                                        <>
                                            {addon.quantity != 0 ? <p key={index} className="orderDetailSubWeb" style={{fontSize:'12px'}}>
                                                {/* + ${((parseFloat(addon.product_price) * parseFloat(addon.quantity) * parseFloat(ord.quantity))).toFixed(2)} */}
                                            + ${((parseFloat(addon.product_price))).toFixed(2)}
                                                
                                            </p> : <></>}
                                        </>
                                    ))}
                                    
                                
                                </Grid>
                                {/* <Grid container spacing={0.5}>
                                    <Grid item xs={8}>
                                    {ord.remarks ?  <p className="orderDetailSubWeb">
                                        Notes: {ord.remarks}
                                    </p> :  <p className="orderDetailSubWeb">
                                        Notes: -
                                    </p>}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {ord.add_ons && ord.add_ons.length > 0 && ord.add_ons.map((addon,index)=>(
                                            <p key={index} className="totalAmt">
                                                <span style={{color:'#6D7278',fontWeight: 400}}>Sub Total:</span>  ${(parseFloat(addon.product_price) + parseFloat(ord.product_price)).toFixed(2)}
                                            </p>
                                        ))}
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </div>
                        <Grid container spacing={0.5} style={{width:'95%',margin:'auto'}}>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={6}>
                                <button className="button5" onClick={()=>this.removeItem(ord)} style={{ fontSize:'12px',marginBottom: "10px", height: '30px', color: '#C70000', background: 'white', textAlign:'left', paddingLeft:'4px'}}>Remove</button>
                            </Grid>
                            <Grid item xs={2} className="pricing">
                            </Grid>
                        </Grid>
                        <Divider style={{marginBottom: '20px'}}/>
                    </>
                ))}
            </>
            
        );
      } else {
        return (
            <>
                {this.state.displayorders.map((ord, index) => (
                    <>
                        <div key={index} className="order" style={{margin:'auto',width:'70%'}}>
                            <Grid container spacing={0.5}>
                                <Grid item xs={4}>
                                    <img src={ord.product_imgurl} width={250} style={{borderRadius:'10px'}}/>
                                
                                </Grid>
                                <Grid item xs={6}>
                                    <h2 className="orderDetailsWeb" style={{ marginBottom: '50px' }}>
                                    {ord.quantity}x {ord.product_name}
                                    </h2>
                                    {/* <p>
                                        Add On:  {ord.addons.length}
                                    </p> */}
                                    {ord.add_ons && ord.add_ons.length > 0 && 
                                    <p key={index} className="orderDetailSubWeb">
                                        Add On:
                                        </p>
                                    }
                                    {ord.add_ons && ord.add_ons.length > 0 && ord.add_ons.map((addon, index) => (
                                        <>
                                            {addon.quantity != 0 ? <p key={index} className="orderDetailSubWeb">
                                                {addon.quantity}x  {addon.product_name}
                                            </p> : <></>}
                                        </>
                                        
                                        
                                    ))}
    
                                
                                {ord.order_pref ?  <p className="orderDetailSubWeb">
                                        Preference: {ord.order_pref}
                                    </p>: <></>}
                                    {/* {ord.add_ons && ord.add_ons.length > 0 ? 
                                        <p className="orderDetailSubWeb">
                                            Sub Total:
                                        </p> :
                                        <p className="orderDetailSubWeb">
                                            
                                        </p>
                                    } */}
                                    
                                </Grid>
                                <Grid item xs={2} className="pricing" style={{position:'relative'}}>
                                    <p className="orderDetailsWeb" style={{ display:'inline-flex', position:'absolute', top:0, right:0}}>
                                        {/* if got discount, og price diff */}
                                        { parseInt(ord.product_discount_percent) > 0 && parseFloat(ord.product_discount_value) > 0 ? <>{(parseFloat(ord.product_price) * ((100 - parseInt(ord.product_discount_percent))/100)) > 0 ? <><p className="orderDetailsWeb" style={{ margin:'10px',color:'red'}}>
                                            ${(parseFloat(ord.product_price) * ((100 - parseInt(ord.product_discount_percent))/100)).toFixed(2)} </p> <p className="orderDetailsWeb" style={{  margin:'10px',textDecoration:'line-through'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p> </> : <><p className="orderDetailsWeb" style={{margin:'10px'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p></>} </> : <></>}
                                        {  parseInt(ord.product_discount_percent) > 0 && (parseFloat(ord.product_discount_value) == null || parseFloat(ord.product_discount_value) == 0) ? <>{(parseFloat(ord.product_price) * ((100 - parseInt(ord.product_discount_percent))/100)) > 0 ? <><p className="orderDetailsWeb" style={{ margin:'10px',color:'red'}}>
                                            ${(parseFloat(ord.product_price) * ((100 - parseInt(ord.product_discount_percent))/100)).toFixed(2)} </p> <p className="orderDetailsWeb" style={{  margin:'10px',textDecoration:'line-through'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p> </> : <><p className="orderDetailsWeb" style={{margin:'10px'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p></>} </> : <></>}
                                    
                                            { parseFloat(ord.product_discount_value) > 0 && (parseFloat(ord.product_discount_percent) == null || parseFloat(ord.product_discount_percent) == 0) ? <>{(parseFloat(ord.product_price) - parseFloat(ord.product_discount_value)) > 0 ? <><p className="orderDetailsWeb" style={{margin:'10px',color:'red'}}>
                                            ${(parseFloat(ord.product_price) - parseFloat(ord.product_discount_value)).toFixed(2)} </p> <p className="orderDetailsWeb" style={{ margin:'10px',textDecoration:'line-through'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p> </> : <><p className="orderDetailsWeb" style={{margin:'10px'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p></>} </> : <></>}
                                    
                                            { (parseInt(ord.product_discount_percent) == null || parseInt(ord.product_discount_percent) == 0) && (parseFloat(ord.product_discount_value) == null || parseFloat(ord.product_discount_value) == 0) && <p className="orderDetailsWeb" style={{margin:'10px'}}>
                                        ${parseFloat(ord.product_price).toFixed(2)} </p>}
                                        {/* else normal price */}
                                        {/* ${(parseFloat(ord.product_price).toFixed(2)*parseInt(ord.quantity))} */}
                                    </p>
                                    <p key={index} className="orderDetailSubWeb" style={{position:'absolute', right:'10px',top:'100px',display:'inline-grid'}}>
                                        {ord.add_ons && ord.add_ons.length > 0 && ord.add_ons.map((addon, index) => (
                                            <>
                                                {addon.quantity != 0 ? <span style={{marginBottom:'5px'}}>
                                                    {/* + ${((parseFloat(addon.product_price)*parseFloat(addon.quantity)*parseFloat(ord.quantity))).toFixed(2)} */}
                                                + ${((parseFloat(addon.product_price))).toFixed(2)}
                                                </span> : <></>}
                                            </>
                                        ))}
                                    </p> 
                                    {/* <p className="orderDetailsWeb" style={{ position: 'absolute', bottom: '20px', right: 0 }}> 
                                        
                                        {  parseInt(ord.product_discount_percent) > 0 && (parseFloat(ord.product_discount_value) == null || parseFloat(ord.product_discount_value) == 0) ? <>
                                            Subtotal: ${((parseFloat(ord.product_price) * ((100 - parseInt(ord.product_discount_percent))/100))*parseInt(ord.quantity)).toFixed(2)} </> : <></>}
                                
                                        { parseFloat(ord.product_discount_value) > 0 && (parseFloat(ord.product_discount_percent) == null || parseFloat(ord.product_discount_percent) == 0) ? <>
                                            Subtotal: ${((parseFloat(ord.product_price) - parseFloat(ord.product_discount_value))*parseInt(ord.quantity)).toFixed(2)} </> : <></>}
                                
                                        { parseInt(ord.product_discount_percent) == null && (parseFloat(ord.product_discount_value) == null || parseFloat(ord.product_discount_value) == 0) && <>Subtotal: ${(parseFloat(ord.product_price)*parseInt(ord.quantity)).toFixed(2)}</>}
                                    </p>
                                   */}
                                    
                                </Grid>
                                {/* <Grid container spacing={0.5}>
                                    <Grid item xs={8}>
                                    {ord.remarks ?  <p className="orderDetailSubWeb">
                                        Notes: {ord.remarks}
                                    </p> :  <p className="orderDetailSubWeb">
                                        Notes: -
                                    </p>}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {ord.add_ons && ord.add_ons.length > 0 && ord.add_ons.map((addon,index)=>(
                                            <p key={index} className="totalAmt">
                                                <span style={{color:'#6D7278',fontWeight: 400}}>Sub Total:</span>  ${(parseFloat(addon.product_price) + parseFloat(ord.product_price)).toFixed(2)}
                                            </p>
                                        ))}
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </div>
                        <Grid container spacing={0.5} style={{width:'70%',margin:'auto'}}>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={6}>
                                <button className="button5" onClick={()=>this.removeItem(ord)} style={{ fontSize:'16px',marginBottom: "10px", width: "324px", height: '65px', color: '#C70000', background: 'white', textAlign:'left', paddingLeft:'4px'}}>Remove</button>
                            </Grid>
                            <Grid item xs={2} className="pricing">
                            </Grid>
                        </Grid>
                    </>
                ))}
            </>
            
        );
      }
    }
}
 
export default OrderDetails;